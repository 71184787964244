/******************************************************************************************
 *    Audit Search Route
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('audits.AuditSearchRoute', [])
		.config(AuditSearchRouteConfig);

	function AuditSearchRouteConfig($stateProvider, PERMISSIONS) {
		$stateProvider
			.state('auditSearch', {
				url: '/audits/search?query&field&action&date',
				templateUrl: 'audit-search.html',
				controller: 'AuditSearchController',
				controllerAs: 'vm',
				data: {
					access: PERMISSIONS.VIEW_ACTIVITY
				}
			});
	}
}());
