/******************************************************************************************
 *    View Model
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('coreServices.ViewModel', [])
		.constant('VM_STATE', {
			LOADING: 'LOADING',
			ERROR: 'ERROR',
			OK: 'OK'
		})
		.run(($rootScope, VM_STATE)=> $rootScope.VM_STATE = VM_STATE)

		.factory('ViewModelFactory', ViewModelFactoryFactory)
		.factory('EditableViewModelFactory', EditableViewModelFactoryFactory);


	function ViewModelFactoryFactory(VM_STATE, $q) {
		return function ViewModelFactory(init, ...properties) {
			return function ViewModel(...models) {
				this.properties = properties;
				this.models = models;
				
				this.syncFromModel = ()=> {
					init.call(this, ...models);
				};

				Object.defineProperty(this, 'isLoading', {
					get: ()=> Boolean(models.find(model=> model.isLoading()))
				});

				Object.defineProperty(this, 'isError', {
					get: ()=> Boolean(models.find(model=> model.isError()))
				});

				Object.defineProperty(this, 'state', {
					get: ()=> this.isLoading ? VM_STATE.LOADING : this.isError ? VM_STATE.ERROR : VM_STATE.OK
				});

				const flatProperties = [].concat(...properties);
				const getChangedPropertiesAffectingThisVM = (changedProperties) =>
					flatProperties.filter((property)=> changedProperties.indexOf(property) !== -1);
				
				// it's used to clear the LOADING flag when the last expected property is loaded, not after the first.
				let propertiesExpectedToChange = [];
				const isPropertyAlreadyExpectedToChange = (property)=> propertiesExpectedToChange.find((p)=> property === p);
				
				models.forEach((model)=> {
					if (model === undefined) {
						throw new Error('Not all models have been passed to the view-model.');
					}
					model.onPropertiesWillUpdate((event, changedProperties)=> { // jshint unused:vars
						let changedPropertiesAffectingThisVM = getChangedPropertiesAffectingThisVM(changedProperties);
						if (changedPropertiesAffectingThisVM.length > 0) {
							changedPropertiesAffectingThisVM.forEach(property=> {
								if (!isPropertyAlreadyExpectedToChange(property)) {
									propertiesExpectedToChange.push(property);
								}
							});
						}
					});
					
					model.onChange((event, changedProperties)=> { // jshint unused:vars
						let changedPropertiesAffectingThisVM = getChangedPropertiesAffectingThisVM(changedProperties);
						if (changedPropertiesAffectingThisVM.length > 0) {
							this.syncFromModel();
							
							changedPropertiesAffectingThisVM.forEach(property=> {
								if (isPropertyAlreadyExpectedToChange(property)) {
									propertiesExpectedToChange.splice(propertiesExpectedToChange.findIndex((p)=> property === p), 1);
								}
							});
						}
					});
				});

				// TODO: this.syncFromModel needs to be called on init if no change event thrown by model
				const fetchers = models.map((model, index)=> model.fetchProperties(properties[index]));
				this.ready = $q.all(fetchers)
					.then(()=>this.syncFromModel())
					.catch((error)=> {
						return $q.reject(error);
					});
			};
		};
	}

	function EditableViewModelFactoryFactory(ViewModelFactory) {
		return function EditableViewModelFactory(init, ...properties) {
			return function EditableViewModel(...models) {
				const ViewModelConstructor = ViewModelFactory(init, ...properties);
				const original = new ViewModelConstructor(...models);
				ViewModelConstructor.call(this, ...models);

				// Update both original and this at the same time
				const originalSyncFromModel = original.syncFromModel;
				const thisSyncFromModel = this.syncFromModel;
				original.syncFromModel = angular.noop;
				this.syncFromModel = ()=> {
					originalSyncFromModel();
					thisSyncFromModel();
				};

				const flatProperties = [].concat(...properties);
				const isPropChanged = (prop)=> !angular.equals(this[prop], original[prop]);  // in Javascript,  [] !== [] returns true
				Object.defineProperty(this, 'changed', {
					get: ()=> flatProperties.some(isPropChanged)
				});

				const buildChange = (prop)=> ({
					property: prop,
					original: original[prop],
					changed: this[prop]
				});
				this.getChanges = ()=> flatProperties.filter(isPropChanged).map(buildChange);
			};
		};
	}
}());
