/******************************************************************************************
 *    Autofocus Directive
 *******************************************************************************************
 *
 * Fixes HTML5 autofocus attribute to work with dynamically inserted elements
 */

(function() {
	'use strict';

	angular.module('coreDirectives.Autofocus', [])
		.directive('aboAutofocus', Autofocus);

	function Autofocus() {
		return {
			restrict: 'A',
			link: function(scope, element) {
				scope.$applyAsync(()=> {
					element[0].focus();
				});
			}
		};
	}
}());
