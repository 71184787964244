/******************************************************************************************
 *    AppStatus Service
 *******************************************************************************************
 *
 * Allows other services to run functionality after the app is ready to go.
 * Being "ready" means all deps are loaded, user is logged in and authorized as well as routing enabled.
 *
 */
(function() {
	'use strict';

	angular.module('core.AppStatus', []).service('AppStatus', AppStatus);

	function AppStatus($q) {
		const deferred = $q.defer();

		//Method called after app is ready.
		this.done = function() {
			deferred.resolve();
		};

		//Define `ready` property on AppStatus to enable this syntax: `AppStatus.ready.then(...)`.
		Object.defineProperty(this, 'ready', {
			value: deferred.promise,
			enumerable: true,
			writable: false,
			configurable: false
		});
	}
}());
