/******************************************************************************************
 *    GdprAccessReport Model
 ******************************************************************************************/
(function() {
	'use strict';


	angular.module('customers.GdprAccessReportModel', [])
		.factory('GdprAccessReportModel', GdprAccessReportModelFactory);


	function GdprAccessReportModelFactory(ModelFactory, AboApi) {
		const initFn = function(requestId) {
			// Uniquely identifies the model
			this.requestId = requestId;

			this.registerPropertiesFetcher(()=>AboApi.getCustomerAccessGDPRReport(this.requestId), 'gdprAccessReport');
		};

		return ModelFactory(initFn);
	}
}());
