/******************************************************************************************
 *    Export Controller
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.ExportController', [])
		.controller('ExportController', ExportController);

	function ExportController($stateParams, CustomerService, CustomerExportVm, VM_STATE, User, $document) {
		var customer = CustomerService.get($stateParams.id);

		this.customer = new CustomerExportVm(customer);
		this.date = Date.now();
		this.agent = User.getEmail();

		this.customer.ready.then(()=> {
			$document[0].title = 'Profile of ' + this.customer.username;
		});

		Object.defineProperty(this, 'isLoading', {
			get: ()=> this.customer.state === VM_STATE.LOADING
		});
	}
}());
