/******************************************************************************************
 *    Customer Activity Controller
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerActivityController', [])
		.controller('CustomerActivityController', CustomerActivityController);

	function CustomerActivityController($stateParams, AuditService, AuditSearchVm, AUDIT_FIELDS, AUDIT_DATES) {
		this.action = '';
		this.actions = [];
		AuditService.getAvailableActions().then((actions)=> {
			this.actions = actions.sort((a, b)=> (a || '').localeCompare(b));
		});

		this.logsUnfiltered = [];
		const isLogVisible = (log)=> !this.action || log.action === this.action;
		this.refresh = ()=> {
			this.logs = this.logsUnfiltered.filter(isLogVisible);
			this.log = null;
		};

		this.isLoading = true;
		const guid = ($stateParams.id || '').split('@')[0];
		AuditService.search(guid, AUDIT_FIELDS.CUSTOMER, '', AUDIT_DATES.ALL)
			.then((logs)=> {
				this.logsUnfiltered = logs.map((log)=>new AuditSearchVm(log));
				this.refresh();
			})

			.finally(()=> {
				this.isLoading = false;
			});

		this.openLog = (log)=> {
			this.log = log;
		};
	}
}());
