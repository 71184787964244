/******************************************************************************************
 *    Agent Header Controller
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('agents.AgentHeaderController', [])
		.controller('AgentHeaderController', AgentHeaderController);

	function AgentHeaderController($stateParams, AgentService, AgentHeaderVm, VM_STATE, User) {
		var agent = AgentService.get($stateParams.id);

		this.agent = new AgentHeaderVm(agent);

		Object.defineProperty(this, 'isLoading', {
			get: ()=> this.agent.state === VM_STATE.LOADING
		});

		this.hasActivityTab = User.canSeeActivity();

		this.searchQuery = AgentService.getLastSearchQuery();
	}
}());
