/******************************************************************************************
 *    String Utility Methods
 *******************************************************************************************/
(function() {
	'use strict';

	angular.module('utility.StringUtility', []).service('StringUtility', StringUtility);

	function StringUtility() {

		this.NON_ASCII_REGEX = /[^ -~]/;

		this.containsNonAscii = function(str) {
			return this.NON_ASCII_REGEX.test(str);
		};

		/** Turn a property name to a pretty string to be displayed in the interface:
		 * snake_and_camelCase_property => Snake and camel Case
		 */
		this.prettifyLabel = function(name) {
			return name.charAt(0).toUpperCase() +
			       name.substring(1).replace(/_/g, ' ').replace(/(a-z)(A-Z)/g, '$1 $2');
		};

		this.compareStringsIgnoreCase = (s1, s2) => s1.toLowerCase().localeCompare(s2.toLowerCase());
	}
}());
