/******************************************************************************************
 *    Modal Component
 ******************************************************************************************
 *
 * To open a modal simply inject the service and call `Modal.open()` with the appropriate parameters:
 * title, template/templateUrl and optionally locals, controller and controllerAs.
 *
 * To close or dismiss a modal you can use the service methods either directly or through the properties
 * exposed on the scope.
 */
(function() {
	'use strict';

	/**
	 * The modal is a dialog window that covers and the entire screen. Only one modal can be open at one time.
	 */
	angular.module('coreDirectives.Modal', [])
		.service('Modal', Modal);

	/*=== Service ===*/
	function Modal($rootScope, $q, $templateRequest, $controller, $document, $compile) {
		// The deferred corresponding to the currently open modal
		let result;

		// The scope of the modal body
		let scope;

		// The scope of the modal host
		const modalScope = $rootScope.$new();
		// Expose the service on the scope (access to title, load status, close(), dismiss())
		modalScope.Modal = this;

		// Load the modal host template
		let modalContentElement;
		const modalTemplateReady = $templateRequest('modal.html').then((modalTemplate)=> {
			const modalTemplateElement = angular.element(modalTemplate);
			$document.find('body').append(modalTemplateElement);
			modalContentElement = angular.element(modalTemplateElement[0].querySelector('.modal-content'));

			$compile(modalTemplateElement)(modalScope);
		});

		this.title = '';
		
		// Will be set as a CSS class.
		let uniqueCssClass = '';

		// Controls whether the body or the loader is shown
		this.isLoading = false;

		// Controls the visibility of the modal and overlay
		let isOpen = false;
		this.isOpen = ()=> isOpen;

		// Opens a modal
		this.open = ({title, template, templateUrl, locals, controller, controllerAs})=> {
			if (!title) {
				throw new Error('Missing configuration for Modal: `title` is required.');
			}

			if (!template && !templateUrl) {
				throw new Error('Missing configuration for Modal: `template` or `templateUrl` is required.');
			}

			if (template && templateUrl) {
				throw new Error('Invalid configuration for Modal: `template` and `templateUrl` cannot be set simultaneously.');
			}

			if (controllerAs && !controller) {
				throw new Error('Missing configuration for Modal: `controllerAs` requires `controller` to be set.');
			}

			if (locals !== undefined && typeof locals !== 'object') {
				throw new Error('Invalid configuration for Modal: `locals` must be an object');
			}

			if (isOpen) {
				throw new Error('A modal is already open, please resolve it before opening a new one');
			}

			this.title = title;
			
			uniqueCssClass = title.replace(/ /g, '-');

			// Prepare the scope
			scope = modalScope.$new();
			if (locals) {
				angular.extend(scope, locals);
			}

			// Load the user template
			isOpen = true;
			this.isLoading = true;
			const renderReadyDeferred = $q.defer();
			this.renderReady = renderReadyDeferred.promise;
			const userTemplatePromise = template ? $q.resolve(template) : $templateRequest(templateUrl, true);
			$q.all([userTemplatePromise, modalTemplateReady])
				.then(([templateString])=> {
					modalContentElement.html(templateString);
					$compile(modalContentElement)(scope);
					modalContentElement.addClass(uniqueCssClass);
					renderReadyDeferred.resolve();
				})
				.catch((error)=> {
					this.dismiss(error);
					return $q.reject(error);
				})
				.finally(()=> {
					this.isLoading = false;
				});

			if (controller) {
				const controllerInstance = $controller(controller, {$scope: scope});
				if (controllerAs) {
					scope[controllerAs] = controllerInstance;
				}
			}


			result = $q.defer();
			return result.promise.finally(()=> {
				this.title = '';
				scope.$destroy();
				modalContentElement.empty();
			});
		};

		// Required by the Wizard service
		this.getScope = ()=> scope;

		// Closes the currently open modal, equivalent to the OK button, accepting the modal promise
		this.close = (reason)=> {
			if (!isOpen) {
				return;
			}
			isOpen = false;
			if (modalContentElement) {
				modalContentElement.removeClass(uniqueCssClass);
			}
			result.resolve(reason);
		};

		// Closes the currently open modal, equivalent to the Cancel button, rejecting the modal promise
		this.dismiss = (reason)=> {
			if (!isOpen) {
				return;
			}
			isOpen = false;
			if (modalContentElement) {
				modalContentElement.removeClass(uniqueCssClass);
			}
			result.reject(reason);
		};
	}
}());
