/******************************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2021 Adobe Systems Incorporated
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe Systems Incorporated and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Adobe Systems Incorporated and its
 * suppliers and are protected by all applicable intellectual property
 * laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe Systems Incorporated.
 *
 * Customer Sessions View Model
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerSessionsVm', [])
		.factory('CustomerSessionsVm', CustomerSessionsVmFactory);

	function CustomerSessionsVmFactory(ViewModelFactory) {
		const init = function(customer) {
			const sessions = customer.sessions || [];

			this.sessions = sessions.map((entry) => ({
				country: entry.country || '[N/A]',
				region: entry.region || '[N/A]',
				ip: entry.ip || '[N/A]',
				createdAt: entry.createdAt || '[N/A]',
				lastUsedAt: entry.lastUsedAt || '[N/A]',
			}));
		};

		const requiredModelProperties = ['sessions'];

		return ViewModelFactory(init, requiredModelProperties);
	}
}());
