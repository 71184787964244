/******************************************************************************************
 *    Time Until Directive
 ******************************************************************************************
 * Elapses time until a target date.
 * Note that the elapsing time does not trigger digest cycles!
 *
 * This directive schedules timeouts for when the next update should happen.
 * Ex: if there are 23 minutes and 42 seconds remaining, the next update will be scheduled in 42 seconds, the one after that, 1 min after, etc.
 */

(function() {
	'use strict';

	angular.module('coreDirectives.TimeUntil', [])
		.directive('timeUntil', TimeUntil);

	function TimeUntil() {
		return {
			restrict: 'A',
			scope: {
				timeUntil: '='
			},
			link: TimeUntilLink
		};
	}

	const TIME_RANGE = {
		YEAR: 365 * 24 * 60 * 60 * 1000,
		DAY: 24 * 60 * 60 * 1000,
		HOUR: 60 * 60 * 1000,
		MINUTE: 60 * 1000,
		SECOND: 1000,
		NONE: 0
	};

	function TimeUntilLink($scope, $element) {
		const display = (count, unit)=> {
			$element.text(count + ' ' + unit + (count > 1 ? 's' : ''));
		};
		const getBestUnit = (ms)=> {
			// The best unit is the smallest one bigger than ammount of milliseconds
			const isBestUnit = (best, current)=> TIME_RANGE[current] < ms && (!best || TIME_RANGE[current] > TIME_RANGE[best]) ? current : best;
			return Object.keys(TIME_RANGE).reduce(isBestUnit, null);
		};

		const update = ()=> {
			// $scope.timeUntil is UTC, Date.now() is also UTC.
			const msRemaining = $scope.timeUntil - Date.now();
			const unit = getBestUnit(msRemaining);

			if (msRemaining < 0) {
				$element.text('already expired');
			} else if (unit === 'NONE') {
				$element.text('now');
			} else {
				// Use round instead of floor: 11:59 should be displayed as "12 hours" instead of "11 hours".
				display(Math.round(msRemaining / TIME_RANGE[unit]), unit.toLowerCase());
			}

			if (msRemaining >= 0) {
				/* Do not perform a digest cycle for timer updates.
				Update every second to handle the cases where the computer may have been asleep between updates and
				it's not computationally intensive to update it. */
				setTimeout(update, 1000);
			}
		};
		update();
	}
}());
