/******************************************************************************************
 *    Agent Model
 ******************************************************************************************/
(function() {
	'use strict';


	angular.module('agents.AgentModel', [])
		.factory('AgentModel', AgentModelFactory);


	function AgentModelFactory(ModelFactory, AboApi) {
		const searchDetails = ['accountId', 'username', 'manager', 'userType', 'enabled', 'permissions',
			'createdDate', 'expireDate', 'loginDate', 'notes'];
		const allDetails = searchDetails.concat(['email', 'firstName', 'lastName']);

		const initFn = function(accountId) {
			// Uniquely identifies the model
			this.accountId = accountId;

			this.registerPropertiesFetcher(()=>AboApi.getAgent(this.accountId), allDetails);
		};

		const AgentModel = ModelFactory(initFn);

		AgentModel.prototype.setSearchDetails = function(details) {
			return this.setProperties(details, searchDetails);
		};

		AgentModel.prototype.disable = function(comment) {
			return AboApi.disableAgent(this.accountId, comment)
				.then((response)=> {
					this.setProperties(response.data, allDetails);
				});
		};

		AgentModel.prototype.enable = function(comment) {
			return AboApi.enableAgent(this.accountId, comment)
				.then((response)=> {
					this.setProperties(response.data, allDetails);
				});
		};

		AgentModel.prototype.updatePermissions = function(permissions, comment) {
			return AboApi.updateAgentPermissions(this.accountId, permissions, comment)
				.then((response)=> {
					this.setProperties(response.data, allDetails);
				});
		};

		AgentModel.prototype.updateExpireDate = function(date, comment) {
			return AboApi.updateAgentExpireDate(this.accountId, date, comment)
				.then((response)=> {
					this.setProperties(response.data, allDetails);
				});
		};

		return AgentModel;
	}
}());
