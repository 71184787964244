/******************************************************************************************
 *    Entitlements Model
 ******************************************************************************************/
(function() {
	'use strict';


	angular.module('entities.EntitlementsModel', [])
		.factory('EntitlementsModel', EntitlementsModelFactory);

	function EntitlementsModelFactory(AboApi, SingletonModel) {
		function EntitlementsModel() {
			this.registerPropertiesFetcher(()=> AboApi.getEntitlementsMetadata(), 'entitlements');
		}

		return SingletonModel(EntitlementsModel);
	}

}());
