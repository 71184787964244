/******************************************************************************************
 *    ABO API HTTP Interceptor
 *******************************************************************************************
 *
 * Interceptor for configuring requests made to ABO API
 *
 */
(function() {
	'use strict';

	angular.module('auth.aboApiInterceptor', []).factory('aboApiInterceptor', aboApiInterceptor);

	function aboApiInterceptor($injector, $q, ABO_API_URL, TakeoverMessage) {
		return {
			request: function(config) {
				//Do nothing if config is invalid or the URL doesn't match ABO_API_URL
				if (!config || !config.url || config.url.indexOf(ABO_API_URL) !== 0) {
					return config;
				}

				//Set the `withCredentials` flag on XHR object
				config.withCredentials = true;

				//Headers
				config.headers = config.headers || {};

				const Auth = $injector.get('Auth');
				if (Auth.getAccessToken()) {
					//Add `Authorization` header.
					config.headers.Authorization = 'Bearer ' + Auth.getAccessToken();
				}

				return config;
			},

			responseError: function(rejection) {
				//Unauthorized
				if (rejection && rejection.status === 401) {
					if (rejection.data && rejection.data.errorDescription) {
						// {
						//    "error":"unauthorized",
						//    "errorDescription":"The user is disabled. RequestID=8f5274a7-3cbb-4957-9146-5c2a8359ff06"
						// }
						if (rejection.data.errorDescription.startsWith('The user does not have access to ABO.')) {
							return TakeoverMessage.showUnauthorizedUserError();
						}
						if (rejection.data.errorDescription.startsWith('The user is disabled.')) {
							return TakeoverMessage.showDisabledUserError();
						}
						if (rejection.data.errorDescription.startsWith('The user is expired.')) {
							return TakeoverMessage.showExpiredUserError();
						}
					}
					return TakeoverMessage.showGenericError();
				}

				if (!rejection.config.silent) {
					const Toast = $injector.get('Toast');
					const error = rejection.data && rejection.data.errorDescription ||
						rejection.data && rejection.data.error ||
						'Failed call to ' + rejection.config.url;
					Toast.showError(error);
				}

				return $q.reject(rejection);
			}
		};
	}
}());
