/******************************************************************************************
 *    Audit Customer View Model
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('audits.AuditCustomerVm', [])
		.factory('AuditCustomerVm', AuditCustomerVmFactory);

	function AuditCustomerVmFactory(ViewModelFactory, CUSTOMER_STATUS, CUSTOMER_ACCOUNT_TYPE) {
		const init = function(customer) {
			this.accountId = customer.accountId;
			this.fullName = [customer.firstName, customer.lastName].filter((value)=>value).join(' ') || '[N/A]';
			this.username = customer.username || '[N/A]';
			this.status = CUSTOMER_STATUS[customer.status] || CUSTOMER_STATUS.UNKNOWN;
			this.accountType = CUSTOMER_ACCOUNT_TYPE[customer.authSrcType] || CUSTOMER_ACCOUNT_TYPE.UNKNOWN;
		};

		const requiredModelProperties = ['firstName', 'lastName', 'username', 'status', 'authSrcType'];

		return ViewModelFactory(init, requiredModelProperties);
	}
}());
