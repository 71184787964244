/******************************************************************************************
 *    Agent Quick Profile
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('agents.AgentQuickProfile', [])
		.directive('aboAgentQuickProfile', AgentQuickProfileDirectiveS)
		.controller('AgentQuickProfileController', AgentQuickProfileController);


	/*=== Controller ===*/
	function AgentQuickProfileController(AgentService, AgentDetailsVm, AgentAccountAccessVm, AgentPermissionsVm,
	                                     Modal, Toast, VM_STATE, PermissionsModel, RolesModel, $scope, fromDateStringFilter) {

		var permissionsModel = PermissionsModel.get();
		var rolesModel = RolesModel.get();
		let agent;
		$scope.$watch(()=>this.agent, ()=> {
			agent = AgentService.get(this.agent.accountId);

			this.agentStatus = new AgentAccountAccessVm(agent, permissionsModel);
			this.agentDetails = new AgentDetailsVm(agent);
			this.agentPermissions = new AgentPermissionsVm(agent, permissionsModel, rolesModel);
		});

		Object.defineProperty(this, 'isLoading', {
				get: ()=> !agent ||
				          this.agentDetails.state === VM_STATE.LOADING ||
				          this.agentStatus.state === VM_STATE.LOADING ||
				          this.agentPermissions.state === VM_STATE.LOADING
			}
		);

		// Reset all fields to their last known server state
		this.discardChanges = ()=> {
			this.agentPermissions.syncFromModel();
			Toast.showWarning('Agent permission changes discarded.');
		};

		// Update the state on the server
		this.saveChanges = (comment)=> {
			Modal.isLoading = true;
			const permissions = this.agentPermissions.permissions;
			return agent.updatePermissions(permissions, comment)
				.then(()=> {
					this.agentPermissions.syncFromModel();
					Toast.showSuccess('Agent permissions successfully saved.');
				})
				.finally(()=> Modal.isLoading = false);
		};

		// Prompt the user to give a reason for the changes
		this.showSaveModal = (allowDiscard = false)=> {
			const permissions = this.agentPermissions.permissions;
			return Modal.open({
				title: 'Save agent permission changes',
				templateUrl: 'agent-save-modal.html',
				locals: {permissions, allowDiscard, save: this.saveChanges, discard: this.discardChanges}
			});
		};

		const disable = (comment)=> {
			Modal.isLoading = true;
			return agent.disable(comment)
				.then(()=> {
					this.agentStatus.syncFromModel();
					Toast.showSuccess('Agent successfully disabled.');
				})
				.finally(()=> Modal.isLoading = false);
		};
		this.showDisableModal = ()=> {
			Modal.open({
				title: 'Disable account access',
				templateUrl: 'agent-disable-modal.html',
				locals: {accountId: agent.accountId, email: agent.username, disable}
			});
		};


		const enable = (comment)=> {
			Modal.isLoading = true;
			return agent.enable(comment)
				.then(()=> {
					this.agentStatus.syncFromModel();
					Toast.showSuccess('Agent successfully enabled.');
				})
				.finally(()=> Modal.isLoading = false);
		};
		this.showEnableModal = ()=> {
			Modal.open({
				title: 'Enable account access',
				templateUrl: 'agent-enable-modal.html',
				locals: {accountId: agent.accountId, email: agent.username, enable}
			});
		};


		const changeExpireDate = (dateString, comment)=> {
			Modal.isLoading = true;
			const expireDate = fromDateStringFilter(dateString);
			return agent.updateExpireDate(expireDate, comment)
				.then(()=> {
					this.agentStatus.syncFromModel();
					Toast.showSuccess('Agent expire date successfully changed.');
				})
				.finally(()=> Modal.isLoading = false);
		};

		this.showChangeExpireDateModal = ()=> {
			Modal.open({
				title: 'Change expire date',
				templateUrl: 'agent-change-expire-modal.html',
				locals: {pattern: this.agentStatus.expireDatePattern, changeExpireDate}
			});
		};
	}


	/*=== Directive ===*/
	function AgentQuickProfileDirectiveS() {
		return {
			restrict: 'E',
			templateUrl: 'agent-quick-profile.html',
			scope: {
				agent: '='
			},
			controller: 'AgentQuickProfileController',
			controllerAs: 'vm',
			bindToController: true
		};
	}
}());
