/******************************************************************************************
 *    App Config-Block
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('app.config', [])
		.config(aboConfig)
		.config(aboRouting);

	function aboConfig($locationProvider, $httpProvider, $animateProvider) {
		//FR: TODO ~ Make this build-aware, perhaps a SETTINGS constant that can be modified at build time
		//which includes other settings as well, such as the API URL or IMS JS Lib URL.
		//Disable debugging.
		//$compileProvider.debugInfoEnabled(false);

		//Use HTML5 pushState API.
		$locationProvider.html5Mode(true).hashPrefix('!');

		//Configure $http service to return promises without `success` or `error` methods.
		$httpProvider.useLegacyPromiseExtensions(false);

		/*Configure $http.delete requests to have a json body
		 @see https://github.com/angular/angular.js/blob/b3a3ed34b9c9d965233c0adc4fd4605fbe7c05d3/src/ng/http.js#L299
		 */
		$httpProvider.defaults.headers.delete = {'Content-Type': 'application/json; charset=utf-8'};

		//Configure $http interceptors.
		$httpProvider.interceptors.push('aboApiInterceptor');

		//Disable ngAnimate from firing on elements with `.ng-animate-disabled` class.
		$animateProvider.classNameFilter(/^(?:(?!js-ng-animate-disabled).)*$/);
	}

	function aboRouting($urlRouterProvider, $stateProvider) {
		//Unmatched URL redirect
		$urlRouterProvider.otherwise('/');

		$stateProvider
			.state('root', {
				url: '/'
			});
	}
})();
