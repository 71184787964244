/******************************************************************************************
 *    App Initializer
 *******************************************************************************************
 *
 * Blocks state transitions until all initialization dependencies are resolved.
 *
 */
(function() {
	'use strict';

	angular.module('core.AppInitializer', [])
		.config(urlRouterConfig)
		.service('AppInitializer', AppInitializer);

	//Prevent $urlRouter from automatically intercepting URL changes.
	//It will be enabled at a later time, when app is ready to transition states.
	function urlRouterConfig($urlRouterProvider) {
		$urlRouterProvider.deferIntercept(true);
	}

	//AppInitializer Service
	function AppInitializer($rootScope, $q, ImsAuth) {
		/*=== STATE CHANGE BLOCKER ===*/

		//Listen for:
		// - `$locationChangeSuccess` events and prevent them from reaching $urlRouter,
		// - `$stateChangeStart` events and prevent them from materializing into a state change.
		//This will block state changes from happening until the app is ready to start.
		//For this to work, in the config-block, we called `deferIntercept()` on the $urlRouter first.
		const routingBlocker = function(event) {
			event.preventDefault();
		};
		const unregisterlocationChangeSuccessBlocker = $rootScope.$on('$locationChangeSuccess', routingBlocker);
		const unregisterStateChangeBlocker = $rootScope.$on('$stateChangeStart', routingBlocker);


		/*=== INIT HANDLERS ===*/

		//Deferred whose promise is returned by the `init()` pubic method.
		let initDeferred;

		//Init handling
		const initDone = function() {
			initDeferred.resolve();

			console.log('%cAppInit :: DONE', 'font-weight:bold;color:#009B77;');
		};
		const initFailed = function() {
			//FR: TODO
			initDeferred.reject();

			console.log('%cAppInit :: ERROR', 'font-weight:bold;color:#CC0000;');
		};


		/*=== PUBLIC INTERFACE ===*/

		//The `init()` method is to be called from the run-block of the application to start the initialization
		// process.
		//Currently, only `ImsAuth` needs to be initialized in this phase (the pre-router or pre-state phase).
		//Future dependencies (such as a language pack being loaded) would be added in the array of promises required
		// to resolve.
		this.init = function() {
			//Init can only be called once, subsequent calls will just return the same promise object.
			if (!initDeferred) {
				console.log('%cAppInit :: START', 'font-weight:bold;color:#009B77;');

				//Set initDeferred, it will not be set a second time.
				initDeferred = $q.defer();

				//Bundle dependencies in an array of promises to be resolved.
				const deps = [
					ImsAuth.init()
				];

				//Resolve all promises in parallel, then call
				//`initDone()` if all fullfilled or
				//`initFailed()` if any rejected.
				$q.all(deps)
					.then(initDone)
					.catch(initFailed);
			}

			return initDeferred.promise;
		};

		//Enables routing by allowing `$locationChangeSuccess` and `$stateChangeStart` events from "propagating".
		//Note: They propagate even without calling this method, but with their default action prevented.
		//Calling `enableRouting()` will no longer call `preventDefault()` on their respective events.
		this.enableRouting = function() {
			unregisterlocationChangeSuccessBlocker();
			unregisterStateChangeBlocker();
		};
	}
}());
