/******************************************************************************************
 *    Customer Controller
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerHeaderController', [])
		.controller('CustomerHeaderController', CustomerHeaderController);

	function CustomerHeaderController($stateParams, CustomerService, CustomerHeaderVm, VM_STATE, User, TakeoverMessage, EnvironmentConfiguration) {
		var customer = CustomerService.get($stateParams.id);

		this.customer = new CustomerHeaderVm(customer);
		this.customer.ready.catch(()=> {
			TakeoverMessage.showMissingCustomerError();
		});

		this.viewAssetsLink = `${EnvironmentConfiguration.getValue('vaderEndpoint')}all/assets/${customer.accountId}`;
		this.isGDPREnabled = EnvironmentConfiguration.getValue('isGDPREnabled');

		Object.defineProperty(this, 'isLoading', {
			get: ()=> this.customer.state === VM_STATE.LOADING
		});

		this.canExportProfile = User.canExportProfile();
		this.canViewAssets = User.canViewAssets();
		this.hasActivityTab = User.canSeeActivity();

		this.searchQuery = CustomerService.getLastSearchQuery();
		
		this.activeTab = '';
		this.setActiveTab = (tabName) => {
			this.activeTab = tabName;
		};
		
		this.getHendrixUrl = () => {
			let hendrixUrl = `${EnvironmentConfiguration.getValue('hendrixEndpoint')}app/customer?id=${customer.accountId}&tab=services`;
			return hendrixUrl;
		};
	}
}());
