/******************************************************************************************
 *    Agent Header View Model
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('agents.AgentHeaderVm', [])
		.factory('AgentHeaderVm', AgentHeaderVmFactory);


	function AgentHeaderVmFactory(ViewModelFactory, AGENT_STATUS) {
		const init = function(agent) {
			this.accountId = agent.accountId;
			this.username = agent.username || '[N/A]';
			this.pictureClass = agent.enabled ? '' : 'warning';
			this.tooltip = 'Status: ' + (agent.enabled ? AGENT_STATUS.ACTIVE : AGENT_STATUS.DISABLED);
		};

		const requiredModelProperties = ['username', 'enabled'];

		return ViewModelFactory(init, requiredModelProperties);
	}
}());
