/******************************************************************************************
 *    Customer Quick Profile
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerQuickProfile', [])
		.directive('aboCustomerQuickProfile', CustomerQuickProfileDirectiveS)
		.controller('CustomerQuickProfileController', CustomerQuickProfileController);


	/*=== Controller ===*/
	function CustomerQuickProfileController(CustomerService, CustomerDetailsVm, CustomerAccountAccessVm, CustomerGDPRVm,
	                                        CustomerEmailVm, CustomerPasswordVm, VM_STATE, Toast, Modal, $q, $scope) {
		let customer;
		$scope.$watch(()=>this.customer, ()=> {
			customer = CustomerService.get(this.customer.accountId);
			this.customerDetails = new CustomerDetailsVm(customer);
			this.customerStatus = new CustomerAccountAccessVm(customer);
			this.customerGDPR = new CustomerGDPRVm(customer);
			this.customerEmail = new CustomerEmailVm(customer);
			this.customerPassword = new CustomerPasswordVm(customer);
		});

		Object.defineProperty(this, 'isLoading', {
				get: ()=> !customer ||
				          this.customerDetails.state === VM_STATE.LOADING ||
				          this.customerStatus.state === VM_STATE.LOADING ||
				          this.customerGDPR.state === VM_STATE.LOADING ||
				          this.customerEmail.state === VM_STATE.LOADING ||
				          this.customerPassword.state === VM_STATE.LOADING
			}
		);

		// Reset all fields to their last known server state
		this.discardChanges = ()=> {
			this.customerDetails.syncFromModel();
			Toast.showWarning('Customer changes discarded.');
		};

		// Update the state on the server
		this.saveChanges = (comment)=> {
			Modal.isLoading = true;
			const changes = this.customerDetails.getChanges();
			return customer.saveDetails(changes, comment)
				.then(()=> {
					this.customerDetails.syncFromModel();
					Toast.showSuccess('Customer details successfully saved.');
				})
				.finally(()=> Modal.isLoading = false);
		};

		// Prompt the user to give a reason for the changes
		this.showSaveModal = (allowDiscard = false)=> {
			const changes = this.customerDetails.getChanges();
			return Modal.open({
				title: 'Save customer details changes',
				templateUrl: 'customer-save-modal.html',
				locals: {changes, allowDiscard, save: this.saveChanges, discard: this.discardChanges}
			});
		};

		let challenge;
		const sendChallenge = (comment)=> {
			Modal.isLoading = true;
			return customer.sendPhoneChallenge(comment)
				.then((response)=> { challenge = response; })
				.finally(()=> Modal.isLoading = false);
		};

		const verify = (code)=> {
			Modal.isLoading = true;
			return customer.verifyPhoneChallenge(challenge, code)
				.then(()=> {
					Toast.showSuccess('Customer successfully verified!');
				})
				.catch((error)=> {
					switch (error.status) {
						case 410:
							Toast.showError('Verification expired, please send new code!');
							/* Resolve promise to close modal */
							return;
						case 429:
							Toast.showError('Too many failed attempts!');
							break;
						case 409:
							Toast.showError('Invalid code!');
							break;
						default:
							Toast.showError('An error occurred, please try again!');
							break;
					}
					/* Keep modal open */
					return $q.reject(error);
				})
				.finally(()=> Modal.isLoading = false);
		};

		const showVerifyPhoneModal = ()=> {
			return Modal.open({
				title: 'Text message sent',
				templateUrl: 'customer-verify-phone-modal.html',
				locals: {verify}
			});
		};

		this.showSendPhoneVerificationModal = ()=> {
			return Modal.open({
				title: 'Verify phone number',
				templateUrl: 'customer-send-phone-verification-modal.html',
				locals: {securityPhoneNumber: this.customerDetails.securityPhoneNumber, sendChallenge}
			}).then(showVerifyPhoneModal);
		};


		const disableWithGDPR = (comment, isPhotoDna)=> {
			Modal.isLoading = true;
			return customer.disableWithGDPR(isPhotoDna, comment)
				.then(()=> {
					customer.triggerGDPRPropertiesUpdate();
					Toast.showSuccess('Customer successfully disabled.');
				})
				.finally(()=> Modal.isLoading = false);
		};
		this.showDisableWithGDPRModal = ()=> {
			Modal.open({
				title: 'Disable account access',
				templateUrl: 'customer-disable-modal.html',
				/* create VM object in modal scope to avoid implicitly creating it in ng-if scope */
				controller: angular.noop,
				controllerAs: 'vm',
				locals: {
					description: 'The account will be deleted in 60 days because of the GDPR privacy laws.',
					accountId: customer.accountId,
					email: customer.email,
					canDisableWithPdna: this.customerStatus.canDisableWithPdna,
					reference: { // angular passes it by reference and the changes inside it are seen in both places (here and in the template).
						comment: '',
						isPhotoDna: false
					},
					disable: disableWithGDPR
				}
			});
		};
		
		const enableWithGDPR = (comment)=> {
			Modal.isLoading = true;
			return customer.enableWithGDPR(comment)
				.then(()=> {
					customer.triggerGDPRPropertiesUpdate();
					Toast.showSuccess('Customer successfully enabled.');
				})
				.finally(()=> Modal.isLoading = false);
		};
		this.showEnableWithGDPRModal = ()=> {
			Modal.open({
				title: 'Enable account access',
				templateUrl: 'customer-enable-modal.html',
				locals: {
					description: 'The Active Delete GDPR Request will be Cancelled.',
					accountId: customer.accountId,
					email: customer.email,
					enable: enableWithGDPR
				}
			});
		};

		
		const disable = (comment, isPhotoDna)=> {
			Modal.isLoading = true;
			return customer.disable(isPhotoDna, comment)
				.then(()=> {
					this.customerStatus.syncFromModel();
					Toast.showSuccess('Customer successfully disabled.');
				})
				.finally(()=> Modal.isLoading = false);
		};
		this.showDisableModal = ()=> {
			Modal.open({
				title: 'Set Status to Disabled',
				templateUrl: 'customer-disable-modal.html',
				/* create VM object in modal scope to avoid implicitly creating it in ng-if scope */
				controller: angular.noop,
				controllerAs: 'vm',
				locals: {
					description: 'The account will NOT be deleted in 60 days.',
					accountId: customer.accountId,
					email: customer.email,
					canDisableWithPdna: this.customerStatus.canDisableWithPdna,
					reference: { // angular passes it by reference and the changes inside it are seen in both places (here and in the template).
						comment: '',
						isPhotoDna: false
					},
					disable
				}
			});
		};
		this.showPhotoDnaDisableModal = ()=> {
			Modal.open({
				title: 'Disable because of PhotoDna',
				templateUrl: 'customer-disable-modal.html',
				/* create VM object in modal scope to avoid implicitly creating it in ng-if scope */
				controller: angular.noop,
				controllerAs: 'vm',
				locals: {
					description: 'The account will NOT be deleted in 60 days.',
					accountId: customer.accountId,
					email: customer.email,
					canDisableWithPdna: true,
					reference: { // angular passes it by reference and the changes inside it are seen in both places (here and in the template).
						isPhotoDna: true  // starts as checked
					},
					disable: disableWithGDPR
				}
			});
		};


		const enable = (comment)=> {
			Modal.isLoading = true;
			return customer.enable(comment)
				.then(()=> {
					this.customerStatus.syncFromModel();
					Toast.showSuccess('Customer successfully enabled.');
				})
				.finally(()=> Modal.isLoading = false);
		};
		this.showEnableModal = ()=> {
			Modal.open({
				title: 'Enable account access',
				templateUrl: 'customer-enable-modal.html',
				locals: {accountId: customer.accountId, email: customer.email, enable}
			});
		};

		const verifyEmail = (comment)=> {
			Modal.isLoading = true;
			return customer.verifyEmail(comment)
				.then(()=> {
					this.customerEmail.syncFromModel();
					Toast.showSuccess('Customer verification email successfully sent.');
				})
				.finally(()=> Modal.isLoading = false);
		};

		this.showVerifyEmailModal = ()=> {
			Modal.open({
				title: 'Send verification email',
				templateUrl: 'customer-verify-email-modal.html',
				locals: {accountId: customer.accountId, email: customer.email, verifyEmail}
			});
		};


		const clearDob = (comment)=> {
			Modal.isLoading = true;
			return customer.clearDob(comment)
				.then(()=> {
					Toast.showSuccess('The date of birth has been cleared.');
				})
				.finally(()=> Modal.isLoading = false);
		};

		this.showClearDobModal = ()=> {
			return Modal.open({
				title: 'Clear the date of birth',
				templateUrl: 'customer-clear-dob-modal.html',
				locals: {
					accountId: customer.accountId,
					email: customer.email,
					clearDob
				}
			});
		};

	}


	/*=== Directive ===*/
	function CustomerQuickProfileDirectiveS() {
		return {
			restrict: 'E',
			templateUrl: 'customer-quick-profile.html',
			scope: {
				customer: '='
			},
			controller: 'CustomerQuickProfileController',
			controllerAs: 'vm',
			bindToController: true
		};
	}
}());
