/******************************************************************************************
 *    Customer Route
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerRoute', [])
		.config(CustomerRouteConfig);

	function CustomerRouteConfig($stateProvider, PERMISSIONS) {
		$stateProvider

			.state('customer', {
				abstract: true,
				url: '/customer/:id',
				// TODO: extract header from this state to a view
				templateUrl: 'customer.html',
				controller: 'CustomerHeaderController',
				controllerAs: 'vm',
				data: {
					access: PERMISSIONS.VIEW_CUSTOMER
				}
			})

			.state('customer.details', {
				url: '/details',
				views: {
					customer: {
						templateUrl: 'customer-profile.html',
						controller: 'CustomerProfileController',
						controllerAs: 'vm'
					}
				}
			})

			.state('customer.authentication', {
				url: '/authentication',
				views: {
					customer: {
						templateUrl: 'customer-authentication.html',
						controller: 'CustomerAuthenticationController',
						controllerAs: 'vm'
					}
				}
			})

			.state('customer.entitlements', {
				url: '/entitlements',
				views: {
					customer: {
						templateUrl: 'customer-entitlements.html',
						controller: 'CustomerEntitlementsController',
						controllerAs: 'vm'
					}
				}
			})

			.state('customer.gdpr', {
				url: '/gdpr',
				views: {
					customer: {
						templateUrl: 'customer-gdpr.html',
						controller: 'CustomerGDPRController',
						controllerAs: 'vm'
					}
				}
			})

			.state('customer.activity', {
				url: '/activity',
				views: {
					customer: {
						templateUrl: 'customer-activity.html',
						controller: 'CustomerActivityController',
						controllerAs: 'vm'
					}
				},
				data: {
					access: PERMISSIONS.VIEW_ACTIVITY
				}
			});
	}
}());
