// from https://github.com/tc39/proposal-object-values-entries/blob/master/polyfill.js
// this solves the problem of the CI Continuous Integration environment needing to use Firefox 45.0.2 that can still be
// automated by the testing framework but doesn't have native support for Object.values and Object.entries.

const reduce = Function.bind.call(Function.call, Array.prototype.reduce);
const isEnumerable = Function.bind.call(Function.call, Object.prototype.propertyIsEnumerable);
const concat = Function.bind.call(Function.call, Array.prototype.concat);
const keys = Reflect.ownKeys;

if (!Object.values) {
	Object.values = function values(O) {
		return reduce(keys(O), function(v, k) { return concat(v, typeof k === 'string' && isEnumerable(O, k) ? [O[k]] : []); }, []);
	};
}

if (!Object.entries) {
	Object.entries = function entries(O) {
		return reduce(keys(O), function(e, k) { return concat(e, typeof k === 'string' && isEnumerable(O, k) ? [[k, O[k]]] : []); }, []);
	};
}

console.log('Object.values.entries.js');
