/******************************************************************************************
 *    Customer Model
 ******************************************************************************************/
(function() {
	'use strict';


	angular.module('customers.CustomerModel', [])
		.factory('CustomerModel', CustomerModelFactory);

	function CustomerModelFactory($q, ModelFactory, AboApi, PromiseUtility, CUSTOMER_STATUS, CUSTOMER_TEMPORARY_STATUS, MODEL_PROPERTY_STATUS,
	                              Toast, $timeout, EnvironmentConfiguration) {
		// These are the properties retrieved directly by the /customers search API so we don't need extra api calls to retrieve them.
		// The content we receive is what is set in /abo2/git/abo-service/abo-services/src/main/java/com/adobe/abo/adapters/RengaEntityConverter.java
		const searchProperties = ['authSrc', 'authSrcType', 'responsibleAuthSrc', 'responsibleAuthSrcType', 'username',
			'firstName', 'lastName', 'screenName', 'status', 'temporaryStatus'];
		
		const profileProperties = ['accountId', 'authSrc', 'authSrcType', 'responsibleAuthSrc', 'responsibleAuthSrcType', 'username',
			'firstName', 'lastName', 'screenName', 'status', 'temporaryStatus',
			'countryCode', 'dateOfBirth', 'securityPhoneNumber', 'isMfaEnabled', 'email', 'alternateEmail', 'pdnaDisabled', 'incrementalIncomplete',
			'isPaidCustomer', 'contracts', 'isSocialOnly', 'socialAccounts', 'preferredLangs', 'humanReadablePreferredLangs', 'tags',
			'invitedOrActiveEntitlementAccountLinkForType2E', 'entitlementAccountLinks',
			'isEOAPreferredUserAgreementPermissionStatusEnabled', 'isMfaRequiredByOrg'];

		const initFn = function(accountId) {
			this.accountId = accountId;

			this.registerPropertiesFetcher(()=> AboApi.getCustomerDetails(this.accountId), profileProperties);
			this.registerPropertiesFetcher(()=> AboApi.getCustomerAddresses(this.accountId), 'addresses');
			this.registerPropertiesFetcher(()=> AboApi.getCustomerOrganizations(this.accountId), 'organizations');
			this.registerPropertiesFetcher(()=> AboApi.getCustomerOrganizationRelationshipsOREOs(this.accountId), 'organizationRelationshipsOREOs');
			this.registerPropertiesFetcher(()=> AboApi.getCustomerRoles(this.accountId), 'roles');
			this.registerPropertiesFetcher(()=> AboApi.getCustomerCreationDetails(this.accountId), 'creationDetails');
			this.registerPropertiesFetcher(()=> AboApi.getCustomerLoginHistory(this.accountId), 'loginHistory');
			this.registerPropertiesFetcher(()=> AboApi.getCustomerAccountAccessStatus(this.accountId), 'accountAccessStatus');
			this.registerPropertiesFetcher(()=> AboApi.getCustomerPasswordStatus(this.accountId), 'passwordStatus');
			this.registerPropertiesFetcher(()=> AboApi.getCustomerEmailStatus(this.accountId), 'emailStatus');
			this.registerPropertiesFetcher(()=> AboApi.getCustomerTermsOfUse(this.accountId), 'termsOfUse');
			this.registerPropertiesFetcher(()=> AboApi.getCustomerEntitlements(this.accountId), 'entitlements');
			this.registerPropertiesFetcher(()=> AboApi.getCustomerProductContexts(this.accountId), 'productContexts');
			this.registerPropertiesFetcher(()=> this.checkReverseVerificationRegularlyInTheBackground(), 'reverseVerification');
			this.registerPropertiesFetcher(()=>AboApi.getImpersonationSessions(this.accountId), 'impersonationSessions');
			this.registerPropertiesFetcher(()=> AboApi.getCustomerSessions(this.accountId), 'sessions');

			const isGDPREnabled = EnvironmentConfiguration.getValue('isGDPREnabled');
			const promiseForEmptyDataArray = $q.when({data: []});
			this.registerPropertiesFetcher(()=> isGDPREnabled ? AboApi.getCustomerGDPRRequests(this.accountId) : promiseForEmptyDataArray, 'gdprRequests');
		};

		const CustomerModel = ModelFactory(initFn);
		
		CustomerModel.reverseVerificationCheckerTimeoutId = null;

		// emailType can be either 'primary' or 'alternate'.
		CustomerModel.prototype.isEmailStatusVerifed = function(emailType) {
			return this.emailStatus && this.emailStatus[emailType] && this.emailStatus[emailType].status === 'VERIFIED';
		};

		// Tells whether the customer's identity was verified in this ABO session.
		// It's a JS only property.
		CustomerModel.prototype.setIdentityIsVerified = function() {
			this.setProperties(true, 'verifiedIdentity');
		};
		
		CustomerModel.prototype.setSearchDetails = function(details) {
			this.setProperties(details, searchProperties);
		};

		CustomerModel.prototype.setProfile = function(profile) {
			this.setProperties(profile, profileProperties);
		};

		CustomerModel.prototype.getProfile = function() {
			let result = {};
			profileProperties.forEach((property) => {
				result[property] = this[property];
			});
			return result;
		};
		
		CustomerModel.prototype.setTermsOfUse = function(customerTermsOfUse) {
			this.setProperties(customerTermsOfUse, 'termsOfUse');
		};

		CustomerModel.prototype.saveDetails = function(changes, comment) {
			const profile = this.getProfile();
			profile.firstName = profile.firstName || null;
			profile.lastName = profile.lastName || null;
			profile.screenName = profile.screenName || null;
			profile.alternateEmail = profile.alternateEmail || null;

			// Username must be passed unchanged so the backend can have the pleasure of updating it
			changes.filter((change)=> change.property !== 'username')
				.forEach((change)=> {
					profile[change.property] = change.changed;
				});

			// if email or alternateEmail have changed, the emailStatus needs to be updated as well
			const shouldUpdateEmailStatus = this._propertyMetadata.emailStatus.status !== MODEL_PROPERTY_STATUS.NOT_RETRIEVED &&
			                                changes.find((change)=>change.property === 'email' || change.property === 'alternateEmail');


			return AboApi.saveCustomerDetails(this.accountId, profile, comment)
				.then((response)=> this.setProfile(response.data))
				.then(()=> {
					if (shouldUpdateEmailStatus) {
						this.triggerPropertyUpdate('emailStatus');
					}
				});
		};

		CustomerModel.prototype.saveTermsOfUse = function(changes, comment) {
			// create default TOU if current is null
			var termsOfUse = this.termsOfUse ? angular.copy(this.termsOfUse) : {
				acceptedDate: '',
				acceptedLanguage: 'English',
				latestAccepted: false,
				revision: ''
			};

			if (!changes && !angular.isArray(changes)) {
				return;
			}
			const changedTou = changes[0].changed;
			const originalTou = changes[0].original;
			termsOfUse.revision = changedTou.revision !== originalTou.revision ? changedTou.revision : originalTou.revision;

			return AboApi.saveCustomerTermsOfUse(this.accountId, termsOfUse, comment)
				.then((response) => this.setTermsOfUse(response.data))
				.then(()=> this.dispatchChangeEvent('termsOfUse'));
		};

		CustomerModel.prototype.disableWithGDPR = function(photoDna, comment) {
			return AboApi.disableCustomerWithGDPR(this.accountId, photoDna, comment)
				.then(()=> {
					this.setProperties({
						status: CUSTOMER_STATUS.ACTIVE,
						temporaryStatus: CUSTOMER_TEMPORARY_STATUS.ACCOUNT_CLOSED,
						pdnaDisabled: !!photoDna
					}, ['status', 'temporaryStatus', 'pdnaDisabled']);
				});
		};

		CustomerModel.prototype.enableWithGDPR = function(comment) {
			return AboApi.enableCustomerWithGDPR(this.accountId, comment)
				.then(()=> {
					this.setProperties({
						status: CUSTOMER_STATUS.ACTIVE,
						temporaryStatus: undefined,
						pdnaDisabled: false
					}, ['status', 'temporaryStatus', 'pdnaDisabled']);
				});
		};

		CustomerModel.prototype.disable = function(photoDna, comment) {
			return AboApi.disableCustomer(this.accountId, photoDna, comment)
				.then((response)=> {
					this.setProperties({
						status: CUSTOMER_STATUS.DISABLED,
						accountAccessStatus: response.data,
						pdnaDisabled: !!photoDna
					}, ['status', 'accountAccessStatus', 'pdnaDisabled']);
				});
		};
		
		CustomerModel.prototype.forcePasswordReset = function(accountId, comment, sendEmailNotification) {
			return AboApi.forcePasswordReset(accountId, comment, sendEmailNotification);
		};

		CustomerModel.prototype.enable = function(comment) {
			return AboApi.enableCustomer(this.accountId, comment)
				.then((response)=> {
					this.setProperties({
						status: CUSTOMER_STATUS.ACTIVE,
						accountAccessStatus: response.data,
						pdnaDisabled: false
					}, ['status', 'accountAccessStatus', 'pdnaDisabled']);
				});
		};

		CustomerModel.prototype.sendPhoneChallenge = function(comment) {
			return AboApi.sendCustomerPhoneChallenge(this.accountId, comment)
				.then((response)=> response.data && response.data.referenceId);
		};

		CustomerModel.prototype.verifyPhoneChallenge = function(challenge, code) {
			return AboApi.verifyCustomerPhoneChallenge(this.accountId, challenge, code)
				.then(()=> {
					this.setIdentityIsVerified();
				});
		};

		CustomerModel.prototype.fixCredentials = function(username, comment) {
			return AboApi.fixCustomerCredentials(this.accountId, username, comment)
				.then(()=> {
					this.setProperties({email: username, username: username}, ['email', 'username']);
				});
		};

		CustomerModel.prototype.setPassword = function(password, comment) {
			return AboApi.setPassword(this.accountId, password, comment);
		};
		
		CustomerModel.prototype.clearDob = function(comment) {
			return AboApi.clearDob(this.accountId, comment)
				.then(()=> {
					this.setProperties(null, 'dateOfBirth');
				});
		};

		CustomerModel.prototype.unlinkSocialProvider = function(provider, comment) {
			return AboApi.unlinkSocialProvider(this.accountId, provider, comment)
				.then(()=> {
					this.triggerPropertyUpdate('socialAccounts');
				});
		};

		CustomerModel.prototype.createImpersonationSession = function(caseNumber) {
			return AboApi.createImpersonationSession(this.accountId, caseNumber).then(()=> {
				this.getImpersonationSessions(this.accountId);
			});
		};

		CustomerModel.prototype.getImpersonationSessions = function() {
			return AboApi.getImpersonationSessions(this.accountId).then((response)=> {
				this.setProperties(response.data, 'impersonationSessions');
			});
		};

		CustomerModel.prototype.resendImpersonationEmail = function(sessionId) {
			return AboApi.resendImpersonationEmail(sessionId);
		};

		CustomerModel.prototype.updateEmailStatus = function() {
			return AboApi.getCustomerEmailStatus(this.accountId)
				.then((response)=> {
					this.setProperties(response.data, 'emailStatus');
				});
		};

		CustomerModel.prototype.verifyEmail = function(comment) {
			return AboApi.verifyCustomerEmail(this.accountId, comment)
				.then(()=> this.updateEmailStatus());
		};
		
		// If the account has an Unverified Email address, the EmailStatus and the ReverseVerification information are
		// updated regularly in the background, even if the agent doesn't click on any of the UI.
		CustomerModel.prototype.checkReverseVerificationRegularlyInTheBackground = function() {
			const CHECK_INTERVAL = 60 * 1000 /* every 60 seconds */;
			
			// Only one Customer can be selected at a time.
			if (CustomerModel.reverseVerificationCheckerTimeoutId) {
				$timeout.cancel(CustomerModel.reverseVerificationCheckerTimeoutId);
				CustomerModel.reverseVerificationCheckerTimeoutId = null;
			}
			
			return AboApi.getReverseEmailVerification(this.accountId)
				.then((response)=> {
					var oldRV = this.reverseVerification;
					var newRV = response.data;
					this.setProperties(newRV, 'reverseVerification');

					const isRVInProgress = (email, rv)=> Boolean(rv && email && rv[email]);
					const mayHaveBeenVerified = (email, newRV, oldRV)=> isRVInProgress(email, oldRV) && !isRVInProgress(email, newRV);
					const primaryMayHaveBeenVerified = !this.isEmailStatusVerifed('primary') && mayHaveBeenVerified(this.email, newRV, oldRV);
					const alternateMayHaveBeenVerified = !this.isEmailStatusVerifed('alternate') && mayHaveBeenVerified(this.alternateEmail, newRV, oldRV);
					
					// emailStatus must be refreshed regardless of whether there is a RV in progress or not (maybe the customer receives
					// the standard verification email after all).
					// Fetching emailStatus gets the status for both the primary and the alternate email in a single go.
					this.triggerPropertyUpdate('emailStatus')
						.then(()=> {
							if (primaryMayHaveBeenVerified || alternateMayHaveBeenVerified) {
								const onSuccessfulVerification = ()=> {
									Toast.showSuccess('Customer email successfully verified.');
								};
								const onFailedVerification = ()=> {
									Toast.showError('Failure to verify customer email.');
								};

								if (primaryMayHaveBeenVerified && this.isEmailStatusVerifed('primary') ||
									alternateMayHaveBeenVerified && this.isEmailStatusVerifed('alternate')) {
									onSuccessfulVerification();
								} else {
									onFailedVerification();
								}
							}
						});

					const shouldCheckAgainForEmailType = (emailType)=> !this.isEmailStatusVerifed(emailType);
					const shouldCheckAgain = shouldCheckAgainForEmailType('primary') || shouldCheckAgainForEmailType('alternate');

					if (shouldCheckAgain) {
						CustomerModel.reverseVerificationCheckerTimeoutId = $timeout(()=> this.checkReverseVerificationRegularlyInTheBackground(), CHECK_INTERVAL);
					}
					
					return response;
				});
		};

		CustomerModel.prototype.generateReverseVerificationCode = function(email, comment) {
			return AboApi.generateReverseEmailVerificationCode(this.accountId, email, comment)
				.then((response)=> {
					const reverseVerification = this.reverseVerification || {};
					reverseVerification[email] = response.data;
					this.setProperties(reverseVerification, 'reverseVerification');
				});
		};

		CustomerModel.prototype.cancelReverseVerification = function(email, comment) {
			return AboApi.cancelReverseEmailVerification(this.accountId, email, comment)
				.then(()=> {
					const reverseVerification = this.reverseVerification || {};
					delete reverseVerification[email];
					this.setProperties(reverseVerification, 'reverseVerification');
				});
		};

		CustomerModel.prototype.forceVerifyEmail = function(comment) {
			return AboApi.forceVerifyCustomerEmail(this.accountId, comment)
				.then(()=> this.updateEmailStatus());
		};

		CustomerModel.prototype.addEntitlement = function(code, comment) {
			return AboApi.addCustomerEntitlement(this.accountId, code, comment)
				.then((response)=> {
					/* Update model: add the new entitlement returned by the call to the list */
					const entitlements = (this.entitlements || []).slice();
					entitlements.push(response.data);
					this.setProperties(entitlements, 'entitlements');
				});
		};

		/* Update model: replace the old entitlement with the new one */
		const updateEntitlements = (customer, newEntitlement)=> {
			const entitlements = customer.entitlements.map((oldEntitlement)=>
				oldEntitlement.code === newEntitlement.code &&
				oldEntitlement.accountId === newEntitlement.accountId ?
				newEntitlement : oldEntitlement);
			customer.setProperties(entitlements, 'entitlements');
		};

		CustomerModel.prototype.updateEntitlementStatus = function(code, serviceId, status, comment) {
			return AboApi.updateCustomerEntitlementStatus(this.accountId, code, serviceId, status, comment)
				.then((response)=> {
					updateEntitlements(this, response.data);
				});
		};

		CustomerModel.prototype.grantEntitlementVip = function(code, serviceId, comment) {
			return AboApi.grantCustomerEntitlementVip(this.accountId, code, serviceId, comment)
				.then((response)=> {
					updateEntitlements(this, response.data);
				});
		};

		CustomerModel.prototype.revokeEntitlementVip = function(code, serviceId, comment) {
			return AboApi.revokeCustomerEntitlementVip(this.accountId, code, serviceId, comment)
				.then((response)=> {
					updateEntitlements(this, response.data);
				});
		};

		CustomerModel.prototype.saveEntitlements = function(changes, comment) {
			const parameterTransformation = {
				assignableSeats: 'assignable_seats',
				storageQuotaMin: 'storage_quota_min'
			};
			/* Build map of changes by entitlement */
			const changeMap = {};
			changes.forEach((change)=> {
				const isEntitlementForChange = (entitlement)=> entitlement.code === change.entitlement.code &&
				                                               entitlement.accountId === change.entitlement.accountId;
				const entitlement = this.entitlements.find(isEntitlementForChange);
				const key = change.entitlement.code + ' ' + change.entitlement.accountId;
				changeMap[key] = changeMap[key] || {
						code: change.entitlement.code,
						accountId: change.entitlement.accountId,
						params: angular.copy(entitlement.parameters)
					};
				changeMap[key].params[parameterTransformation[change.property]] = String(change.changed);
			});

			/* Call save API for each entitlement, updating the model on success */
			const updated = [];
			const calls = Object.keys(changeMap).map((key)=> {
				const change = changeMap[key];
				return AboApi.saveCustomerEntitlementData(this.accountId, change.code, change.accountId, change.params, comment)
					.then((response)=> {
						updated.push({code: change.code, accountId: change.accountId});
						updateEntitlements(this, response.data);
					});
			});

			/* Return a promise that resolves when all entitlements have been updated and rejects with the value of
			 * all of the updated entitlements on a partial success
			 */
			return PromiseUtility.allSettled(calls)
				.then((states)=> {
					const getValue = (object)=> object.value;
					const hasFails = (failed, snapshot)=> failed || snapshot.state === PromiseUtility.STATE.REJECTED;
					return states.reduce(hasFails, false) ? $q.reject(updated) : states.map(getValue);
				});
		};

		CustomerModel.prototype.delete = function(comment) {
			return AboApi.deleteCustomer(this.accountId, comment);
		};
		
		CustomerModel.prototype.createGDPRRequest = function(type, comment) {
			return AboApi.createCustomerGDPRRequest(this.accountId, type, comment)
				.then(()=> {
					this.triggerGDPRPropertiesUpdate();
				});
		};
		
		CustomerModel.prototype.triggerGDPRPropertiesUpdate = function() {
			return this.triggerUpdateProperties(['gdprRequests', 'status', 'temporaryStatus']);
		};
		
		CustomerModel.prototype.cancelGDPRRequest = function(requestId, comment) {
			return AboApi.cancelCustomerGDPRRequest(this.accountId, requestId, comment)
				.then(()=> {
					this.triggerGDPRPropertiesUpdate();
				});
		};
		
		CustomerModel.prototype.setGDPRRequestStatusToPending = function(requestId) {
			return AboApi.setGDPRRequestStatusToPending(this.accountId, requestId)
				.then(()=> {
					this.triggerGDPRPropertiesUpdate();
				});
		};
		
		CustomerModel.prototype.getCustomerAccessGDPRReport = function(requestId) {
			return AboApi.getCustomerAccessGDPRReport(requestId);
		};

		return CustomerModel;
	}

}());
