/******************************************************************************************
 *    Audit Model
 ******************************************************************************************/
(function() {
	'use strict';


	angular.module('entities.AuditModel', [])
		.factory('AuditModel', AuditModelFactory);


	function AuditModelFactory(ModelFactory) {
		const searchDetailsProperties = ['id', 'createdDate', 'auditActionName', 'comment', 'parametersMap',
			'agentAccountId', 'agentUsername', 'agentStatus', 'accountId', 'username', 'fullname'];

		const initFn = function(id) {
			// Uniquely identifies the model
			this.id = id;

			// Make sure no errors pop up even if some properties are not set
			this.registerPropertiesFetcher(null, searchDetailsProperties);
		};

		const AuditModel = ModelFactory(initFn);

		AuditModel.prototype.setSearchDetails = function(details) {
			this.setProperties(details, searchDetailsProperties);
		};

		return AuditModel;
	}

}());
