/******************************************************************************************
 *    Agent Constants
 ******************************************************************************************/
(function() {
	'use strict';


	angular.module('agents.Constants', [])
		// Account statuses
		.constant('AGENT_STATUS', {
			ACTIVE: 'ACTIVE',
			DISABLED: 'DISABLED'
		})
		.run(($rootScope, AGENT_STATUS)=> $rootScope.AGENT_STATUS = AGENT_STATUS)
		// Authentication source for all agents (includes @)
		.constant('AGENT_AUTH_SOURCE', '@adobe.com');
}());
