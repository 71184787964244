/******************************************************************************************
 *    Audit Quick Result
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('audits.auditQuickResult', [])
		.controller('auditQuickResultController', auditQuickResultController)
		.directive('aboAuditQuickResult', auditQuickResultDirective);


	/*=== Controller ===*/
	function auditQuickResultController($scope, $state, CustomerService, AgentService, PermissionsModel, User,
	                                    AuditCustomerVm, AgentDetailsVm, AgentAccountAccessVm) {
		/* jshint validthis: true */
		$scope.$watch(()=>this.audit, ()=> {
			this.isActionOnAgent = this.audit.isActionOnAgent;
			this.hasCustomer = !this.isActionOnAgent && !!this.audit.customerAccountId;

			if (this.isActionOnAgent) {
				const agent = AgentService.get(this.audit.customerAccountId);

				// TODO: create new VM for this section
				this.target = new AgentDetailsVm(agent);
				this.targetStatus = new AgentAccountAccessVm(agent, PermissionsModel.get());
				this.viewTarget = ()=> {
					$state.go('agent.profile', {id: this.audit.customerAccountId});
				};
			}

			if (this.hasCustomer) {
				const customer = CustomerService.get(this.audit.customerAccountId);
				this.customer = new AuditCustomerVm(customer);
			}
		});

		this.canViewCustomer = User.canSeeCustomers();
		this.viewCustomer = ()=> {
			$state.go('customer.details', {id: this.audit.customerAccountId});
		};
		this.canViewAgent = User.canSeeAgents();
		this.viewAgent = ()=> {
			$state.go('agent.profile', {id: this.audit.agentAccountId});
		};
	}


	/*=== Directive ===*/
	function auditQuickResultDirective() {
		return {
			restrict: 'E',
			templateUrl: 'audit-quick-result.html',
			scope: {
				audit: '='
			},
			controller: 'auditQuickResultController',
			controllerAs: 'vm',
			bindToController: true
		};
	}
}());
