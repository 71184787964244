/******************************************************************************************
 *    Customer Account Access View Model
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerGdprAccessReportVm', [])
		.factory('CustomerGdprAccessReportVm', CustomerGdprAccessReportVmFactory);

	function CustomerGdprAccessReportVmFactory(ViewModelFactory) {
		const init = function(customer, gdprAccessReportModel) {
			this.gdprAccessReportModel = gdprAccessReportModel;
			this.accessReport = '';
			if (gdprAccessReportModel.gdprAccessReport) {
				const match = /<body>((.|\s)*)<\/body>/mi.exec(gdprAccessReportModel.gdprAccessReport);
				this.accessReport = match && match.length > 2 ? match[1] : '';
			}
			
			this.accountId = customer.accountId;
			this.email = customer.email || '[N/A]';
			this.alternateEmail = customer.alternateEmail || '[N/A]';
			this.username = customer.username || '[N/A]';
			this.firstName = customer.firstName || '[N/A]';
			this.lastName = customer.lastName || '[N/A]';
		};

		const requiredCustomerModelProperties = ['email', 'alternateEmail', 'username', 'firstName', 'lastName'];
		const requiredReportModelProperties = ['gdprAccessReport'];

		return ViewModelFactory(init, requiredCustomerModelProperties, requiredReportModelProperties);
	}
}());
