/******************************************************************************************
 *    Customer Search Input Directive
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerSearchInput', [])
		.directive('customerSearchInput', CustomerSearchInputDirective);

	function CustomerSearchInputDirective(StringUtility) {
		return {
			require: 'ngModel',
			restrict: 'A',
			link: function(scope, elem, attr, ngModel) { // jshint unused:vars
				const WILDCARD = '*';
				
				// Renga requires printable ASCII characters (@see Java: StringUtils#isAsciiPrintable)
				const checkCharset = (modelValue = '')=> !StringUtility.containsNonAscii(modelValue);

				// Renga needs at least 5 characters before the wildcard
				const REQUIRED_CHARS_BEFORE_WILDCARD = 5;
				const checkCharsBeforeStar = (modelValue = '')=> {
					return modelValue.indexOf(WILDCARD) === -1 ||
						modelValue.indexOf(WILDCARD) >= REQUIRED_CHARS_BEFORE_WILDCARD;
				};
				// There is no valid reason to search for 100+ characters
				const QUERY_MAX_LENGTH = 100;
				const checkMaxLength = (modelValue = '')=> modelValue.length < QUERY_MAX_LENGTH;
				
				
				ngModel.$validators.charset = checkCharset;
				ngModel.$validators.charCountBeforeStar = checkCharsBeforeStar;
				ngModel.$validators.maxLength = checkMaxLength;
			}
		};
	}
}());
