/******************************************************************************************
 *    Customer Log
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerLog', [])
		.controller('CustomerLogController', CustomerLogController)
		.directive('aboCustomerLog', CustomerLogDirective);


	/*=== Controller ===*/
	function CustomerLogController($state, User) {/* jshint validthis: true */
		this.canViewAgent = User.canSeeAgents();
		this.viewAgent = ()=> {
			$state.go('agent.profile', {id: this.log.agentAccountId});
		};
	}


	/*=== Directive ===*/
	function CustomerLogDirective() {
		return {
			restrict: 'E',
			templateUrl: 'customer-log.html',
			scope: {
				log: '='
			},
			controller: 'CustomerLogController',
			controllerAs: 'vm',
			bindToController: true
		};
	}
}());
