/******************************************************************************************
 *    Customer Entitlements Controller
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerEntitlementsController', [])
		.controller('CustomerEntitlementsController', CustomerEntitlementsController);

	function CustomerEntitlementsController($scope, $stateParams, $state, $timeout, Modal, Toast, VM_STATE,
	                                        CustomerService, CustomerEntitlementsVm, EntitlementsModel) {
		const customer = CustomerService.get($stateParams.id);
		const metadata = EntitlementsModel.get();
		this.customerEntitlements = new CustomerEntitlementsVm(customer, metadata);

		this.openStates = [];

		Object.defineProperty(this, 'isLoading', {
			get: ()=> this.customerEntitlements.state === VM_STATE.LOADING
		});

		Object.defineProperty(this, 'changed', {
			get: ()=> !!this.customerEntitlements.changed
		});
		// Reset all fields to their last known server state
		this.discardChanges = ()=> {
			this.customerEntitlements.syncFromModel();
			Toast.showWarning('Customer entitlement changes discarded.');
		};

		// Update the state on the server
		this.saveChanges = (comment)=> {
			Modal.isLoading = true;
			const changes = this.customerEntitlements.getChanges();
			return customer.saveEntitlements(changes, comment)
				.then(()=> {
					this.customerEntitlements.syncFromModel();
					Toast.showSuccess('Customer entitlements successfully saved.');
				})
				.catch((updated)=> {
					updated.forEach((entitlement)=> {
						this.customerEntitlements.syncEntitlementFromModel(entitlement.code, entitlement.accountId);
					});
					$timeout(()=> {
						this.showSaveModal(false, true);
					});
				})
				.finally(()=> Modal.isLoading = false);
		};

		// Prompt the user to give a reason for the changes
		this.showSaveModal = (allowDiscard = false, isRecovered = false)=> {
			const changes = this.customerEntitlements.getChanges();
			return Modal.open({
				title: 'Save customer entitlement changes',
				templateUrl: 'customer-save-entitlements-modal.html',
				locals: {changes, allowDiscard, isRecovered, save: this.saveChanges, discard: this.discardChanges}
			});
		};

		this.showDiscardModal = ()=> {
			return Modal.open({
				title: 'Discard customer entitlement changes',
				templateUrl: 'customer-discard-modal.html',
				locals: {discard: this.discardChanges}
			});
		};

		// Prompt the user to save changes when navigating away from the page
		$scope.$on('$stateChangeStart', (evt, toState, toParams)=> {
			const resumeNavigation = ()=> {
				$state.go(toState, toParams);
			};

			const changes = this.customerEntitlements.getChanges() || [];
			if (changes.length) {
				evt.preventDefault();
				if ($scope.customerEntitlementsForm.$valid) {
					// By the time the modal closing promise resolves, the VM will be in sync with the updated model
					// avoiding to prompt again when navigation is resumed
					this.showSaveModal(true).then(resumeNavigation);
				} else {
					// Invalid forms cannot be saved, but it's still nice to let the user know he made changes
					this.showDiscardModal().then(resumeNavigation);
				}
			}
		});

		const addEntitlement = (code, comment)=> {
			Modal.isLoading = true;
			return customer.addEntitlement(code, comment)
				.then(()=> {
					this.customerEntitlements.syncFromModel();
					Toast.showSuccess('Successfully added entitlement');
				})
				.finally(()=> Modal.isLoading = false);
		};
		// preselectedCode can be undefined.
		this.showAddEntitlementModal = (preselectedCode)=> {
			return Modal.open({
				title: 'Add entitlement',
				templateUrl: 'customer-add-entitlement-modal.html',
				locals: {
					accountId: customer.accountId,
					email: this.customerEntitlements.email,
					entitlements: this.customerEntitlements.entitlementList,
					vm: {
						entitlementCode: preselectedCode
					},
					addEntitlement
				}
			});
		};

		const grantVip = (entitlement, comment)=> {
			Modal.isLoading = true;
			return customer.grantEntitlementVip(entitlement.code, entitlement.accountId, comment)
				.then(()=> {
					this.customerEntitlements.syncEntitlementFromModel(entitlement.code, entitlement.accountId);
					Toast.showSuccess('Successfully granted VIP status');
				})
				.finally(()=> Modal.isLoading = false);
		};
		this.showGrantVipModal = (entitlement)=> {
			return Modal.open({
				title: 'Grant vip',
				templateUrl: 'customer-grant-vip-modal.html',
				locals: {accountId: customer.accountId, email: this.customerEntitlements.email, entitlement, grantVip}
			});
		};

		const revokeVip = (entitlement, comment)=> {
			Modal.isLoading = true;
			return customer.revokeEntitlementVip(entitlement.code, entitlement.accountId, comment)
				.then(()=> {
					this.customerEntitlements.syncEntitlementFromModel(entitlement.code, entitlement.accountId);
					Toast.showSuccess('Successfully revoked VIP status');
				})
				.finally(()=> Modal.isLoading = false);
		};
		this.showRevokeVipModal = (entitlement)=> {
			return Modal.open({
				title: 'Revoke vip',
				templateUrl: 'customer-revoke-vip-modal.html',
				locals: {accountId: customer.accountId, email: this.customerEntitlements.email, entitlement, revokeVip}
			});
		};

		const enableEntitlement = (entitlement, comment)=> {
			Modal.isLoading = true;
			return customer.updateEntitlementStatus(entitlement.code, entitlement.accountId, 'ACTIVE', comment)
				.then(()=> {
					this.customerEntitlements.syncEntitlementFromModel(entitlement.code, entitlement.accountId);
					Toast.showSuccess('Successfully enabled service');
				})
				.finally(()=> Modal.isLoading = false);
		};
		this.showEnableEntitlementModal = (entitlement)=> {
			return Modal.open({
				title: 'Enable service',
				templateUrl: 'customer-enable-entitlement-modal.html',
				locals: {
					accountId: customer.accountId,
					email: this.customerEntitlements.email,
					entitlement,
					enable: enableEntitlement
				}
			});
		};

		const disableEntitlement = (entitlement, comment)=> {
			Modal.isLoading = true;
			return customer.updateEntitlementStatus(entitlement.code, entitlement.accountId, 'DISABLED', comment)
				.then(()=> {
					this.customerEntitlements.syncEntitlementFromModel(entitlement.code, entitlement.accountId);
					Toast.showSuccess('Successfully disabled service');
				})
				.finally(()=> Modal.isLoading = false);
		};
		this.showDisableEntitlementModal = (entitlement)=> {
			return Modal.open({
				title: 'Disable service',
				templateUrl: 'customer-disable-entitlement-modal.html',
				locals: {
					accountId: customer.accountId,
					email: this.customerEntitlements.email,
					entitlement,
					disable: disableEntitlement
				}
			});
		};

		const changeStatus = (entitlement, status, comment)=> {
			Modal.isLoading = true;
			return customer.updateEntitlementStatus(entitlement.code, entitlement.accountId, status, comment)
				.then(()=> {
					this.customerEntitlements.syncEntitlementFromModel(entitlement.code, entitlement.accountId);
					Toast.showSuccess('Successfully changed service status');
				})
				.finally(()=> Modal.isLoading = false);
		};
		this.showChangeEntitlementStatusModal = (entitlement)=> {
			return Modal.open({
				title: 'Change status',
				templateUrl: 'customer-change-entitlement-status-modal.html',
				locals: {
					accountId: customer.accountId,
					email: this.customerEntitlements.email,
					entitlement,
					changeStatus
				}
			});
		};
	}
}());
