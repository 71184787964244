/******************************************************************************************
 *    Customer Route
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerGdprAccessReportRoute', [])
		.config(CustomerRouteConfig);

	function CustomerRouteConfig($stateProvider, PERMISSIONS) {
		$stateProvider

			.state('gdprAccessReport', {
				url: '/gdpr-access-report/:customerId/:requestId',
				templateUrl: 'customer-gdpr-access-report.html',
				controller: 'GdprAccessReportController',
				controllerAs: 'vm',
				data: {
					access: PERMISSIONS.VIEW_CUSTOMER
				}
			});
	}
}());
