/******************************************************************************************
 *    Auth Service
 *******************************************************************************************
 *
 * User authorization via ABO API.
 * Complements ImsAuth, which does user authentication via IMS.
 *
 */
(function() {
	'use strict';

	angular.module('auth.Auth', []).service('Auth', Auth);

	function Auth($q, AboApi, ImsAuth, User) {
		//Access token store
		let accessToken = null;

		//Sets access token
		const setAccessToken = function(token) {
			accessToken = token;
		};
		const unsetAccessToken = function() {
			accessToken = null;
		};

		//Returns current session's access token.
		this.getAccessToken = function() {
			return accessToken;
		};

		//Calls authorization API to know if the current IMS user is also an ABO user.
		this.login = function(imsSession) {
			const deferred = $q.defer();

			//Error handler.
			const onError = function(response) {
				console.log('%cAuth :: UNAUTHORIZED %O', 'font-weight:bold;color:#CC0000;', response);
				deferred.reject(response);
			};

			//Check that an access token was passed in.
			if (!imsSession || !imsSession.accessToken) {
				onError(new Error('No access token specified.'));
				return deferred.promise;
			}

			//Store access token.
			//It needs to be set before making the authorization call.
			//This is because aboApiInterceptor needs it to sign that call.
			setAccessToken(imsSession.accessToken);

			//Authorization call success handler.
			const onSuccess = function(response) {
				//Set current user.
				if (!User.set(imsSession, response.data.permissions)) {
					deferred.reject();
					return deferred.promise;
				}

				console.log('%cAuth :: DONE', 'font-weight:bold;color:#009B77;');
				deferred.resolve();
			};

			console.log('%cAuth :: START', 'font-weight:bold;color:#009B77;');
			AboApi.authorize().then(onSuccess, onError);

			return deferred.promise;
		};

		//Logs out user from IMS, but not before clearing in-memory data about current session
		this.logout = function() {
			unsetAccessToken();
			User.unset();
			ImsAuth.triggerSignOutFlow();
		};
	}
}());
