/******************************************************************************************
 *    Promise Utility Methods
 *******************************************************************************************/
(function() {
	'use strict';

	angular.module('utility.PromiseUtility', []).service('PromiseUtility', PromiseUtility);

	function PromiseUtility($q) {
		const STATE = this.STATE = {
			FULFILLED: 'fulfilled',
			REJECTED: 'rejected'
		};

		/* Executes all promises and returns a single successful promise resolved with
		 * an array of values or rejection reasons.
		 * @see https://github.com/kriskowal/q/wiki/API-Reference#promiseallsettled
		 */
		this.allSettled = function(promises) {
			const wrapPromise = (promise)=> {
				/* Not a real promise */
				if (!promise || !promise.then) {
					return {state: STATE.FULFILLED, value: promise};
				}
				/* Always successful promise */
				return promise.then(
					(value)=> ({state: STATE.FULFILLED, value: value}),
					(error)=> ({state: STATE.REJECTED, reason: error})
				);
			};
			return $q.all(promises.map(wrapPromise));
		};
	}
}());
