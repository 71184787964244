/******************************************************************************************
 *    Agent Route
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('agents.AgentRoute', [])
		.config(AgentRouteConfig);

	function AgentRouteConfig($stateProvider, PERMISSIONS) {
		$stateProvider

			.state('agent', {
				abstract: true,
				url: '/agent/:id',
				// TODO: extract header from this state to a view
				templateUrl: 'agent.html',
				controller: 'AgentHeaderController',
				controllerAs: 'vm',
				data: {
					access: PERMISSIONS.VIEW_AGENTS
				}
			})

			.state('agent.profile', {
				url: '/profile',
				views: {
					agent: {
						templateUrl: 'agent-profile.html',
						controller: 'AgentProfileController',
						controllerAs: 'vm'
					}
				}
			})

			.state('agent.activity', {
				url: '/activity',
				views: {
					agent: {
						templateUrl: 'agent-activity.html',
						controller: 'AgentActivityController',
						controllerAs: 'vm'
					}
				}
			});
	}
}());
