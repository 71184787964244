/******************************************************************************************
 *    Audit Search View Model
 ******************************************************************************************/

/*

 {
 "auditActionId": "2431428",
 "createdDate": "1461329085000",
 "action": "UPDATE_STATUS",
 "comment": "I need a sample response.",
 "parametersMap":
 {
 "reason":"other",
 "status":"active->disabled"
 },
 "accountId": "aturlac@adobe.com",
 "agentUsername": "abo-test",
 "agentAccountId": "abo-test"
 }

 */


(function() {
	'use strict';

	angular.module('audits.AuditSearchVm', [])
		.factory('AuditSearchVm', AuditSearchVmFactory);

	function AuditSearchVmFactory(ViewModelFactory, CUSTOMER_STATUS) {
		const init = function(audit) {
			this.id = audit.id;
			this.date = audit.createdDate;
			this.action = audit.auditActionName;
			this.agentAccountId = audit.agentAccountId;
			this.agentUsername = audit.agentUsername || '[N/A]';
			this.customerAccountId = audit.accountId;
			this.customer = !audit.username ? '[N/A]' :
			                audit.fullname ? `${audit.username} (${audit.fullname})` :
			                audit.username;
			this.comment = audit.comment;
			this.agentStatus = audit.agentStatus || '[N/A]';

			/* Handle parameters */
			/* Normalize cases where logging is done using 3 fields: ParamName, ParamValue and optionally ParamType.
			 Additionally, some of the fields have a space at the end (!!!) */
			const parametersMap = audit.parametersMap || {};
			if (parametersMap.hasOwnProperty('ParamName') && parametersMap.hasOwnProperty('ParamValue') ||
			    parametersMap.hasOwnProperty('ParamName ') && parametersMap.hasOwnProperty('ParamValue ')) {
				parametersMap[parametersMap.ParamName || parametersMap['ParamName ']] =
					parametersMap.ParamValue || parametersMap['ParamValue '];
				delete parametersMap.ParamName;
				delete parametersMap['ParamName '];
				delete parametersMap.ParamValue;
				delete parametersMap['ParamValue '];
				delete parametersMap.ParamType;
				delete parametersMap['ParamType '];
			}

			/* Special functionality for PDNA disabling */
			const disableActions = ['UPDATE_STATUS', 'DISABLE_ACCOUNT'];
			if (disableActions.indexOf(this.action) !== -1 && parametersMap.hasOwnProperty('reason')) {
				this.isPdnaDisabled = parametersMap.reason === 'photodna' ? 'YES' : 'NO';
				delete parametersMap.reason;
			}

			/* Special functionality for batch tool disable */
			if (parametersMap.hasOwnProperty('statusCode')) {
				const statusCodeMapping = [CUSTOMER_STATUS.ACTIVE, CUSTOMER_STATUS.DISABLED,
					CUSTOMER_STATUS.LOCKED, CUSTOMER_STATUS.REMOVED];
				parametersMap.status = statusCodeMapping[parametersMap.statusCode] || CUSTOMER_STATUS.UNKNOWN;
				delete parametersMap.statusCode;
			}

			/* Decides between three columns PARAM FROM TO or two columns PARAM VALUE */
			this.hasChanges = Object.keys(parametersMap).some((prop)=> parametersMap[prop].indexOf('->') >= 0);
			this.parameters = Object.keys(parametersMap).map((prop)=> {
				const values = parametersMap[prop].split('->');
				return {
					property: prop,
					original: values[1] && values[0] || '[N/A]',
					changed: values[1] || values[0] || '[N/A]'
				};
			});

			const actionsOnAgents = ['CREATE_AGENT', 'UPDATE_AGENT_PERMISSIONS', 'UPDATE_AGENT_EXPIRY_DATE',
				'UPDATE_AGENT_MANAGER', 'ENABLE_AGENT', 'DISABLE_AGENT'];
			this.isActionOnAgent = actionsOnAgents.indexOf(this.action) !== -1;
		};

		const requiredModelProperties = ['createdDate', 'auditActionName', 'comment', 'parametersMap',
			'agentAccountId', 'agentUsername', 'agentStatus', 'accountId', 'username', 'fullname'];

		return ViewModelFactory(init, requiredModelProperties);
	}

}());
