/******************************************************************************************
 *    Customer Service
 ******************************************************************************************/
// TODO: rethink this service
(function() {
	'use strict';

	angular.module('customers.CustomerService', [])
		.service('CustomerService', CustomerService);

	function CustomerService(AboApi, CustomerModel, $q) {
		const cachedSearch = {
			query: '',
			results: []
		};
		const cacheSearch = (query, results) => {
			cachedSearch.query = query || '';
			cachedSearch.results = results || [];
		};

		// used to cancel the latest search
		let searchCanceler;

		/* retrieves an array of customers. Each of the customers is filled with the properties returned by the search API */
		this.search = function(query) {
			// cancel previous search if any
			this.search.cancel();

			// make actual call to backend
			searchCanceler = $q.defer();
			return AboApi.findCustomers(query, searchCanceler.promise)
				.then((result) => {
					const customers = result.data.map((customerData)=> {
						var customer = new CustomerModel(customerData.accountId);
						customer.setSearchDetails(customerData);
						return customer;
					});

					cacheSearch(query, customers);

					return customers;
				});
		};

		this.search.cancel = function() {
			if (searchCanceler) {
				searchCanceler.resolve();
				searchCanceler = null;
			}
		};

		this.getLastSearchQuery = function() {
			return cachedSearch.query;
		};

		var cachedCustomer = {};
		/* retrieves a single customer */
		this.get = function(id) {
			const hasCorrectId = (customer)=> customer.accountId === id;

			// Replace the cachedCustomer with the one that will be returned if necessary
			if (!cachedCustomer.accountId || !hasCorrectId(cachedCustomer)) {
				cachedCustomer = cachedSearch.results.find(hasCorrectId) || new CustomerModel(id);
			}

			return cachedCustomer;
		};

		this.clearCache = function() {
			cachedCustomer = {};
			cacheSearch();
		};
	}
}());
