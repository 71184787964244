/******************************************************************************************
 *    User Service
 *******************************************************************************************
 *
 * Stores and provides information about the currently logged in user.
 *
 */
(function() {
	'use strict';

	angular.module('auth.User', []).service('User', User);

	function User(Permissions, PERMISSIONS, TakeoverMessage) {
		//In-memory store for current user
		let user = {
			id: null,
			firstName: null,
			lastName: null,
			email: null,
			permissions: null
		};

		//Sets current user session and returns true on success and false otherwise.
		this.set = function(imsSession, aboPermissions) {
			if (!imsSession || !imsSession.userId || !Permissions.validate(aboPermissions)) {
				console.log('%cUser :: SET ERROR %O', 'font-weight:bold;color:#CC0000;', {
					profile: imsSession,
					permissions: aboPermissions
				});
				return false;
			}

			user.id = imsSession.userId;
			user.firstName = imsSession.firstName;
			user.lastName = imsSession.lastName;
			user.email = imsSession.email;
			user.permissions = aboPermissions;

			console.log('%cUser :: SET %O', 'font-weight:bold;color:#009B77;', user);
			return true;
		};

		//Unset current user session
		this.unset = function() {
			/*jshint forin:false*/
			for (let prop in user) {
				user[prop] = null;
			}
			/*jshint forin:true*/
		};

		//Checks if the passed-in permission is contained within the current user's permissions.
		this.hasPermission = function(permission) {
			return user.permissions && user.permissions.indexOf(permission) >= 0;
		};

		//Returns a string representing the default stateName for the current user.
		this.getDefaultStateName = function() {
			return this.hasPermission(PERMISSIONS.VIEW_CUSTOMER) && 'customerSearch' ||
			       this.hasPermission(PERMISSIONS.VIEW_AGENTS) && 'agentSearch' ||
			       this.hasPermission(PERMISSIONS.VIEW_ACTIVITY) && 'auditSearch' ||
			       TakeoverMessage.showUnauthorizedUserError();
		};

		//Returns display name
		this.getDisplayName = function() {
			//Return `undefined` if none of the two names exist.
			if (!user.firstName && !user.lastName) {
				return;
			}

			//If only one of the two names exist, return it
			if (!user.firstName && user.lastName || user.firstName && !user.lastName) {
				return user.firstName || user.lastName;
			}

			return `${user.firstName} ${user.lastName}`;
		};

		this.getEmail = function() {
			return user.email;
		};
		
		this.getId = function() {
			return user.id;
		};

		// Permissions
		this.canSeeCustomers = function() {
			return this.hasPermission(PERMISSIONS.VIEW_CUSTOMER);
		};

		this.canEditCustomerDetails = function() {
			return this.hasPermission(PERMISSIONS.EDIT_CUSTOMER);
		};

		this.canClearDob = function() {
			return this.hasPermission(PERMISSIONS.CLEAR_DOB);
		};

		this.canResetCustomerPassword = function() {
			return this.hasPermission(PERMISSIONS.EDIT_CUSTOMER);
		};

		this.canSetCustomerPassword = function() {
			return this.hasPermission(PERMISSIONS.EDIT_CUSTOMER);
		};

		this.canVerifyCustomerEmail = function() {
			return this.hasPermission(PERMISSIONS.EDIT_CUSTOMER);
		};

		this.canEditCustomerStatus = function() {
			return this.hasPermission(PERMISSIONS.EDIT_STATUS);
		};
		
		this.canForceResetCustomerPassword = function() {
			return this.hasPermission(PERMISSIONS.PDNA);
		};

		this.canEditCustomerStatusToNotBeDeleted = function() {
			return this.hasPermission(PERMISSIONS.EDIT_STATUS_TO_NOT_BE_DELETED);
		};

		this.canEditCustomerStatusWithPdna = function() {
			return this.hasPermission(PERMISSIONS.EDIT_STATUS) && this.hasPermission(PERMISSIONS.PDNA);
		};

		this.canAddEntitlement = function() {
			return this.hasPermission(PERMISSIONS.ADD_ENTITLEMENT_ACROBAT);
		};
		this.canEditEntitlementStatus = function() {
			return this.hasPermission(PERMISSIONS.EDIT_ENTITLEMENT);
		};
		this.canEditEntitlementStatusExtended = function() {
			return this.hasPermission(PERMISSIONS.EDIT_ENTITLEMENT) &&
			       this.hasPermission(PERMISSIONS.EDIT_ALL_ENTITLEMENT_STATUS_CODES);
		};
		this.canChangeEntitlementVipStatus = function() {
			return this.hasPermission(PERMISSIONS.EDIT_ENTITLEMENT) &&
			       this.hasPermission(PERMISSIONS.ADD_ENTITLEMENT_ACROBAT);
		};

		this.canChangeEntitlementAssignableSeats = function() {
			return this.hasPermission(PERMISSIONS.EDIT_ENTITLEMENT) &&
			       this.hasPermission(PERMISSIONS.ADD_ENTITLEMENT_ACROBAT);
		};

		this.canChangeEntitlementQuota = function() {
			return this.hasPermission(PERMISSIONS.EDIT_ENTITLEMENT);
		};

		this.canExportProfile = function() {
			return this.hasPermission(PERMISSIONS.VIEW_CUSTOMER);
		};

		this.canViewAssets = function() {
			return this.hasPermission(PERMISSIONS.VIEW_VADER_ASSETS);
		};

		this.canDeleteCustomer = function() {
			return this.hasPermission(PERMISSIONS.DELETE_CUSTOMER);
		};

		// Agent permissions

		this.canSeeAgents = function() {
			return this.hasPermission(PERMISSIONS.VIEW_AGENTS);
		};

		const isUserAdmin = ()=> this.hasPermission(PERMISSIONS.MANAGE_ABO_RIGHTS) ||
		                         this.hasPermission(PERMISSIONS.MANAGE_ADMINISTRATOR_RIGHTS);

		this.canAddAgent = function() {
			return this.hasPermission(PERMISSIONS.ADD_AGENTS) && isUserAdmin();
		};

		this.canEditAgent = function(agentId) {
			return this.hasPermission(PERMISSIONS.EDIT_AGENTS) && isUserAdmin() &&
			       (user.accountId !== agentId || this.hasPermission(PERMISSIONS.MANAGE_ADMINISTRATOR_RIGHTS));
		};

		this.canExpireAgent = function() {
			return this.hasPermission(PERMISSIONS.EDIT_AGENTS);
		};

		this.canEditPermissions = function(permissionType) {
			return permissionType === 'ABO' && this.hasPermission(PERMISSIONS.MANAGE_ABO_RIGHTS) ||
			       permissionType === 'PLATFORM' && this.hasPermission(PERMISSIONS.MANAGE_ADMINISTRATOR_RIGHTS);
		};

		this.canSeeActivity = function() {
			return this.hasPermission(PERMISSIONS.VIEW_ACTIVITY);
		};
		
		this.canImpersonateCustomer = function() {
			return this.hasPermission(PERMISSIONS.IMPERSONATE);
		};
	}
}());
