/******************************************************************************************
 *    Customer Terms Of Use View Model
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerTermsOfUseVm', [])
		.factory('CustomerTermsOfUseVm', CustomerTermsOfUseVmFactory);

	function CustomerTermsOfUseVmFactory(EditableViewModelFactory, CUSTOMER_ACCOUNT_TYPE, CUSTOMER_STATUS, CUSTOMER_TOU_STATUS, User) {
		const init = function(customer) {
			const tou = this.termsOfUse = angular.copy(customer.termsOfUse) || {};
			this.accountType = CUSTOMER_ACCOUNT_TYPE[customer.authSrcType] || CUSTOMER_ACCOUNT_TYPE.UNKNOWN;
			this.hasEditableFields = User.canEditCustomerDetails();
			this.isEditableCustomer = customer.status === CUSTOMER_STATUS.ACTIVE && !!customer.username;
			this.status = CUSTOMER_TOU_STATUS[tou.status] || CUSTOMER_TOU_STATUS.UNKNOWN;
			this.acceptedDate = tou.acceptedDate || '[N/A]';
			this.acceptedLanguage = tou.acceptedLanguage || '[N/A]';
			// We need this indirection for termsOfUse.revision in order to raise `changed` events
			this.termsOfUse.revision = tou.revision ? tou.revision.toString() : this.hasEditableFields ? '' : '[N/A]';
			this.latestAccepted = tou.latestAccepted === true;

			this.hasDetails = this.status !== CUSTOMER_TOU_STATUS.UNKNOWN;

			this.onlyNumbers = /^\d+$/;
			this.revisionMaxLength = 10;
		};

		const requiredModelProperties = ['termsOfUse'];

		return EditableViewModelFactory(init, requiredModelProperties);
	}
}());
