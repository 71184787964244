/******************************************************************************************
 *    Customer GDPR Controller
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerGDPRController', [])
		.controller('CustomerGDPRController', CustomerGDPRController);

	function CustomerGDPRController($stateParams, CustomerService, CustomerGDPRVm, VM_STATE, Modal, Toast, User) {
		const REQUEST_TYPE = {
			DELETE: 'DELETE',
			ACCESS: 'ACCESS',
			AGE_OUT_DELETE: 'AGE_OUT_DELETE'
		};
		
		var customer = CustomerService.get($stateParams.id);
		this.customer = customer;
		
		this.customerGDPR = new CustomerGDPRVm(customer);
		
		Object.defineProperty(this, 'isLoading', {
			get: ()=> this.customerGDPR.state === VM_STATE.LOADING
		});

		this.showCreateGDPRModal = ()=> {
			return Modal.open({
				title: 'Create GDPR Request',
				templateUrl: 'customer-gdpr-create-request-modal.html',
				controller: function() {},
				controllerAs: 'vm',
				locals: {
					accountId: customer.accountId,
					email: customer.email,
					binding: {
						requestType: REQUEST_TYPE.ACCESS,
						customerGDPR: this.customerGDPR
					},
					createGDPRRequest: (requestType, comment)=> {
						Modal.isLoading = true;
						return customer.createGDPRRequest(requestType, comment)
							.then(() => {
								Toast.showSuccess('GDPR request created successfully.');

								Modal.isLoading = false;
							})
							.catch(()=> Toast.showError('Didn\'t create the GDPR request.'));
					}
				}
			});
		};

		this.showCancelGDPRModal = (requestId, requestType)=> {
			return Modal.open({
				title: 'Cancel GDPR Request',
				templateUrl: 'customer-gdpr-cancel-request-modal.html',
				controller: function() {},
				controllerAs: 'vm',
				locals: {
					accountId: customer.accountId,
					email: customer.email,
					requestId,
					requestType,
					cancelGDPRRequest: (comment)=> {
						Modal.isLoading = true;
						return customer.cancelGDPRRequest(requestId, comment)
							.then(() => {
								Toast.showSuccess('GDPR request canceled successfully.');
								Modal.isLoading = false;
							})
							.catch(()=> Toast.showError('Didn\'t cancel the GDPR request.'));
					}
				}
			});
		};

		this.setGDPRRequestStateToPending = (requestId)=> {
			if (!User.canDeleteCustomer()) {
				if (confirm('You need the DELETE_CUSTOMER permission to update the request to pending.\n' +
					'You can ask for this request to be updated at https://imstracker.zendesk.com/.\n' +
					'Or you can request the permission, if you have a business reason for it.\n' +
					'Do you want to visit https://imstracker.zendesk.com/ ?')) {
					window.open('https://imstracker.zendesk.com/', 'imstracker');
				}
				return;
			}
			if (confirm('Are you sure you want to update the state of this request from IN_PROGRESS to PENDING?' +
					' This can\'t be undone. The whole account would be deleted (#ACCOUNT#).'
					.replace('#ACCOUNT#', this.customer.email))) {
				return customer.setGDPRRequestStatusToPending(requestId)
					.then(() => {
						Toast.showSuccess('GDPR request Status set to Pending.');
					})
					.catch(()=> Toast.showError('Didn\'t set the GDPR request Status to Pending.'));
			}
		};
	}
}());
