/******************************************************************************************
 *    Customer GDPR View Model
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerGDPRVm', [])
		.factory('CustomerGDPRVm', CustomerGDPRVmFactory);

	function CustomerGDPRVmFactory(ViewModelFactory, GeneralUtility, User, EnvironmentConfiguration, CUSTOMER_STATUS, CUSTOMER_ACCOUNT_TYPE) {
		const init = function(customer) {
			const areElevatedGDPRRightsEnabled = EnvironmentConfiguration.getValue('areElevatedGDPRRightsEnabled');
			
			const isCustomerRemoved = customer.status === CUSTOMER_STATUS.REMOVED;
			const isIndividualAccountType = CUSTOMER_ACCOUNT_TYPE[customer.authSrcType] === CUSTOMER_ACCOUNT_TYPE.INDIVIDUAL;
			
			const isLoading = !Boolean(customer.status);

			const REQUEST_TYPE = {
				DELETE: 'GDPR_DELETE',
				ACCESS: 'GDPR_ACCESS',
				AGE_OUT_DELETE: 'AGE_OUT_DELETE'
			};
			const isAccess = (gdprRequest) => gdprRequest.type === REQUEST_TYPE.ACCESS;
			const isDelete = (gdprRequest) => gdprRequest.type === REQUEST_TYPE.DELETE;
			const isAgeOutDelete = (gdprRequest) => gdprRequest.type === REQUEST_TYPE.AGE_OUT_DELETE;
			const isDeleteOrAgeOut = (gdprRequest) => isDelete(gdprRequest) || isAgeOutDelete(gdprRequest);
			
			const REQUEST_STATUS = {
				IN_PROGRESS: 'IN_PROGRESS',
				PENDING: 'PENDING',
				CANCELLED: 'CANCELLED',
				FAILED: 'FAILED',
				COMPLETED: 'COMPLETED'
			};
			const isInProgress = (r) => r.status === REQUEST_STATUS.IN_PROGRESS;
			const isPending = (r) => r.status === REQUEST_STATUS.PENDING;
			const isCanceled = (r) => r.status === REQUEST_STATUS.CANCELLED;
			//const isFailed = (r) => r.status === REQUEST_STATUS.FAILED;
			const isCompleted = (r) => r.status === REQUEST_STATUS.COMPLETED;
			const isForbiddingOtherNewRequestsOfSameType = (r) => isInProgress(r) || isPending(r) || isCompleted(r);
			const isCancelable = (r) => isInProgress(r) && !isAgeOutDelete(r);

			// a customer account disabled through a GDPR Delete request will be deleted in 60 days by IRS.
			this.agentPermissionCanDeleteCustomerGDPR = User.canEditCustomerStatus();

			this.processGDPRRequest = (r) => {
				const canBeCanceled = isCancelable(r) && this.agentPermissionCanDeleteCustomerGDPR;
				const hasResultLink = isAccess(r) && !isCanceled(r);
				const canBeSetToPending = areElevatedGDPRRightsEnabled && isInProgress(r) && isDeleteOrAgeOut(r) && this.agentPermissionCanDeleteCustomerGDPR;
				let processed = angular.merge({}, r, {
					canBeCanceled,
					canBeSetToPending,
					hasResultLink,
					hasAnyAction: canBeCanceled || canBeSetToPending || hasResultLink
				});
				return processed;
			};
			
			this.gdprRequests = [].concat(GeneralUtility.ensureArray(customer.gdprRequests)).map(this.processGDPRRequest);
			this.hasAnyAction = Boolean(this.gdprRequests.find((r) => r.hasAnyAction));
			
			
			this.hasAnyActiveDeleteRequest = Boolean(this.gdprRequests.find((r) => isDeleteOrAgeOut(r) && isForbiddingOtherNewRequestsOfSameType(r)));
			this.hasAnyCancelableDeleteRequest = Boolean(this.gdprRequests.find((r) => isDelete(r) && isCancelable(r)));
			this.hasAnyCompletedDeleteRequest = Boolean(this.gdprRequests.find((r) => isDelete(r) && isCompleted(r)));
			this.canCustomerAccountBeDisabledWithGDPR = !isLoading && this.agentPermissionCanDeleteCustomerGDPR && !this.hasAnyActiveDeleteRequest &&
				!isCustomerRemoved && isIndividualAccountType;
			this.hasCreateDeleteRequest = this.canCustomerAccountBeDisabledWithGDPR;
			this.hasCreateAgeOutDeleteRequest = this.canCustomerAccountBeDisabledWithGDPR && areElevatedGDPRRightsEnabled;
			this.canCustomerAccountBeEnabledWithGDPR = !isLoading && this.agentPermissionCanDeleteCustomerGDPR && this.hasAnyCancelableDeleteRequest &&
				!isCustomerRemoved && isIndividualAccountType;
			this.hasAnyEnableDisableButton = this.canCustomerAccountBeDisabledWithGDPR || this.canCustomerAccountBeEnabledWithGDPR;
		};

		const requiredModelProperties = ['gdprRequests', 'status'];

		return ViewModelFactory(init, requiredModelProperties);
	}
}());
