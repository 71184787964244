/******************************************************************************************
 *    App Run-Block
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('app.run',[]).run(runBlock);

	function runBlock(AppInitializer, TakeoverMessage, ImsAuth, Auth, EnvironmentConfiguration, StatePatrol, AppStatus) {
		AppInitializer.init()//Init critical dependencies.
			.catch(TakeoverMessage.showGenericError)//If init() fails, show generic error takeover message.
			.then(ImsAuth.getLoggedInUser)//If an active session is detected, return information about it, otherwise
		                                  // reject.
			.then(Auth.login, ImsAuth.triggerSignInFlow)//If active session is found, run authorization logic,
		                                                // otherwise trigger sign-in flow.
			.catch(TakeoverMessage.showUnexpectedLoginError)//If Auth.login() fails, break the flow.
			.then(EnvironmentConfiguration.fetch)// Load environment configuration
			.then(AppInitializer.enableRouting)//Enable routing.
			.then(StatePatrol.init)//Enable state access logic.
			.then(AppStatus.done)//We're done!
			.catch(TakeoverMessage.showGenericError);//Safety net for any uncaught errors.
	}
})();
