/******************************************************************************************
 *    Agent Search Controller
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('agents.AgentSearchController', [])
		.controller('AgentSearchController', AgentSearchController);

	function AgentSearchController($stateParams, $state, User, Modal, Toast, fromDateStringFilter,
	                               AgentService, AgentSearchVm, AgentPermissionsVm, AgentModel,
	                               PermissionsModel, RolesModel, AGENT_AUTH_SOURCE) {
		// convenience computed property to simplify view logic
		Object.defineProperty(this, 'hasAgents', {
			get: ()=>this.agents.length !== 0
		});

		this.search = ()=> {
			if (!this.query) {
				return;
			}

			// Update the URL, for deep linking
			$state.go('agentSearch', {query: this.query}, {notify: false, location: true});

			// Close the quick profile
			this.agent = null;

			this.isLoading = true;
			AgentService.search(this.query)
				.then((agents)=> {
					this.agents = agents.map((agent)=>new AgentSearchVm(agent));
				})

				.catch((error)=> {
					console.error(error);
					// TODO: error state
				})

				.finally(()=> {
					this.isPristine = false;
					this.isLoading = false;
				});
		};

		this.resetSearch = ()=> {
			AgentService.search.cancel();
			this.agents = [];
			this.agent = null;
			this.isPristine = true;
			this.isLoading = false;
			this.query = '';
		};


		this.showAgent = (agent)=> {
			this.agent = agent;
		};

		this.hasAddAgentButton = User.canAddAgent();
		const addAgent = (username, manager, permissions, expireDateString, comment)=> {
			Modal.isLoading = true;
			manager = manager ? manager + '@adobe.com' : undefined;
			username = username ? username + '@adobe.com' : undefined;
			const expireDate = fromDateStringFilter(expireDateString);

			return AgentService.create(username, manager, permissions, expireDate, comment)
				.then((agent)=> {
					$state.go('agent.profile', {id: agent.accountId});
					Toast.showSuccess('Agent successfully created.');
				})
				.finally(()=> Modal.isLoading = false);
		};
		const permissionsModel = PermissionsModel.get();
		const rolesModel = RolesModel.get();
		this.showAddAgentModal = ()=> {
			/* prepare permissions VM on a fake agent */
			const agent = new AgentModel(null);
			agent.setProperties([], 'permissions');
			const agentPermissions = new AgentPermissionsVm(agent, permissionsModel, rolesModel);
			const expireDatePattern = /^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/;
			const canSeeType = (type)=> agentPermissions[type] && agentPermissions[type].editable;
			Modal.open({
				title: 'Add agent',
				templateUrl: 'agent-create-modal.html',
				locals: {agentPermissions, canSeeType, expireDatePattern, AGENT_AUTH_SOURCE, addAgent}
			});
		};

		// setup initial state
		this.resetSearch();

		// returning to search will set the query parameter and re-perform the search
		if ($stateParams.query) {
			this.query = $stateParams.query;
			this.search();
		}
	}
}());
