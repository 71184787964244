/******************************************************************************************
 *    Accordion Directive
 ******************************************************************************************
 *
 * Expected markup:
 *
 * <ul abo-accordion>
 *   <li abo-accordion-item>
 *     <h3 abo-accordion-title>First accordion item</h3>
 *     <p>First accordion title</p>
 *   </li>
 *
 *   <li abo-accordion-item is-open>
 *     <h3 abo-accordion-title>Second accordion item</h3>
 *     <p abo-accordion-body>Second accordion title</p>
 *   </li>
 *
 *   <li abo-accordion-item is-open="item.isOpen">
 *     <h3 abo-accordion-title>Third accordion title</h3>
 *     <p abo-accordion-body>Third accordion title</p>
 *   </li>
 * </ul>
 */
(function() {
	'use strict';

	angular.module('coreDirectives.Accordion', [])
		.controller('AccordionItemCtrl', AccordionItemCtrl)
		.directive('aboAccordionItem', AccordionItem);


	function AccordionItem() {
		return {
			restrict: 'A',
			controller: AccordionItemCtrl
		};
	}

	function AccordionItemCtrl($scope, $element, $attrs, $animate, $parse, DomUtility, $q) {
		const TITLE_SELECTOR = '[abo-accordion-title]';
		
		let lastOpenPromise = $q.resolve();
		let lastClosePromise = $q.resolve();

		let updateIsOpenAttribute = ()=> {};
		let isOpen;
		Object.defineProperty(this, 'isOpen', {
			get: ()=> isOpen,
			set: (val)=> {
				isOpen = !!val;
				if (isOpen) {
					this.open(true); // dontCheckDuplicateCalls.
				} else {
					this.close(true); // dontCheckDuplicateCalls.
				}
			}
		});
		
		// the default value for dontCheckDuplicateCalls is falsy (undefined).
		this.open = (dontCheckDuplicateCalls) => {
			if (this.isOpen && !Boolean(dontCheckDuplicateCalls)) {
				return lastOpenPromise;
			}
			isOpen = true;
			updateIsOpenAttribute(this.isOpen);
			lastOpenPromise = $animate.addClass($element, 'open');
			return lastOpenPromise;
		};

		// the default value for dontCheckDuplicateCalls is falsy (undefined).
		this.close = (dontCheckDuplicateCalls) => {
			if (!this.isOpen && !Boolean(dontCheckDuplicateCalls)) {
				return lastClosePromise;
			}
			isOpen = false;
			updateIsOpenAttribute(this.isOpen);
			lastClosePromise = $animate.removeClass($element, 'open');
			return lastClosePromise;
		};

		this.toggle = ()=> {
			this.isOpen = !this.isOpen;
		};

		// Initialize isOpen:
		// Check the `isOpen` property of the element to decide if/when the item should be opened/closed
		if (!$attrs.hasOwnProperty('isOpen')) {
			// There is no `isOpen` property, so the item should be closed.
			this.isOpen = false;
		} else if (!$attrs.isOpen) {
			// The `isOpen` property has no value, so the item should start as closed.
			this.isOpen = true;
		} else {
			// The `isOpen` property has a value, so watch the expression to dynamically control openness.
			const getter = $parse($attrs.isOpen);
			$scope.$watch(()=> getter($scope), (isOpen)=> {
				this.isOpen = isOpen;
			});

			if (getter.assign) {
				updateIsOpenAttribute = (value)=> {
					getter.assign($scope, value);
				};
			}
		}

		// Register click handler on title
		$element.on('click', (evt)=> {
			const title = DomUtility.closest(evt.target, TITLE_SELECTOR, $element[0]);

			if (!title || title.classList.contains('disabled')) {
				return;
			}

			$scope.$evalAsync(this.toggle);
		});
	}
}());
