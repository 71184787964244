/******************************************************************************************
 *    FileReader Directive
 ******************************************************************************************
 *
 * Expected markup:
 *
 * <input type="file" file-reader="vm.file"/>
 */
(function() {
	'use strict';

	angular.module('coreDirectives.FileReader', [])
		.directive('fileReader', FileReaderDirective);


	function FileReaderDirective() {
		return {
			restrict: 'A',
			scope: {
				fileReader: '='
			},
			link: FileReaderLink
		};
	}

	function FileReaderLink(scope, element) {
		const reader = new FileReader();

		reader.onload = function() {
			scope.$apply(()=> {
				scope.fileReader = reader.result;
			});
		};

		element.bind('change', function() {
			const file = element[0].files[0];
			if (file instanceof Blob) {
				reader.readAsText(file);
			}
		});
	}
}());
