/******************************************************************************************
 *    Customer Header View Model
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerHeaderVm', [])
		.factory('CustomerHeaderVm', CustomerHeaderVmFactory);


	function CustomerHeaderVmFactory(ViewModelFactory, CUSTOMER_STATUS) {
		const init = function(customer) {
			const status = CUSTOMER_STATUS[customer.status] || CUSTOMER_STATUS.UNKNOWN;

			this.accountId = customer.accountId;
			this.fullName = [customer.firstName, customer.lastName].join(' ').trim() || '[N/A]';
			this.pictureClass = status === CUSTOMER_STATUS.ACTIVE ? '' : 'warning';
			this.tooltip = 'Status: ' + status;
			this.isPdnaDisabled = customer.pdnaDisabled;
			this.isIncomplete = customer.incrementalIncomplete;
		};

		const requiredModelProperties = ['firstName', 'lastName', 'status', 'pdnaDisabled', 'incrementalIncomplete'];

		return ViewModelFactory(init, requiredModelProperties);
	}
}());
