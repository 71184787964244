/******************************************************************************************
 *    Customer Organizations View Model
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerOrganizationsVm', [])
		.factory('CustomerOrganizationsVm', CustomerOrganizationsVmFactory);


	function CustomerOrganizationsVmFactory(ViewModelFactory) {
		const init = function(customer) {
			/** Example of a rawOrganization
			 * {
			 *   name: 'Adobe Systems',
			 *   id: 'FWQFE3142134FD@AdobeOrg',
			 *   type: 'Enterprise',
			 *   tags: ['edu'],
			 *   groupMemberships: [{
			 *     groupName: 'IMSS',
			 *     groupRole: 'ADMIN@stg'
			 *   }]
			 * }
			 */
			const rawOrganizations = customer.organizations || [];
			const rawOrganizationRelationshipsOREOs = customer.organizationRelationshipsOREOs || [];
			const organizations = [];
			rawOrganizations.forEach((organization, organizationIndex)=> {
				const groups = organization.groupMemberships || [];
				// Some users are in organizations but not in any groups.
				// Create an empty group entry to make sure the organization is displayed.
				if (!groups.length) {
					groups.push({});
				}
				
				const oreos = rawOrganizationRelationshipsOREOs.filter(oreo => oreo.orgRef === organization.organizationId);

				groups.forEach((group, groupIndex)=> {
					organizations.push({
						index: organizationIndex + 1,
						groupIndex: groups.length > 1 ? groupIndex + 1 : undefined,
						name: organization.organizationName || '[N/A]',
						id: organization.organizationId || '[N/A]',
						type: organization.organizationType || '[N/A]',
						humanReadableTags: (organization.tags || []).join(', ') || '[N/A]',
						groupName: group.groupName || '[N/A]',
						groupRole: group.groupRole || '[N/A]',
						organizationRelationshipsOREOs: groupIndex === 0 && oreos.length ? oreos : null
					});
				});
			});

			this.organizations = organizations;
			this.organizationCount = rawOrganizations.length;
		};

		const requiredModelProperties = ['organizations', 'organizationRelationshipsOREOs'];

		return ViewModelFactory(init, requiredModelProperties);
	}
}());
