/******************************************************************************************
 *    Customer Profile Controller
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerProfileController', [])
		.controller('CustomerProfileController', CustomerProfileController);

	function CustomerProfileController($scope, $state, $stateParams, $q, Modal, CustomerService,
	                                   CustomerDetailsVm, CustomerAddressesVm, CustomerOrganizationsVm, CustomerRolesVm,
	                                   CustomerCreationDetailsVm, CustomerLoginHistoryVm, Toast, VM_STATE, GeneralUtility, CustomerSessionsVm) {
		this.ACCORDION_IDS = {
			CONTRACTS: 'contracts',
			ENTITLEMENT_LINK_EOA: 'entitlementLinkEOA',
			ENTITLEMENT_LINKS_AA: 'entitlementLinksAA'
		};
		
		var customer = CustomerService.get($stateParams.id);

		this.customerDetails = new CustomerDetailsVm(customer);
		this.customerOrganizations = new CustomerOrganizationsVm(customer);
		this.customerRoles = new CustomerRolesVm(customer);
		this.customerAddresses = new CustomerAddressesVm(customer);
		this.customerCreationDetails = new CustomerCreationDetailsVm(customer);
		this.customerLoginHistory = new CustomerLoginHistoryVm(customer);
		this.customerSessions = new CustomerSessionsVm(customer);

		Object.defineProperty(this, 'isLoading', {
			get: ()=> this.customerDetails.state === VM_STATE.LOADING ||
			this.customerOrganizations.state === VM_STATE.LOADING ||
			this.customerRoles.state === VM_STATE.LOADING ||
			this.customerAddresses.state === VM_STATE.LOADING ||
			this.customerCreationDetails.state === VM_STATE.LOADING ||
			this.customerLoginHistory.state === VM_STATE.LOADING ||
			this.customerSessions.state === VM_STATE.LOADING
		});

		Object.defineProperty(this, 'changed', {
			get: ()=> this.customerDetails.changed
		});


		// Reset all fields to their last known server state
		this.discardChanges = ()=> {
			this.customerDetails.syncFromModel();
			Toast.showWarning('Customer changes discarded.');
		};

		// Update the state on the server
		this.saveChanges = (comment)=> {
			Modal.isLoading = true;
			const changes = this.customerDetails.getChanges();
			return customer.saveDetails(changes, comment)
				.then(()=> {
					this.customerDetails.syncFromModel();
					Toast.showSuccess('Customer details successfully saved.');
				})
				.finally(()=> Modal.isLoading = false);
		};

		// Prompt the user to give a reason for the changes
		this.showSaveModal = (allowDiscard = false)=> {
			const changes = this.customerDetails.getChanges();
			return Modal.open({
				title: 'Save customer details changes',
				templateUrl: 'customer-save-modal.html',
				locals: {changes, allowDiscard, save: this.saveChanges, discard: this.discardChanges}
			});
		};

		this.showDiscardModal = ()=> {
			return Modal.open({
				title: 'Discard customer details changes',
				templateUrl: 'customer-discard-modal.html',
				locals: {discard: this.discardChanges}
			});
		};

		// Prompt the user to save changes when navigating away from the page
		$scope.$on('$stateChangeStart', (evt, toState, toParams)=> {
			const resumeNavigation = ()=> {
				$state.go(toState, toParams);
			};

			const changes = this.customerDetails.getChanges() || [];
			if (changes.length) {
				evt.preventDefault();
				if ($scope.customerProfileForm.$valid) {
					// By the time the modal closing promise resolves, the VM will be in sync with the updated model
					// avoiding to prompt again when navigation is resumed
					this.showSaveModal(true).then(resumeNavigation);
				} else {
					// Invalid forms cannot be saved, but it's still nice to let the user know he made changes
					this.showDiscardModal().then(resumeNavigation);
				}
			}
		});

		const handleVerifyError = (error)=> {
			switch (error.status) {
				case 410:
					Toast.showError('Verification expired, please send new code!');
					/* Resolve promise to close modal */
					return;
				case 429:
					Toast.showError('Too many failed attempts!');
					break;
				case 409:
					Toast.showError('Invalid code!');
					break;
				default:
					Toast.showError('An error occurred, please try again!');
					break;
			}
			/* Keep modal open */
			return $q.reject(error);
		};

		let challenge;
		const sendChallenge = (comment)=> {
			Modal.isLoading = true;
			return customer.sendPhoneChallenge(comment)
				.then((response)=> { challenge = response; })
				.finally(()=> Modal.isLoading = false);
		};

		const verify = (code)=> {
			Modal.isLoading = true;
			return customer.verifyPhoneChallenge(challenge, code)
				.then(()=> {
					Toast.showSuccess('Customer successfully verified!');
				})
				.catch(handleVerifyError)
				.finally(()=> Modal.isLoading = false);
		};

		const showVerifyPhoneModal = ()=> {
			return Modal.open({
				title: 'Text message sent',
				templateUrl: 'customer-verify-phone-modal.html',
				locals: {verify}
			});
		};

		this.showSendPhoneVerificationModal = ()=> {
			return Modal.open({
				title: 'Verify phone number',
				templateUrl: 'customer-send-phone-verification-modal.html',
				locals: {securityPhoneNumber: this.customerDetails.securityPhoneNumber, sendChallenge}
			}).then(showVerifyPhoneModal);
		};
		
		const fixCredentials = (username, comment)=> {
			Modal.isLoading = true;
			return customer.fixCredentials(username, comment)
				.finally(()=> Modal.isLoading = false);
		};

		this.showFixCredentialsModal = ()=> {
			const email = this.customerDetails.email;
			return Modal.open({
				title: 'Fix credentials',
				templateUrl: 'customer-fix-credentials-modal.html',
				controller: function() { this.username = email; },
				controllerAs: 'vm',
				locals: {
					accountId: customer.accountId, email: customer.email, fixCredentials,
					maxLength: this.customerDetails.emailMaxLength, pattern: this.customerDetails.emailPattern
				}
			});
		};


		const clearDob = (comment)=> {
			Modal.isLoading = true;
			return customer.clearDob(comment)
				.then(()=> {
					Toast.showSuccess('The date of birth has been cleared.');
				})
				.finally(()=> Modal.isLoading = false);
		};

		this.showClearDobModal = ()=> {
			return Modal.open({
				title: 'Clear the date of birth',
				templateUrl: 'customer-clear-dob-modal.html',
				locals: {
					accountId: customer.accountId,
					email: customer.email,
					clearDob
				}
			});
		};


		const unlinkSocialProvider = (provider, comment)=> {
			Modal.isLoading = true;
			return customer.unlinkSocialProvider(provider, comment)
				.then(()=> {
					Toast.showSuccess('The social provider has been unlinked.');
				})
				.finally(()=> Modal.isLoading = false);
		};

		this.showUnlinkSocialProviderModal = (provider)=> {
			return Modal.open({
				title: 'Unlink Social Provider',
				templateUrl: 'customer-unlink-social-provider-modal.html',
				locals: {
					accountId: customer.accountId,
					email: customer.email,
					provider,
					unlinkSocialProvider
				}
			});
		};

		this.openAccordion = (accordionItem)=> {
			var aboAccordionItem = angular.element('#' + accordionItem).controller('aboAccordionItem');
			return aboAccordionItem.open();
		};

		this.openAndScrollToAccordion = (accordionItem)=> {
			const selector = '#' + accordionItem;
			// we start the scroll immediately so the user has feedback that something is happening.
			// the accordion may be initially outside of the screen and the user may not otherwise see it opening.
			return $q.all([GeneralUtility.scrollIntoView(selector, '.customer-container'), this.openAccordion(accordionItem)]).
				then(() => GeneralUtility.scrollIntoView(selector, '.customer-container'));
		};
	}
}());
