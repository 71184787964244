/******************************************************************************************
 * EnvironmentConfiguration Service
 *******************************************************************************************
 *
 * Service storing the environment-specific configuration
 *
 */
(function() {
	'use strict';

	angular.module('core.EnvironmentConfiguration', []).service('EnvironmentConfiguration', EnvironmentConfiguration);

	function EnvironmentConfiguration(AboApi) {
		let config = {};

		this.fetch = ()=> {
			return AboApi.getEnvironmentMetadata().then((response)=> {
				config = response.data || {};
			});
		};

		this.getValue = (feature)=> {
			return config[feature];
		};
	}
}());
