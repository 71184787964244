/******************************************************************************************
 *    Customer Route
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerExportRoute', [])
		.config(CustomerRouteConfig);

	function CustomerRouteConfig($stateProvider, PERMISSIONS) {
		$stateProvider

			.state('export', {
				url: '/export/:id',
				templateUrl: 'customer-export.html',
				controller: 'ExportController',
				controllerAs: 'vm',
				data: {
					access: PERMISSIONS.VIEW_CUSTOMER
				}
			});
	}
}());
