/******************************************************************************************
 *    Customer Email View Model
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerEmailVm', [])
		.factory('CustomerEmailVm', CustomerEmailVmFactory);

	function CustomerEmailVmFactory(ViewModelFactory, CUSTOMER_EMAIL_STATUS, CUSTOMER_STATUS, CUSTOMER_ACCOUNT_TYPE,
	                                User, EnvironmentConfiguration) {
		const init = function(customer) {
			// There are IMS client ids that are configured to create customer accounts that aren't required to receive
			// any email verification.
			// In this case, we'll get the email address from the customer data itself, because there isn't any
			// email verification information.
			const initEmailVerificationInformation = (emailVerificationInformation, customerEmail) => {
				const email = emailVerificationInformation.email || customerEmail;
				return {
					email: email || '[N/A]',
					status: CUSTOMER_EMAIL_STATUS[emailVerificationInformation.status] || CUSTOMER_EMAIL_STATUS.UNKNOWN,
					hasDeliveryInfo: Boolean(emailVerificationInformation.lastSendDate),
					lastSendDate: emailVerificationInformation.lastSendDate || '[N/A]',
					deliveryStatus: emailVerificationInformation.deliveryStatus || '[N/A]',
					hasUnverifiedEmailAddress: Boolean(email && CUSTOMER_EMAIL_STATUS[emailVerificationInformation.status] !== CUSTOMER_EMAIL_STATUS.VERIFIED),
					isReverseVerificationInProgress: Boolean(email && customer.reverseVerification && customer.reverseVerification[email])
				};
			};
			const emailStatus = customer.emailStatus || {};
			let primaryEmailVerificationInformation = emailStatus.primary || {};
			let alternateEmailVerificationInformation = emailStatus.alternate || {};
			
			this.primary = initEmailVerificationInformation(primaryEmailVerificationInformation, customer.email);
			this.alternate = initEmailVerificationInformation(alternateEmailVerificationInformation, customer.alternateEmail);
			this.reverseVerification = customer.reverseVerification;
			this.hasAlternateEmail = Boolean(this.alternate.email && this.alternate.email !== '[N/A]');

			this.isReverseVerificationInProgress = this.primary.isReverseVerificationInProgress || this.alternate.isReverseVerificationInProgress;
			let hasCustomerUnverifiedEmailAddresses = this.primary.hasUnverifiedEmailAddress || this.alternate.hasUnverifiedEmailAddress;
			// Once customerHasVerifyButton is set to false you cannot turn it back on.
			this.customerHasVerifyButton = CUSTOMER_ACCOUNT_TYPE[customer.authSrcType] === CUSTOMER_ACCOUNT_TYPE.INDIVIDUAL &&
				hasCustomerUnverifiedEmailAddresses;
			this.agentHasVerifyButton = User.canVerifyCustomerEmail();
			this.hasVerifyButton = this.customerHasVerifyButton && this.agentHasVerifyButton;
			// canVerify can switch from true to false and vice-versa at any time.
			this.canVerify = customer.status === CUSTOMER_STATUS.ACTIVE;
			this.isReverseVerifyButtonDisabled = !this.agentHasVerifyButton || !this.canVerify;
			
			this.hasPrimaryEmailReverseVerificationUI = this.primary.hasUnverifiedEmailAddress && this.customerHasVerifyButton &&
				(this.agentHasVerifyButton || this.primary.isReverseVerificationInProgress);
			this.hasAlternateEmailReverseVerificationUI = this.alternate.hasUnverifiedEmailAddress && this.customerHasVerifyButton &&
				(this.agentHasVerifyButton || this.alternate.isReverseVerificationInProgress);

			this.hasForceVerifyButton = EnvironmentConfiguration.getValue('isForceEmailVerificationEnabled') &&
			                            this.hasVerifyButton && this.primary.hasUnverifiedEmailAddress;
		};

		const requiredModelProperties = ['emailStatus', 'reverseVerification', 'status', 'authSrcType', 'email', 'alternateEmail'];

		return ViewModelFactory(init, requiredModelProperties);
	}
}());
