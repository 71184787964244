/******************************************************************************************
 *    Audit Search Controller
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('audits.AuditSearchController', [])
		.controller('AuditSearchController', AuditSearchController);

	function AuditSearchController($state, $stateParams, AuditService, AuditSearchVm, AUDIT_DATES, AUDIT_FIELDS) {
		this.actions = $stateParams.action ? [$stateParams.actions] : [];
		AuditService.getAvailableActions().then((actions)=> {
			this.actions = actions.sort((a, b)=> (a || '').localeCompare(b));
		});

		this.dates = Object.keys(AUDIT_DATES).filter((key)=> key !== 'ALL').map((key)=> AUDIT_DATES[key]);
		this.fields = Object.keys(AUDIT_FIELDS).filter((key)=> key !== 'ALL').map((key)=> AUDIT_FIELDS[key]);

		// convenience computed property to simplify view logic
		Object.defineProperty(this, 'hasAudits', {
			get: ()=>this.audits.length !== 0
		});


		this.search = ()=> {
			this.audit = null;

			// Update the URL, for deep linking
			$state.go('auditSearch',
				{
					query: this.query,
					field: this.field,
					action: this.action,
					date: this.date
				},
				{notify: false, location: true});

			this.isLoading = true;
			AuditService.search(this.query, this.field, this.action, this.date)
				.then((audits)=> {
					this.audits = audits.map((audit)=>new AuditSearchVm(audit));
				})

				.finally(()=> {
					this.isPristine = false;
					this.isLoading = false;
				});
		};

		this.resetSearch = ()=> {
			AuditService.search.cancel();
			this.audits = [];
			this.audit = null;
			this.isPristine = true;
			this.isLoading = false;
			this.query = '';
			this.field = '';
			this.action = '';
			this.date = '';
		};

		// setup initial state
		this.resetSearch();

		// returning to search will set the query parameter and re-perform the search
		if ($stateParams.query || $stateParams.field || $stateParams.action || $stateParams.date) {
			this.query = $stateParams.query;
			this.field = $stateParams.field;
			this.action = $stateParams.action;
			this.date = $stateParams.date;
			this.search();
		}

		this.openQuickActions = (audit)=> {
			this.audit = audit;
		};
	}
}());
