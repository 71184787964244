/******************************************************************************************
 *    General Utility Methods
 *******************************************************************************************/
(function() {
	'use strict';

	angular.module('utility.GeneralUtility', []).service('GeneralUtility', GeneralUtility);

	function GeneralUtility() {

		this.ensureArray = (a) => Array.isArray(a) ? a : [];
		
		this.ensureObject = (o) => o || {};

		// returns a promise for when the scrolling is done.
		this.scrollIntoView = (selectorScrollTo, containerSelector)=> {
			const durationMilliseconds = 1000;
			const element = angular.element(selectorScrollTo);
			const container = angular.element(containerSelector);
			return container.scrollTo(element, 0, durationMilliseconds);
		};
	}
}());
