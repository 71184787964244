/******************************************************************************************
 *    Model
 ******************************************************************************************
 * The model is the source of truth for an entity in ABO. Any property it has is guaranteed to be up to date with the
 * latest data retrieved from the server.
 * The model provides sync methods to set data from outside sources, methods modeled around the APIs that
 * return data. It also provides load methods that retrieve data from the APIs.
 * Finally, the model provides a convenience method to load specific properties that handles calling APIs transparently.
 * Loading specific properties is smart enough to make the minimum amount of API calls to ensure that the properties
 * are available.
 *
 * Synchronously update the model with already available data
 * ```
 * var customer = new CustomerModel(id);
 * var customerData = { ... };
 * customer.setProperties(customerData, ['name', 'age']);
 * console.log(customer);
 * ```
 *
 * Request new data from the server and update the model
 * ```
 * var customer = new CustomerModel(id);
 * customer.fetchProfile().then(()=> {
 *   console.log(customer);
 * });
 * ```
 *
 * Request that the model have specific properties filled asynchronously
 * (will only request from server if values are not already available)
 * ```
 * var customer = new CustomerModel(id);
 * customer.fetchProperties(['username', 'status', 'organizations']).then(()=> {
 *   console.log(customer);
 * });
 * ```
 */
(function() {
	'use strict';

	angular.module('coreServices.Model', [])
		.constant('MODEL_PROPERTY_STATUS', {
			NOT_RETRIEVED: 'NOT_RETRIEVED',
			PENDING: 'PENDING', /* prevent making multiple requests for a property before the first request has been completed */
			ERROR_DONT_TRY_AGAIN: 'ERROR_DONT_TRY_AGAIN', /* because of an error that we can't recover from */
			RETRIEVED: 'RETRIEVED'
		})
		.factory('ModelFactory', ModelFactoryFactory)
		.factory('SingletonModel', SingletonModelFactory);

	function ModelFactoryFactory($rootScope, $q, MODEL_PROPERTY_STATUS) {
		return function ModelFactory(initFn) {
			function Model() {
				this._eventBus = $rootScope.$new();
				this._propertyMetadata = {};

				initFn.apply(this, arguments);
			}

			/* Property setters */
			Model.prototype.setProperty = function(value, property) {
				const dto = {};
				dto[property] = value;
				return this.setProperties(dto, [property]);
			};

			/* Copies the properties from the dto to the model, notifying of changes in the process.
			 * If a single property is set, then the DTO itself will be the value set at that property name. */
			Model.prototype.setProperties = function(dto, properties) {
				/* If we have a single property, the DTO will be the value to be set */
				if (!(properties instanceof Array)) {
					return this.setProperty(dto, properties);
				}

				properties.forEach((property)=> {
					this[property] = dto[property];
					// the js properties like verifiedIdentity don't have a _propertyMetadata.
					if (this._propertyMetadata[property]) {
						this._propertyMetadata[property].status = MODEL_PROPERTY_STATUS.RETRIEVED;
					}
				});

				/* Notify listeners that these properties have been changed */
				this.dispatchChangeEvent(properties);
			};

			Model.prototype.getPropertyNamesAsArray = function() {
				let propertiesArray = [];
				for (let propertyName in this._propertyMetadata) {
					if (!this._propertyMetadata.hasOwnProperty(propertyName)) {
						continue;
					}
					propertiesArray.push(propertyName);
				}
				return propertiesArray;
			};


			Model.prototype.isLoading = function() {
				return this.isAnyPropertyMetadateWithStatus(MODEL_PROPERTY_STATUS.PENDING);
			};

			Model.prototype.isError = function() {
				return this.isAnyPropertyMetadateWithStatus(MODEL_PROPERTY_STATUS.ERROR_DONT_TRY_AGAIN);
			};

			Model.prototype.isAnyPropertyMetadateWithStatus = function(modelPropertyStatus) {
				return Object.values(this._propertyMetadata).some(metadata => metadata.status === modelPropertyStatus);
			};


			const noopFetcher = function() {
				return $q.resolve();
			};

			/* Make model aware that some properties are being requested to prevent making multiple requests for a property
			 * before the first request has been completed */
			Model.prototype.markPropertiesAsPending = function(properties) {
				properties.forEach((property)=> {
					if (this._propertyMetadata[property].status !== MODEL_PROPERTY_STATUS.ERROR_DONT_TRY_AGAIN) {
						this._propertyMetadata[property].status = MODEL_PROPERTY_STATUS.PENDING;
					}
				});
			};

			/* Because of an error that we can't recover from */
			Model.prototype.markPropertiesAsError = function(properties) {
				properties.forEach((property)=> {
					this._propertyMetadata[property].status = MODEL_PROPERTY_STATUS.ERROR_DONT_TRY_AGAIN;
				});
			};
			
			Model.prototype.isPropertyStateAllowingToBeRetrieved = function(property) {
				const status = this._propertyMetadata[property].status;
				return status === MODEL_PROPERTY_STATUS.NOT_RETRIEVED || /* not retrieved yet at all */
					status === MODEL_PROPERTY_STATUS.RETRIEVED; /* refresh the value */
			};
			
			Model.prototype.markPropertiesAsNotRetrieved = function(properties) {
				let isAnythingToDo = false;
				properties.forEach((property)=> {
					if (this.isPropertyStateAllowingToBeRetrieved(property)) {
						this._propertyMetadata[property].status = MODEL_PROPERTY_STATUS.NOT_RETRIEVED;
						isAnythingToDo = true;
					}
				});
				return isAnythingToDo;
			};

			/* A fetcher retrieves properties from the backend and delivers them to the model.
			 * The return value of a fetcher is a promise that is resolved when the model has been updated.
			 * @see Model#setProperties for difference of behavior between a single property and an array of properties */
			Model.prototype.registerPropertiesFetcher = function(fetcherFn, properties) {
				fetcherFn = fetcherFn || noopFetcher;
				const propertiesArray = properties instanceof Array ? properties : [properties];
				const fetcher = ()=> {
					this.markPropertiesAsPending(propertiesArray);
					return fetcherFn()
						.then((response)=> this.setProperties(response.data, properties))
						.catch((error)=> {
							this.markPropertiesAsError(propertiesArray);
							return $q.reject(error);  // pass it along so the view can do its thing too.
						});
				};
				propertiesArray.forEach((property)=> {
					this._propertyMetadata[property] = {
						fetcher: fetcher,
						status: MODEL_PROPERTY_STATUS.NOT_RETRIEVED
					};
				});
			};

			/** Returns a promise that is resolved when all the properties passed as parameters have been loaded
			 * This method makes as few requests to the server as possible, including 0 if the requested properties
			 * are already available
			 */
			Model.prototype.fetchProperties = function(properties) {
				const invalidProperties = properties.filter((prop)=>!this._propertyMetadata[prop]);
				if (invalidProperties.length) {
					throw new Error('Request to fetch invalid properties: ' + invalidProperties.join(', '));
				}

				const shouldFetchProperty = (prop)=> this._propertyMetadata[prop].status === MODEL_PROPERTY_STATUS.NOT_RETRIEVED;
				const missingProperties = properties.filter(shouldFetchProperty);
				const fetchersForMissingProperties = missingProperties.map((prop)=>this._propertyMetadata[prop].fetcher);
				const uniqueFetchers = fetchersForMissingProperties.filter((fetcher, index, arr)=> {
					return arr.lastIndexOf(fetcher) === index;
				});
				
				
				this.dispatchPropertiesWillUpdate(missingProperties);

				const promises = uniqueFetchers.map((fetcher)=> fetcher.call(this));
				return $q.all(promises).then(()=> this);
			};


			// Can be called at anytime after the page was initialized to update some part of the page.
			Model.prototype.triggerPropertyUpdate = function(property) {
				if (!this.isPropertyStateAllowingToBeRetrieved(property)) {
					return $q.resolve();
				}
				this.markPropertiesAsNotRetrieved([property]);
				return this.fetchProperties([property]);
			};

			Model.prototype.triggerUpdateProperties = function(propertyNamesArray) {
				if (this.markPropertiesAsNotRetrieved(propertyNamesArray)) {
					return this.fetchProperties(propertyNamesArray);
				} else {
					return $q.resolve();
				}
			};

			Model.prototype.triggerUpdateAllProperties = function() {
				const propertyNamesArray = this.getPropertyNamesAsArray();
				this.markPropertiesAsNotRetrieved(propertyNamesArray);
				return this.fetchProperties(propertyNamesArray);
			};

			/* Event bus implementation */
			const EVENTS = {
				// Can be used to show the spinner while the values are retrieved from the server.
				PROPERTIES_WILL_UPDATE: 'propertiesWillUpdate',
				CHANGE: 'change'
			};

			// Can be used to show the spinner while the values are retrieved from the server.
			// propertiesWillUpdate is called before the properties are fetched.
			Model.prototype.dispatchPropertiesWillUpdate = function(properties) {
				this._eventBus.$broadcast(EVENTS.PROPERTIES_WILL_UPDATE, properties);
			};
			Model.prototype.onPropertiesWillUpdate = function(fn) {
				return this._eventBus.$on(EVENTS.PROPERTIES_WILL_UPDATE, fn);
			};
			
			// change is called after the properties are fetched.
			Model.prototype.dispatchChangeEvent = function(properties) {
				this._eventBus.$broadcast(EVENTS.CHANGE, properties);
			};
			Model.prototype.onChange = function(fn) {
				return this._eventBus.$on(EVENTS.CHANGE, fn);
			};

			return Model;
		};
	}

	function SingletonModelFactory(ModelFactory) {
		return function SingletonModel(initFn) {
			const Model = ModelFactory(initFn);

			// TODO: add protection against direct instantiation
			// Ensure the model is a singleton throughout the application
			let entity;
			Model.get = ()=> {
				return entity || (entity = new Model());
			};

			return Model;
		};
	}
}());
