/******************************************************************************************
 *    Customer Authentication Controller
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerAuthenticationController', [])
		.controller('CustomerAuthenticationController', CustomerAuthenticationController);

	function CustomerAuthenticationController($scope, $state, $stateParams, CustomerService, CustomerAccountAccessVm, CustomerGDPRVm, CustomerPasswordVm,
	                                          CustomerDetailsVm, CustomerEmailVm, CustomerTermsOfUseVm, CustomerLoginAsImpersonationVm, Modal, Toast,
	                                          VM_STATE, Wizard, User) {
		var customer = CustomerService.get($stateParams.id);
		this.customer = customer;
		this.hasImpersonationPermission = User.canImpersonateCustomer();
		// Email required for Enable/Disable modals
		const emailPromise = customer.fetchProperties(['email']);

		this.customerAccountAccess = new CustomerAccountAccessVm(customer);
		this.customerGDPR = new CustomerGDPRVm(customer);
		this.customerDetails = new CustomerDetailsVm(customer);
		this.customerPassword = new CustomerPasswordVm(customer);
		this.customerEmail = new CustomerEmailVm(customer);
		this.customerTermsOfUse = new CustomerTermsOfUseVm(customer);

		this.canCustomerBeImpersonated = this.customer.authSrcType !== 'ENTITLEMENT_ONLY_ACCOUNT';

		this.shouldEnableTheImpersonationFeature = this.hasImpersonationPermission && this.canCustomerBeImpersonated;
		
		if (this.shouldEnableTheImpersonationFeature) {
			this.customerLoginAsImpersonate = new CustomerLoginAsImpersonationVm(customer);
		}

		let emailReady = false;
		emailPromise.finally(()=> emailReady = true);

		Object.defineProperty(this, 'isLoading', {
			get: ()=> !emailReady ||
			          this.customerAccountAccess.state === VM_STATE.LOADING ||
			          this.customerGDPR.state === VM_STATE.LOADING ||
			          this.customerDetails.state === VM_STATE.LOADING ||
			          this.customerPassword.state === VM_STATE.LOADING ||
			          this.customerEmail.state === VM_STATE.LOADING ||
			          this.customerTermsOfUse.state === VM_STATE.LOADING ||
			          this.shouldEnableTheImpersonationFeature && this.customerLoginAsImpersonate.state === VM_STATE.LOADING
		});

		Object.defineProperty(this, 'changed', {
			get: ()=> this.customerTermsOfUse.changed
		});

		// Reset all fields to their last known server state
		this.discardChanges = ()=> {
			this.customerTermsOfUse.syncFromModel();
			Toast.showWarning('Terms of use changes discarded.');
		};

		// Update the state on the server
		this.saveChanges = (comment)=> {
			Modal.isLoading = true;
			const changes = this.customerTermsOfUse.getChanges();
			return customer.saveTermsOfUse(changes, comment)
				.then(()=> {
					this.customerTermsOfUse.syncFromModel();
					Toast.showSuccess('Customer terms of use successfully saved.');
				})
				.finally(()=> Modal.isLoading = false);
		};

		// Prompt the user to give a reason for the changes
		this.showSaveModal = (allowDiscard = false)=> {
			var changes = this.customerTermsOfUse.getChanges();
			
			if (angular.isArray(changes)) {
				changes.forEach(change => {
					if (angular.isObject(changes) && angular.isObject(change.original) && change.property === 'termsOfUse') {
						// we show only the revision field that changed, not the entire `termsOfUse` object
						change.original = change.original.revision;
						change.changed = change.changed.revision;
					}
				});
			}
			
			return Modal.open({
				title: 'Save customer terms of use changes',
				templateUrl: 'customer-save-modal.html',
				locals: {changes, allowDiscard, save: this.saveChanges, discard: this.discardChanges}
			});
		};

		this.showDiscardModal = ()=> {
			return Modal.open({
				title: 'Discard customer terms of use changes',
				templateUrl: 'customer-discard-modal.html',
				locals: {discard: this.discardChanges}
			});
		};

		// Prompt the user to save changes when navigating away from the page
		$scope.$on('$stateChangeStart', (evt, toState, toParams)=> {
			const resumeNavigation = ()=> {
				$state.go(toState, toParams);
			};

			const changes = this.customerTermsOfUse.getChanges() || [];
			if (changes.length) {
				evt.preventDefault();
				if ($scope.customerAuthenticationForm.$valid) {
					// By the time the modal closing promise resolves, the VM will be in sync with the updated model
					// avoiding to prompt again when navigation is resumed
					this.showSaveModal(true).then(resumeNavigation);
				} else {
					// Invalid forms cannot be saved, but it's still nice to let the user know he made changes
					this.showDiscardModal().then(resumeNavigation);
				}
			}
		});
		
		const disableWithGDPR = (comment, isPhotoDna)=> {
			Modal.isLoading = true;
			return customer.disableWithGDPR(isPhotoDna, comment)
				.then(()=> {
					customer.triggerGDPRPropertiesUpdate();
					Toast.showSuccess('Customer successfully disabled.');
				})
				.finally(()=> Modal.isLoading = false);
		};
		this.showDisableWithGDPRModal = ()=> {
			Modal.open({
				title: 'Disable account access with GDPR',
				templateUrl: 'customer-disable-modal.html',
				/* create VM object in modal scope to avoid implicitly creating it in ng-if scope */
				controller: angular.noop,
				controllerAs: 'vm',
				locals: {
					description: 'The account will be deleted in 60 days because of the GDPR privacy laws.',
					accountId: customer.accountId,
					email: customer.email,
					canDisableWithPdna: this.customerAccountAccess.canDisableWithPdna,
					reference: { // angular passes it by reference and the changes inside it are seen in both places (here and in the template).
						comment: '',
						isPhotoDna: false
					},
					disable: disableWithGDPR
				}
			});
		};

		const enableWithGDPR = (comment)=> {
			Modal.isLoading = true;
			return customer.enableWithGDPR(comment)
				.then(()=> {
					customer.triggerGDPRPropertiesUpdate();
					Toast.showSuccess('Customer successfully enabled.');
				})
				.finally(()=> Modal.isLoading = false);
		};
		this.showEnableWithGDPRModal = ()=> {
			Modal.open({
				title: 'Enable account access with GDPR',
				templateUrl: 'customer-enable-modal.html',
				locals: {
					description: 'The Active Delete GDPR Request will be Cancelled.',
					accountId: customer.accountId,
					email: customer.email,
					enable: enableWithGDPR
				}
			});
		};


		const disable = (comment, isPhotoDna)=> {
			Modal.isLoading = true;
			return customer.disable(isPhotoDna, comment)
				.then(()=> {
					this.customerAccountAccess.syncFromModel();
					Toast.showSuccess('Customer successfully disabled.');
				})
				.finally(()=> Modal.isLoading = false);
		};
		this.showDisableModal = ()=> {
			Modal.open({
				title: 'Set Status to Disabled',
				templateUrl: 'customer-disable-modal.html',
				/* create VM object in modal scope to avoid implicitly creating it in ng-if scope */
				controller: angular.noop,
				controllerAs: 'vm',
				locals: {
					description: 'The account will NOT be deleted in 60 days.',
					accountId: customer.accountId,
					email: customer.email,
					canDisableWithPdna: this.customerAccountAccess.canDisableWithPdna,
					reference: { // angular passes it by reference and the changes inside it are seen in both places (here and in the template).
						comment: '',
						isPhotoDna: false
					},
					disable
				}
			});
		};
		
		this.showPhotoDnaDisableModal = ()=> {
			Modal.open({
				title: 'Disable because of PhotoDna',
				templateUrl: 'customer-disable-modal.html',
				/* create VM object in modal scope to avoid implicitly creating it in ng-if scope */
				controller: angular.noop,
				controllerAs: 'vm',
				locals: {
					description: 'The account will be deleted in 60 days.',
					accountId: customer.accountId,
					email: customer.email,
					canDisableWithPdna: true,
					reference: { // angular passes it by reference and the changes inside it are seen in both places (here and in the template).
						comment: 'NPDNA',
						isPhotoDna: true  // starts as checked
					},
					disable: disableWithGDPR
				}
			});
		};


		const enable = (comment)=> {
			Modal.isLoading = true;
			return customer.enable(comment)
				.then(()=> {
					this.customerAccountAccess.syncFromModel();
					Toast.showSuccess('Customer successfully enabled.');
				})
				.finally(()=> Modal.isLoading = false);
		};
		this.showEnableModal = ()=> {
			Modal.open({
				title: 'Set Status to Active',
				templateUrl: 'customer-enable-modal.html',
				locals: {
					description: 'The account will be enabled in Renga.',
					accountId: customer.accountId,
					email: customer.email,
					enable
				}
			});
		};
		
		this.showCancelPhotoDnaModal = ()=> {
			Modal.open({
				title: 'Cancel PhotoDna',
				templateUrl: 'customer-enable-modal.html',
				locals: {
					description: 'The account will be enabled in Renga and the PhotoDna flag will be removed.',
					accountId: customer.accountId,
					email: customer.email,
					enable: enableWithGDPR
				}
			});
		};
		
		const setPassword = (password, comment)=> {
			Modal.isLoading = true;
			return customer.setPassword(password, comment)
				.then(() => {
					Toast.showSuccess('Customer password successfully set.');
					Modal.isLoading = false;
				})
				.catch(()=> Toast.showError('Customer password not set.'));
		};

		this.showSetPasswordModal = ()=> {
			return Modal.open({
				title: 'Set password',
				templateUrl: 'customer-set-password-modal.html',
				controller: function() { this.password = ''; },
				controllerAs: 'vm',
				locals: {
					accountId: customer.accountId,
					email: customer.email,
					minLength: 6,
					maxLength: 12,
					setPassword
				}
			});
		};


		const verifyEmail = (comment)=> {
			Modal.isLoading = true;
			return customer.verifyEmail(comment)
				.then(()=> {
					this.customerEmail.syncFromModel();
					Toast.showSuccess('Customer verification email successfully sent.');
				})
				.finally(()=> Modal.isLoading = false);
		};

		this.showVerifyEmailModal = ()=> {
			Modal.open({
				title: 'Send verification email',
				templateUrl: 'customer-verify-email-modal.html',
				locals: {accountId: customer.accountId, email: customer.email, verifyEmail}
			});
		};


		const generateReverseVerificationCode = (email, comment)=> {
			Modal.isLoading = true;
			return customer.generateReverseVerificationCode(email, comment)
				.finally(()=> Modal.isLoading = false);
		};

		const cancelReverseVerification = (email, comment)=> {
			Modal.isLoading = true;
			return customer.cancelReverseVerification(email, comment)
				.finally(()=> Modal.isLoading = false);
		};

		this.showReverseVerifyEmailModal = (email, step)=> {
			Wizard.open({
				title: 'Reverse email verification',
				steps: [
					{label: 'Generate code', templateUrl: 'customer-reverse-verification-wizard-generate.html'},
					{label: 'Inform customer', templateUrl: 'customer-reverse-verification-wizard-deliver.html'},
					{label: 'Verify', templateUrl: 'customer-reverse-verification-wizard-verify.html'}
				],
				locals: {
					accountId: customer.accountId,
					email,
					generateReverseVerificationCode,
					getInfo: ()=> customer.reverseVerification && customer.reverseVerification[email]
				},
				startingStep: step || 0
			});
		};
		
		this.getReverseEmailVerificationInfo = (email)=> customer.reverseVerification && customer.reverseVerification[email];

		this.showCancelReverseVerifyEmailModal = (email) => {
			// The email can be either the primary or the alternate address.
			Modal.open({
				title: 'Cancel reverse verification?',
				templateUrl: 'customer-reverse-verification-cancel-modal.html',
				locals: {
					accountId: customer.accountId,
					email,
					cancelReverseVerification
				}
			});
		};

		const forceVerifyEmail = (comment)=> {
			Modal.isLoading = true;
			return customer.forceVerifyEmail(comment)
				.then(()=> {
					Toast.showSuccess('Customer email successfully verified.');
				})
				.finally(()=> Modal.isLoading = false);
		};

		this.showForceVerifyEmailModal = () => {
			// Only the primary email can be force-verified
			Modal.open({
				title: 'Force email verification?',
				templateUrl: 'customer-force-verify-email-modal.html',
				locals: {email: customer.email, forceVerifyEmail}
			});
		};

		const deleteCustomer = (comment)=> {
			return customer.delete(comment)
				.then(()=> {
					Toast.showSuccess('Customer successfully deleted.');
					CustomerService.clearCache();
					$state.go('customerSearch');
				});
		};
		this.showDeleteModal = ()=> {
			Modal.open({
				title: 'Delete customer',
				templateUrl: 'customer-delete-modal.html',
				locals: {accountId: customer.accountId, email: customer.email, delete: deleteCustomer}
			});
		};

		this.getImpersonationSessions = () => {
			customer.getImpersonationSessions().then(()=> {
				this.customerLoginAsImpersonate.syncFromModel();
			});
		};

		this.resendImpersonationEmail = (sessionId) => {
			customer.resendImpersonationEmail(sessionId).then(()=> {
				Toast.showSuccess('Email sent successfully');
			},()=> {
				Toast.showError('An error occurred, please try again!');
			});
		};

		const createImpersonationSession = (caseNumber)=> {
			Modal.isLoading = true;
			return customer.createImpersonationSession(caseNumber).then(()=> {
				this.customerLoginAsImpersonate.syncFromModel();
				Toast.showSuccess('Session is created successfully.');
			})
			.finally(()=> Modal.isLoading = false);
		};

		this.showCreateImpersonationSessionModal = ()=> {
			return Modal.open({
				title: 'Initiate request to login as user',
				templateUrl: 'create-impersonation-session.html',
				locals: {
					impersonateeEmail: customer.email,
					createImpersonationSession
				}
			});
		};
		
	}
}());
