/******************************************************************************************
 *    Agent Search View Model
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('agents.AgentSearchVm', [])
		.factory('AgentSearchVm', AgentSearchVmFactory);

	function AgentSearchVmFactory(ViewModelFactory) {
		const init = function(agent) {
			this.accountId = agent.accountId;
			this.username = agent.username || '[N/A]';
			this.status = agent.enabled ? 'ACTIVE' : 'DISABLED';
			this.created = agent.createdDate || '[N/A]';
			this.expires = agent.expireDate || '[N/A]';
			this.lastLogin = agent.loginDate || '[N/A]';
		};

		const requiredModelProperties = ['accountId', 'username', 'enabled',
			'createdDate', 'expireDate', 'loginDate'];

		return ViewModelFactory(init, requiredModelProperties);
	}

}());
