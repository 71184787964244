/******************************************************************************************
 *    IMS Lib Wrapper Service
 *******************************************************************************************
 *
 * User authentication via IMS.
 *
 * Provides an interface for IMS JS Lib.
 * Library documentation is here: https://git.corp.adobe.com/pages/IMS/imslib.js/.
 *
 */
(function() {
	'use strict';

	angular.module('auth.ImsAuth', []).service('ImsAuth', ImsAuth);

	function ImsAuth($q, IMS_JS_LIB_URL, DomUtility) {
		//Initializes IMS JS Lib and returns a promise that fulfils with the user token and user profile
		//or rejects if user is logged out or something failed along the way.
		this.init = (function() {
			let deferred;

			return function wrappedInit() {
				//Init should only happen once.
				//If deferred was previously set, skip the init logic and just return the same promise.
				if (!deferred) {
					console.log('%cIMS JS Lib init...', 'color:#CCCCCC;');
					deferred = $q.defer();

					//IMS Lib reads the config from the `window.adobeid` object
					//which needs to exist before loading the library.
					window.adobeid = {
						//jscs:disable requireCamelCaseOrUpperCaseIdentifiers
						client_id: 'ABO2-UI',
						scope: 'AdobeID,openid,read_countries_regions',
						uses_redirect_mode: true,
						is_mandatory_sign_in: false,//Sign-in is called programatically instead of automatically
						//jscs:enable requireCamelCaseOrUpperCaseIdentifiers
						onReady: function() {
							console.log('%cIMS JS Lib ready.', 'color:#CCCCCC;');
							deferred.resolve();
						},
						onError: function() {
							console.log('%cIMS JS Lib failed.', 'color:#CC0000;');
							deferred.reject();
						}
					};

					//Load the lib
					const imsLibLoadError = function() {
						console.log('%cIMS JS Lib script load failed.', 'color:#CC0000;');
						deferred.reject();
					};
					DomUtility.asyncLoadScript(IMS_JS_LIB_URL, null, imsLibLoadError);
				}

				return deferred.promise;
			};
		})();

		//Check if there is an active IMS session.
		this.getLoggedInUser = function() {
			let deferred = $q.defer();

			if (window.adobeIMS && window.adobeIMS.isSignedInUser && window.adobeIMS.isSignedInUser()) {
				const token = window.adobeIMS.getAccessToken();
				if (!token || !token.token) {
					console.log('%cImsAuth :: Bad session data. Token is empty', 'font-weight:bold;color:#CC0000;');
					deferred.reject();
					return deferred.promise;
				}
				
				const profilePromise = window.adobeIMS.getProfile();
				profilePromise.then(function(profile) {
					if (!profile || !profile.userId) {
						console.log('%cImsAuth :: Bad session data. %O', 'font-weight:bold;color:#CC0000;', {profile});
						deferred.reject();
						return;
					}
					console.log('%cImsAuth :: User logged in. %O', 'font-weight:bold;color:#009B77;', {token, profile});
					deferred.resolve({
						accessToken: token.token,
						userId: profile.userId,
						//jscs:disable requireCamelCaseOrUpperCaseIdentifiers
						accountType: profile.account_type,
						firstName: profile.first_name,
						lastName: profile.last_name,
						//jscs:enable requireCamelCaseOrUpperCaseIdentifiers
						email: profile.email
					});
				}).catch(function(error) {
					console.log(error);
					deferred.reject();
				});
			} else {
				console.log('%cImsAuth :: User not logged in.', 'font-weight:bold;color:#CC0000;');
				deferred.reject();
			}

			return deferred.promise;
		};

		//Triggers the signIn() method of the IMS JS Lib.
		this.triggerSignInFlow = function(apiParams) {
			console.log('%cImsAuth :: Triggering Sign-In Flow...', 'font-weight:bold;color:#009B77;');
			window.adobeIMS.signIn(apiParams);

			//Play nice with promise chains:
			//Keep promise sequence pending until redirect occurs.
			return $q.defer().promise;
		};

		//Triggers the signOut() method of the IMS JS Lib.
		this.triggerSignOutFlow = function() {
			console.log('%cImsAuth :: Triggering Sign-In Flow...', 'font-weight:bold;color:#009B77;');
			window.adobeIMS.signOut();

			//Play nice with promise chains:
			//Keep promise sequence pending until redirect occurs.
			return $q.defer().promise;
		};
	}
})();
