/******************************************************************************************
 *    Customer Creation Details View Model
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerCreationDetailsVm', [])
		.factory('CustomerCreationDetailsVm', CustomerCreationDetailsVmFactory);

	function CustomerCreationDetailsVmFactory(ViewModelFactory) {
		const init = function(customer) {
			const rawCreationDetails = customer.creationDetails;
			this.cad = rawCreationDetails.originClientAppDescriptor || '[N/A]';
			this.clientIp = rawCreationDetails.clientIp || '[N/A]';
			this.userIp = rawCreationDetails.userIp || '[N/A]';
			this.guidIp = rawCreationDetails.guidIp || '[N/A]';
			this.guidSequence = rawCreationDetails.guidSequence || '[N/A]';
			this.guidProcId = rawCreationDetails.guidProcId || '[N/A]';
			this.guidDate = rawCreationDetails.guidDate || '[N/A]';
		};

		const requiredModelProperties = ['creationDetails'];

		return ViewModelFactory(init, requiredModelProperties);
	}
}());
