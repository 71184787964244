/******************************************************************************************
 *    ABO API Service
 *******************************************************************************************
 *
 * Used to talk to ABO backend
 *
 */
(function() {
	'use strict';

	angular.module('core.AboApi', []).service('AboApi', AboApi);

	function AboApi(ABO_API_URL, $http, $q, IMS_URL, User) {
		this.authorize = function() {
			return $http.get(ABO_API_URL + `/authorization`, {cache: false});
		};

		this.forcePasswordReset = function(accountId, comment, sendEmailNotification) {
			return $http.post(ABO_API_URL + '/customers/force_password_reset', {accountId, comment, sendEmailNotification});
		};
		
		this.createImpersonationSession = function(impersonatee, caseNumber) {
			const requester = User.getId();
			return $http.post(ABO_API_URL + `/impersonation/create_session`, {impersonatee, requester, caseNumber});
		};
		
		this.getImpersonationSessions = function(impersonatee) {
			const encodedRequester = window.encodeURIComponent(User.getId());
			const encodedImpersonatee = window.encodeURIComponent(impersonatee);
			return $http.get(ABO_API_URL + `/impersonation/sessions?impersonatee=${encodedImpersonatee}&requester=${encodedRequester}`);
		};
		
		this.resendImpersonationEmail = function(sessionId) {
			const encodedSessionId = window.encodeURIComponent(sessionId);
			return $http.put(ABO_API_URL + `/impersonation/${encodedSessionId}/resend_email`);
		};
		
		this.findCustomers = function(query, timeout) {
			const encodedQuery = window.encodeURIComponent(query);
			return $http.get(ABO_API_URL + `/customers?query=${encodedQuery}`, {timeout});
		};

		// Unused for now because backend doesn't have it
		this.getCustomer = function(id, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.get(ABO_API_URL + `/customers/${encodedId}`, {timeout});
		};

		this.getCustomerDetails = function(id, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.get(ABO_API_URL + `/customers/${encodedId}/details`, {timeout}).then((response)=> {
				if (response.data.accountId !== id) {
					//TODO : this is an erroneous state, even though the server considered it to be correct and a 200 OK
					//The interface currently doesn't handle this case of rejected promises.
					return $q.reject('Received a different accountId than the one requested from the server!');
				}
				return response;
			});
		};

		this.getCustomerAddresses = function(id, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.get(ABO_API_URL + `/customers/${encodedId}/addresses`, {timeout});
		};

		this.getCustomerOrganizations = function(id, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.get(ABO_API_URL + `/customers/${encodedId}/organizations`, {timeout});
		};

		this.getCustomerOrganizationRelationshipsOREOs = function(id, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.get(ABO_API_URL + `/customers/${encodedId}/organization_relationships`, {timeout});
		};

		this.getCustomerRoles = function(id, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.get(ABO_API_URL + `/customers/${encodedId}/roles`, {timeout});
		};

		this.getCustomerCreationDetails = function(id, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.get(ABO_API_URL + `/customers/${encodedId}/creation_details`, {timeout});
		};

		this.getCustomerLoginHistory = function(id, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.get(ABO_API_URL + `/customers/${encodedId}/login_history`, {timeout});
		};

		this.saveCustomerDetails = function(id, customer, comment, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.put(ABO_API_URL + `/customers/${encodedId}/details`, {customer, comment}, {timeout});
		};

		this.getCustomerAccountAccessStatus = function(id, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.get(ABO_API_URL + `/customers/${encodedId}/account_access`, {timeout});
		};

		this.getCustomerPasswordStatus = function(id, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.get(ABO_API_URL + `/customers/${encodedId}/password`, {timeout});
		};

		this.getCustomerEmailStatus = function(id, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.get(ABO_API_URL + `/customers/${encodedId}/email_verification`, {timeout});
		};

		this.getCustomerTermsOfUse = function(id, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.get(ABO_API_URL + `/customers/${encodedId}/terms_of_use`, {timeout});
		};

		this.saveCustomerTermsOfUse = function(id, termsOfUse, comment, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.post(ABO_API_URL + `/customers/${encodedId}/terms_of_use`, {termsOfUse, comment}, {timeout});
		};

		this.disableCustomerWithGDPR = function(id, photodna, comment, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.post(ABO_API_URL + `/customers/${encodedId}/disable_gdpr`, {photodna, comment}, {timeout});
		};

		this.enableCustomerWithGDPR = function(id, comment, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.post(ABO_API_URL + `/customers/${encodedId}/enable_gdpr`, {comment}, {timeout});
		};

		this.disableCustomer = function(id, photodna, comment, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.post(ABO_API_URL + `/customers/${encodedId}/disable`, {photodna, comment}, {timeout});
		};

		this.enableCustomer = function(id, comment, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.post(ABO_API_URL + `/customers/${encodedId}/enable`, {comment}, {timeout});
		};

		this.sendCustomerPhoneChallenge = function(id, comment, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.post(ABO_API_URL + `/customers/${encodedId}/phone_verification`, {comment}, {timeout});
		};

		this.getCountries = function(locale, timeout) {
			locale = window.encodeURIComponent(locale || 'en_US'); // jscs:disable requireCamelCaseOrUpperCaseIdentifiers
			const clientId = window.encodeURIComponent(window.adobeid.client_id);
			const token = window.encodeURIComponent(window.adobeIMS.getAccessToken());
			return $http.get(IMS_URL + `/countries/v1?client_id=${clientId}&bearer_token=${token}&locale=${locale}`, {timeout});
		};

		this.verifyCustomerPhoneChallenge = function(id, challenge, code, timeout) {
			const encodedId = window.encodeURIComponent(id);
			const encodedChallenge = window.encodeURIComponent(challenge);
			return $http.post(ABO_API_URL + `/customers/${encodedId}/phone_verification/${encodedChallenge}`,
				{code}, {timeout, silent: true});
		};

		this.fixCustomerCredentials = function(id, username, comment, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.post(ABO_API_URL + `/customers/${encodedId}/fix_credentials`, {username, comment}, {timeout});
		};

		this.setPassword = function(id, password, comment, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.post(ABO_API_URL + `/customers/${encodedId}/password/`, {password, comment}, {timeout});
		};
		
		this.clearDob = function(id, comment, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.post(ABO_API_URL + `/customers/${encodedId}/clear_dob/`, {comment}, {timeout});
		};

		this.unlinkSocialProvider = function(id, provider, comment, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.delete(ABO_API_URL + `/customers/${encodedId}/unlink_social_provider/`,
				{
					data: {
						provider,
						comment
					},
					timeout
				});
		};

		this.verifyCustomerEmail = function(id, comment, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.post(ABO_API_URL + `/customers/${encodedId}/email_verification/send`, {comment}, {timeout});
		};

		this.generateReverseEmailVerificationCode = function(id, email, comment, timeout) {
			const encodedId = window.encodeURIComponent(id);
			const encodedEmail = window.encodeURIComponent(email);
			return $http.post(ABO_API_URL + `/customers/${encodedId}/reverse_verification/${encodedEmail}`, {comment}, {timeout});
		};

		this.getReverseEmailVerification = function(id, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.get(ABO_API_URL + `/customers/${encodedId}/reverse_verification`, {timeout});
		};

		this.cancelReverseEmailVerification = function(id, email, comment, timeout) {
			const encodedId = window.encodeURIComponent(id);
			const encodedEmail = window.encodeURIComponent(email);
			const data = {comment};
			return $http.delete(ABO_API_URL + `/customers/${encodedId}/reverse_verification/${encodedEmail}`, {data, timeout});
		};

		this.forceVerifyCustomerEmail = function(id, comment, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.post(ABO_API_URL + `/customers/${encodedId}/email_verification/force_verify`, {comment}, {timeout});
		};

		this.getEntitlementsMetadata = function(timeout) {
			return $http.get(ABO_API_URL + '/metadata/entitlements', {timeout});
		};

		this.getCustomerEntitlements = function(id, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.get(ABO_API_URL + `/customers/${encodedId}/entitlements`, {timeout});
		};

		this.addCustomerEntitlement = function(id, code, comment, timeout) {
			const encodedId = window.encodeURIComponent(id);
			const encodedCode = window.encodeURIComponent(code);
			return $http.post(ABO_API_URL + `/customers/${encodedId}/entitlements/${encodedCode}`,
				{comment}, {timeout});
		};

		this.updateCustomerEntitlementStatus = function(id, code, serviceId, status, comment, timeout) {
			const encodedId = window.encodeURIComponent(id);
			const encodedCode = window.encodeURIComponent(code);
			const encodedServiceId = window.encodeURIComponent(serviceId);
			return $http.put(ABO_API_URL + `/customers/${encodedId}/entitlements/${encodedCode}/` +
			                 `${encodedServiceId}/status`, {status, comment}, {timeout});
		};

		this.grantCustomerEntitlementVip = function(id, code, serviceId, comment, timeout) {
			const encodedId = window.encodeURIComponent(id);
			const encodedCode = window.encodeURIComponent(code);
			const encodedServiceId = window.encodeURIComponent(serviceId);
			return $http.post(ABO_API_URL + `/customers/${encodedId}/entitlements/${encodedCode}/` +
			                  `${encodedServiceId}/grant_vip`, {comment}, {timeout});
		};

		this.revokeCustomerEntitlementVip = function(id, code, serviceId, comment, timeout) {
			const encodedId = window.encodeURIComponent(id);
			const encodedCode = window.encodeURIComponent(code);
			const encodedServiceId = window.encodeURIComponent(serviceId);
			return $http.post(ABO_API_URL + `/customers/${encodedId}/entitlements/${encodedCode}/` +
			                  `${encodedServiceId}/revoke_vip`, {comment}, {timeout});
		};

		this.saveCustomerEntitlementData = function(id, code, serviceId, parameters, comment, timeout) {
			const encodedId = window.encodeURIComponent(id);
			const encodedCode = window.encodeURIComponent(code);
			const encodedServiceId = window.encodeURIComponent(serviceId);
			return $http.put(ABO_API_URL + `/customers/${encodedId}/entitlements/${encodedCode}/` +
			                 `${encodedServiceId}/parameters`, {parameters, comment}, {timeout});
		};

		this.getCustomerProductContexts = function(id, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.get(ABO_API_URL + `/customers/${encodedId}/projected_product_contexts`, {timeout});
		};

		this.getCustomerGDPRRequests = function(id, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.get(ABO_API_URL + `/customers/${encodedId}/gdpr_requests/`, {timeout})
				.catch((response) => {
					if (response.data && response.data.error === 'forbidden' && response.data.errorDescription && response.data.errorDescription.startsWith &&
						response.data.errorDescription.startsWith('Access Denied because of the Environment Configura')) {
						// ignore, the GDPR feature is disabled.
						return {};
					} else {
						return response;
					}
				});
		};

		this.createCustomerGDPRRequest = function(id, type, comment, timeout) {
			const encodedId = window.encodeURIComponent(id);
			if (type === 'GDPR_DELETE' || type === 'GDPR_ACCESS' || type === 'AGE_OUT_DELETE') {
				const api = type.toLowerCase();
				const typeForApi = type;
				return $http.post(ABO_API_URL + `/customers/${encodedId}/create_${api}_gdpr_request/`, {type: typeForApi, comment}, {timeout});
			} else {
				throw new Error('Unknown gdpr request type ' + type);
			}
		};

		this.setGDPRRequestStatusToPending = function(customerAccountId, requestId, timeout) {
			const encodedId = window.encodeURIComponent(customerAccountId);
			return $http.post(ABO_API_URL + `/customers/${encodedId}/update_gdpr_request_to_pending/`, {requestId}, {timeout});
		};

		this.cancelCustomerGDPRRequest = function(customerAccountId, requestId, comment, timeout) {
			const encodedId = window.encodeURIComponent(customerAccountId);
			return $http.delete(ABO_API_URL + `/customers/${encodedId}/gdpr_request/${requestId}`, {
				data: {
					comment
				},
				timeout
			});
		};

		this.getCustomerAccessGDPRReport = function(requestId, timeout) {
			const encodedId = window.encodeURIComponent(requestId);
			return $http.get(ABO_API_URL + `/customers/${encodedId}/access_gdpr_report/`, {timeout});
		};

		this.deleteCustomer = function(id, comment, timeout) {
			const encodedId = window.encodeURIComponent(id);
			const data = {comment};
			return $http.delete(ABO_API_URL + `/customers/${encodedId}`, {data, timeout});
		};


		this.findLogs = function(query, field, action, since, until, timeout) {
			const encodedQuery = window.encodeURIComponent(query);
			const params = {
				action: action && window.encodeURIComponent(action),
				since: since && window.encodeURIComponent(since),
				until: until && window.encodeURIComponent(until)
			};
			switch (field) {
				case 'Customer':
					params.customer = encodedQuery;
					break;
				case 'Agent':
					params.agent = encodedQuery;
					break;
				case 'Comment':
					params.comment = encodedQuery;
					break;
				default:
					params.freeSearch = encodedQuery;
					break;
			}
			const paramString = Object.keys(params).filter((key)=> !!params[key])
				.map((key)=> `${key}=${params[key]}`).join('&');
			return $http.get(ABO_API_URL + `/logs?${paramString}`, {timeout});
		};

		this.getAuditActions = function(timeout) {
			return $http.get(ABO_API_URL + `/metadata/audit_actions`, {timeout});
		};

		this.getEnvironmentMetadata = function(timeout) {
			return $http.get(ABO_API_URL + '/metadata/environment', {timeout});
		};

		this.sendFeedback = function(comment) {
			return $http.post(ABO_API_URL + '/feedback', {comment});
		};

		this.findAgents = function(query, timeout) {
			const encodedQuery = window.encodeURIComponent(query);
			return $http.get(ABO_API_URL + `/agents?query=${encodedQuery}`, {timeout});
		};

		this.getAgent = function(id, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.get(ABO_API_URL + `/agents/${encodedId}`, {timeout});
		};

		this.createAgent = function(username, manager, permissions, expireDate, comment, timeout) {
			return $http.post(ABO_API_URL + `/agents`, {username, manager, expireDate, permissions, comment}, {timeout});
		};

		this.disableAgent = function(id, comment, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.post(ABO_API_URL + `/agents/${encodedId}/disable`, {comment}, {timeout});
		};

		this.enableAgent = function(id, comment, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.post(ABO_API_URL + `/agents/${encodedId}/enable`, {comment}, {timeout});
		};

		this.updateAgentPermissions = function(id, permissions, comment, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.put(ABO_API_URL + `/agents/${encodedId}/permissions`, {permissions, comment}, {timeout});
		};

		this.updateAgentExpireDate = function(id, expireDate, comment, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.put(ABO_API_URL + `/agents/${encodedId}/expire_date`, {expireDate, comment}, {timeout});
		};

		this.getPermissionsMetadata = function(timeout) {
			return $http.get(ABO_API_URL + '/metadata/permissions', {timeout});
		};

		this.getRolesMetadata = function(timeout) {
			return $http.get(ABO_API_URL + '/metadata/roles', {timeout});
		};

		this.getCustomerSessions = function(id, timeout) {
			const encodedId = window.encodeURIComponent(id);
			return $http.get(ABO_API_URL + `/customers/${encodedId}/sessions`, {timeout});
		};
	}
}());
