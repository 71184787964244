/******************************************************************************************
 *    Agent Account Access View Model
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('agents.AgentAccountAccessVm', [])
		.factory('AgentAccountAccessVm', AgentAccountAccessVmFactory);

	function AgentAccountAccessVmFactory(ViewModelFactory, AGENT_STATUS, User) {
		const init = function(agent, permissionsModel) {
			this.status = agent.enabled ? AGENT_STATUS.ACTIVE : AGENT_STATUS.DISABLED;
			
			const permissionTypes = {};
			(permissionsModel.permissions || []).forEach((permission)=> {
				permissionTypes[permission.permission] = permission.type;
			});
			const isUneditablePermission = (permission)=> {
				// Treat unknown permissions as ABO
				const type = permissionTypes[permission] || 'ABO';
				return !User.canEditPermissions(type);
			};
			const hasUneditableRights = (agent.permissions || []).some(isUneditablePermission);
			this.hasStatusButton = User.canEditAgent(agent.accountId) && !hasUneditableRights;

			this.expireDate = agent.expireDate || '[N/A]';
			this.expireDatePattern = /^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/;
			this.hasExpireButton = User.canExpireAgent(agent.accountId);
		};

		return ViewModelFactory(init, ['enabled', 'permissions', 'expireDate'], ['permissions']);
	}
}());
