/******************************************************************************************
 *    Customer Search Route
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerSearchRoute', [])
		.config(CustomerSearchRouteConfig);

	function CustomerSearchRouteConfig($stateProvider, PERMISSIONS) {
		$stateProvider
			.state('customerSearch', {
				url: '/customers/search?query',
				templateUrl: 'customer-search.html',
				controller: 'CustomerSearchController',
				controllerAs: 'vm',
				data: {
					access: PERMISSIONS.VIEW_CUSTOMER
				}
			});
	}
}());
