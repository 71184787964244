/******************************************************************************************
 *    Audit Constants
 ******************************************************************************************/
(function() {
	'use strict';


	angular.module('audits.Constants', [])
		// Dates
		.constant('AUDIT_DATES', {
			ALL: 'All time',
			DAY: 'Today',
			WEEK: 'Last 7 days',
			MONTH: 'Last month',
			YEAR: 'Last year'
		})
		.run(($rootScope, AUDIT_DATES)=> $rootScope.AUDIT_DATES = AUDIT_DATES)
		// Fields
		.constant('AUDIT_FIELDS', {
			ALL: 'Any field',
			CUSTOMER: 'Customer',
			AGENT: 'Agent',
			COMMENT: 'Comment'
		})
		.run(($rootScope, AUDIT_FIELDS)=> $rootScope.AUDIT_FIELDS = AUDIT_FIELDS);
}());
