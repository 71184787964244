/******************************************************************************************
*	Takeover Message Directive
*******************************************************************************************
*
* Takeover Messages are messages that cover the entire application visually.
* They are used when an unrecoverable error occurs (e.g. auth library load failed)
* or something is found to be uncompliant (e.g. user access denied).
* Takeover Messages are made up of title, message and optionally action button.
* A TakeoverMessage method will always return a never-resolving promise in order to block
* promise chains from continuing. The best example of this is in the app's run-block.
*/
(function() {
	'use strict';

	angular.module('coreDirectives.TakeoverMessage', [])
		.controller('TakeoverMessageController', TakeoverMessageController)
		.directive('aboTakeoverMessage', TakeoverMessageDirective)
		.service('TakeoverMessage', TakeoverMessageService);


	/*=== Controller ===*/
	function TakeoverMessageController(TakeoverMessage) {
		//Expose TakeoverMessage service to view.
		this.TakeoverMessage = TakeoverMessage;
	}


	/*=== Directive ===*/
	function TakeoverMessageDirective() {
		return {
			restrict: 'A',
			templateUrl: 'takeover-message.html',
			scope: {},
			controller: 'TakeoverMessageController',
			controllerAs: 'vm',
			bindToController: true
		};
	}


	/*=== Service ===*/
	function TakeoverMessageService($rootScope, $q, $document, ImsAuth) {
		//Visibility control var
		let isVisible = false;
		this.isVisible = function() {
			return isVisible;
		};

		//Show Takeover Message using the specified `config`.
		//This method will return a never-resolving promise which blocks promise chains.
		this.show = function(config) {
			//Minimum requirements
			if (config && config.title && config.message) {
				//$evalAsync ensures watchers are notified of changes regardless if we are in the middle of a digest
				// cycle or outside of it. This avoids having to check if `$$phase` is falsey and then call `$apply`
				// manually.
				$rootScope.$evalAsync(()=> {
					this.config = {};
					this.config.title = config.title;
					this.config.message = config.message;
					this.config.iconSrc = config.iconSrc;
					this.config.actionLabel = config.actionLabel;
					this.config.actionFn = typeof config.actionFn === 'function' ? config.actionFn : null;

					ImsAuth.getLoggedInUser().then((user) => {
						this.config.email = user ? user.email : 'email';
						this.config.accountType = user ? user.accountType : 'accountType';
					});

					isVisible = true;
				});
			} else {
				//If minimum configuration requirements aren't met, display a console error.
				//Don't throw or reject the promise since that will break the promise chain.
				//And the whole purpuse is to block the chain when a TakeoverMessage is shown.
				console.error('%cYou need at least a title and a message in order to show a TakeoverMessage.',
					'font-weight:bold;color:#CC0000;');
			}

			//Return a never-resolving promise to block promise chain onward from executing.
			return $q.defer().promise;
		};

		//Hide Takeover Message.
		this.hide = function() {
			//$evalAsync ensures watchers are notified of changes.
			//This avoids having to check if `$$phase` is falsey and then call `$apply` manually.
			$rootScope.$evalAsync(()=>isVisible = false);
		};


		/*=== Common takeover messages ===*/

		//Reload the current page, without using the cache.
		const reloadApp = function() {
			$document[0].location.reload(true);
		};

		//Shows generic error takeover message.
		this.showGenericError = function() {
			const config = {
				iconSrc: 'cracked-bulb.svg',
				title: 'Ooops',
				message: 'An error on our end prevents ABO from continuing.',
				actionLabel: 'Reload',
				actionFn: reloadApp
			};

			return this.show(config);
		}.bind(this);

		// Show error during login flow
		this.showUnexpectedLoginError = function() {
			const config = {
				iconSrc: 'cracked-bulb.svg',
				title: 'Login error',
				message: 'An error occurred during login.',
				actionLabel: 'Logout',
				actionFn: ImsAuth.triggerSignOutFlow
			};

			return this.show(config);
		}.bind(this);

		//Shows unauthorized error takeover message.
		this.showUnauthorizedUserError = function() {
			const config = {
				iconSrc: 'unauthorized.svg',
				title: 'Unauthorized',
				message: `
					You are not entitled to use ABO.
					<br/><br/>
					<a href="https://wiki.corp.adobe.com/display/ABO2/ABO+Access+Request+Procedure" target="_blank">Request ABO access</a>`
			};

			return this.show(config);
		}.bind(this);

		//Shows expired user error takeover message.
		this.showExpiredUserError = function() {
			const config = {
				iconSrc: 'unauthorized.svg',
				title: 'Unauthorized',
				message: `
					Your account has expired.
					<br/>
					<br/>
					<a href="https://wiki.corp.adobe.com/display/ABO2/ABO+Access+Request+Procedure" target="_blank">Request ABO access</a>`
			};

			return this.show(config);
		}.bind(this);

		//Shows disabled user error takeover message.
		this.showDisabledUserError = function() {
			const config = {
				iconSrc: 'unauthorized.svg',
				title: 'Unauthorized',
				message: `
					Your account has been disabled.
					<br/><br/>
					<a href="https://wiki.corp.adobe.com/display/ABO2/ABO+Access+Request+Procedure" target="_blank">Request ABO access</a>`
			};

			return this.show(config);
		}.bind(this);

		//Shows unauthorized error takeover message.
		this.showMissingCustomerError = function() {
			const config = {
				iconSrc: 'cracked-bulb.svg',
				title: 'Missing customer',
				message: `
					An error occurred while retrieving this customer's data.
					<br/><br/>
					<a href="/customers/search" target="_self">Back to search</a>`
			};

			return this.show(config);
		}.bind(this);
	}
}());
