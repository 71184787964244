/******************************************************************************************
 *    Customer Account Access View Model
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerAccountAccessVm', [])
		.factory('CustomerAccountAccessVm', CustomerAccountAccessVmFactory);

	function CustomerAccountAccessVmFactory(ViewModelFactory, CUSTOMER_STATUS, CUSTOMER_ACCOUNT_TYPE, User) {
		// TODO: update this code when the status change is pretty in the backend
		const extractStatusFromAudit = (audit)=> {
			const info = audit || {};
			const parameters = info.parametersMap || {};
			if (parameters.status) {
				const change = parameters.status || '';
				const status = change.split('->')[1];
				return (status || '').toUpperCase();
			} else if (parameters.GDPR) {
				return CUSTOMER_STATUS.ACTIVE;
			} else if (parameters.statusCode) {
				/* Batch disable tool logs statusCode=number instead of status=string */
				const statusCodeMapping = [CUSTOMER_STATUS.ACTIVE, CUSTOMER_STATUS.DISABLED,
					CUSTOMER_STATUS.LOCKED, CUSTOMER_STATUS.REMOVED];
				return statusCodeMapping[parameters.statusCode];
			}
		};

		const init = function(customer) {
			this.customer = customer;
			this.pdnaDisabled = Boolean(customer.pdnaDisabled);
			this.status = customer.status || '[N/A]';
			this.temporaryStatus = customer.temporaryStatus || '';

			this.isCustomerStatusLoading = !Boolean(customer.status);

			if (customer.accountAccessStatus) {
				this.statusChange = {
					agent: customer.accountAccessStatus.agentUsername || '[N/A]',
					date: customer.accountAccessStatus.createdDate || '[N/A]',
					comment: customer.accountAccessStatus.comment || '[N/A]'
				};
			}

			this.isIndividualAccountType = CUSTOMER_ACCOUNT_TYPE[customer.authSrcType] === CUSTOMER_ACCOUNT_TYPE.INDIVIDUAL;
			this.isStatusActive = customer.status === CUSTOMER_STATUS.ACTIVE;
			this.isStatusDisabled = customer.status === CUSTOMER_STATUS.DISABLED;
			this.isStatusRemoved = customer.status === CUSTOMER_STATUS.REMOVED;
			this.isDisabledWithGDPR = Boolean(customer.accountAccessStatus && customer.accountAccessStatus.auditActionName === 'DISABLE_ACCOUNT' &&
				customer.accountAccessStatus.parametersMap && customer.accountAccessStatus.parametersMap.GDPR);

			// When the account is removed, the audit in ABO is also cleared for this account because of the GDPR laws.
			const isInterestedInMismatchedStatus = !this.isStatusRemoved && !this.isCustomerStatusLoading;
			if (isInterestedInMismatchedStatus) {
				const expectedStatus = extractStatusFromAudit(customer.accountAccessStatus) || CUSTOMER_STATUS.ACTIVE;
				this.isStatusMismatched = this.status !== expectedStatus;
			} else {
				this.isStatusMismatched = false;
			}
			const hasStatusButton = !this.isCustomerStatusLoading && this.isIndividualAccountType && User.canEditCustomerStatusToNotBeDeleted();
			this.hasDisableButton = hasStatusButton && this.isStatusActive;
			this.hasEnableButton = hasStatusButton && this.isStatusDisabled && !(this.pdnaDisabled && !User.canEditCustomerStatusWithPdna());

			this.hasDeleteButton = User.canDeleteCustomer() && !this.isStatusRemoved && this.isIndividualAccountType;

			this.canDisableWithPdna = !this.isCustomerStatusLoading && !this.isStatusRemoved && User.canEditCustomerStatusWithPdna();
			this.hasDisablePhotoDnaButton = !this.pdnaDisabled && !this.isDisabledWithGDPR && this.canDisableWithPdna;
			this.hasCancelPhotoDnaButton = this.pdnaDisabled && this.isDisabledWithGDPR && this.canDisableWithPdna;

			this.hasAnyButton = this.hasDisableButton || this.hasEnableButton || this.hasDeleteButton ||
				this.hasDisablePhotoDnaButton || this.hasCancelPhotoDnaButton;
		};

		const requiredModelProperties = ['status', 'temporaryStatus', 'accountAccessStatus', 'pdnaDisabled', 'authSrcType'];

		return ViewModelFactory(init, requiredModelProperties);
	}
}());
