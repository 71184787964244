/******************************************************************************************
 *    Utility Filters
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('core.Util', [])
		.filter('yesNo', yesNo);

	function yesNo() {
		return function(bool) {
			return bool ? 'Yes' : 'No';
		};
	}
}());
