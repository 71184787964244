/******************************************************************************************
 *    Customer Details View Model
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerDetailsVm', [])
		.factory('CustomerDetailsVm', CustomerDetailsVmFactory);

	function CustomerDetailsVmFactory(EditableViewModelFactory, CUSTOMER_ACCOUNT_TYPE, CUSTOMER_STATUS, User) {
		const init = function(customer) {
			this.accountId = customer.accountId;
			this.authSrcInAccountId = customer.accountId.split('@')[1];
			this.accountType = CUSTOMER_ACCOUNT_TYPE[customer.authSrcType] || CUSTOMER_ACCOUNT_TYPE.UNKNOWN;
			this.responsibleAuthSrc = customer.responsibleAuthSrc;
			this.shouldDisplayResponsibleAuthSrc = customer.responsibleAuthSrc !== this.authSrcInAccountId && customer.responsibleAuthSrc !== 'WCD';
			this.responsibleAccountType = CUSTOMER_ACCOUNT_TYPE[customer.responsibleAuthSrcType] || CUSTOMER_ACCOUNT_TYPE.UNKNOWN;
			this.currentAccountType = this.responsibleAccountType !== CUSTOMER_ACCOUNT_TYPE.UNKNOWN &&
				this.responsibleAccountType !== CUSTOMER_ACCOUNT_TYPE.NOT_SET ? this.responsibleAccountType : this.accountType;
			this.hasDifferentAccountTypes = this.accountType !== this.responsibleAccountType;
			this.isIndividualAccountType = CUSTOMER_ACCOUNT_TYPE.INDIVIDUAL === this.currentAccountType;
			this.countryCode = customer.countryCode || '[N/A]';
			this.dateOfBirth = customer.dateOfBirth || '[N/A]';
			this.hasDateOfBirth = Boolean(customer.dateOfBirth);
			this.securityPhoneNumber = customer.securityPhoneNumber || '[N/A]';
			this.email = customer.email;
			// These properties needs to be null if set to the empty string
			this.firstName = customer.firstName || '';
			this.lastName = customer.lastName || '';
			this.screenName = customer.screenName || '';

			// Username needs to match the email if it is updated or the original username otherwise
			Object.defineProperty(this, 'username', {
				configurable: true, /* Allow the property to be changed/deleted */
				get: ()=> (this.email === customer.email ? customer.username : this.email) || '[N/A]'
			});

			Object.defineProperty(this, 'verifiedIdentity', {
				configurable: true, /* Allow the property to be changed/deleted */
				get: ()=> customer.verifiedIdentity
			});

			this.hasEditableFields = this.isIndividualAccountType && User.canEditCustomerDetails();
			this.hasFixCredentials = !customer.username;
			this.isEditable = customer.status === CUSTOMER_STATUS.ACTIVE && !!customer.username;
			this.isPhoneVerifiable = !!customer.securityPhoneNumber;
			this.canEmptyScreenName = !customer.screenName;
			this.isPaidCustomer = customer.isPaidCustomer;
			this.contracts = customer.contracts;
			this.canClearDob = User.canClearDob() && this.hasDateOfBirth && this.isIndividualAccountType;

			// Validations
			this.emailPattern = /^[^[\]:,\(\)<>"@]+@[^[\]:,\(\)<>"@]+\.[^[\]:,\(\)<>"@]+$/;
			this.emailMaxLength = 60;
			this.namePattern = /^[^<>";]+$/;
			this.nameMaxLength = 300;
			this.screenNamePattern = /^[^<>";]*$/;
			this.screenNameMaxLength = 25;
		};

		const requiredModelProperties = ['username', 'firstName', 'lastName', 'screenName', 'email',
			'countryCode', 'authSrc', 'authSrcType', 'responsibleAuthSrc', 'responsibleAuthSrcType', 'dateOfBirth',
			'securityPhoneNumber', 'status', 'temporaryStatus', 'isPaidCustomer', 'contracts'];

		return EditableViewModelFactory(init, requiredModelProperties);
	}
}());
