/******************************************************************************************
 *    Customer Account Access View Model
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerExportVm', [])
		.factory('CustomerExportVm', CustomerExportVmFactory);

	function CustomerExportVmFactory(ViewModelFactory, CUSTOMER_STATUS, CUSTOMER_EMAIL_STATUS, GeneralUtility, StringUtility) {
		const getZipCode = (zipCode)=> zipCode.length <= 5 ? zipCode : `${zipCode.substr(0, 5)}-${zipCode.substr(5)}`;
		const getAddress = (address)=> ({
			address: [address.line1, address.line2, address.line3, address.line4, address.line5, address.line6]
				         .filter((line)=> !!line).join(' ') || '[N/A]',
			state: address.state || '[N/A]',
			country: address.countryCode || '[N/A]',
			city: address.city || '[N/A]',
			zipCode: getZipCode(address.postalZip || '') || '[N/A]',
			modifiedDate: address.modifiedDate || '[N/A]'
		});

		const init = function(customer) {
			this.accountId = customer.accountId;
			this.email = customer.email || '[N/A]';
			this.alternateEmail = customer.alternateEmail || '[N/A]';
			this.username = customer.username || '[N/A]';
			this.firstName = customer.firstName || '[N/A]';
			this.lastName = customer.lastName || '[N/A]';
			this.country = customer.countryCode || '[N/A]';
			this.dateOfBirth = customer.dateOfBirth || '[N/A]';
			this.securityPhoneNumber = customer.securityPhoneNumber || '[N/A]';
			this.isMfaEnabled = customer.isMfaEnabled;

			this.loginHistory = (customer.loginHistory || []).map((entry)=>({
				ip: entry.ip || '[N/A]',
				country: entry.countryCode || '[N/A]',
				date: entry.firstSeenDate || '[N/A]'
			}));

			const creationDetails = customer.creationDetails || {};
			this.creationIp = creationDetails.userIp || '[N/A]';
			this.creationDate = creationDetails.guidDate || '[N/A]';

			const addresses = customer.addresses || {};
			this.mailingAddress = addresses.mailingAddress ? getAddress(addresses.mailingAddress) : null;
			this.billingAddress = addresses.billingAddress ? getAddress(addresses.billingAddress) : null;
			this.shippingAddress = addresses.shippingAddress ? getAddress(addresses.shippingAddress) : null;

			this.status = CUSTOMER_STATUS[customer.status] || CUSTOMER_STATUS.UNKNOWN;
			this.statusChangeDate = customer.accountAccessStatus && customer.accountAccessStatus.createdDate ||
			                        this.creationDate;

			const initEmailVerificationInformation = (emailVerificationInformation) => {
				emailVerificationInformation = emailVerificationInformation || {};
				//a null email verification should mean a N/A email verification status
				//while some non-null but incorrect value would be UNKNOWN
				const computedEmailVerificationStatus = !emailVerificationInformation.status ? '[N/A]' :
				                                        CUSTOMER_EMAIL_STATUS[emailVerificationInformation.status] ||
				                                        CUSTOMER_EMAIL_STATUS.UNKNOWN;
				return {
					emailStatus: computedEmailVerificationStatus,
					emailSendDate: emailVerificationInformation.lastSendDate || '[N/A]'
				};
			};
			
			const emailStatus = customer.emailStatus || {};
			this.primaryEmailStatus = initEmailVerificationInformation(emailStatus.primary);
			this.alternateEmailStatus = initEmailVerificationInformation(emailStatus.alternate);

			this.entitlements = (customer.entitlements || []).map((entitlement)=>({
				name: entitlement.name || '[N/A]',
				code: entitlement.code || '[N/A]',
				createdDate: entitlement.createdDate || '[N/A]',
				effectiveEndDate: entitlement.effectiveEndDate || '[N/A]',
				status: entitlement.status || '[N/A]',
				level: entitlement.level || '[N/A]'
			}));

			this.productContexts = GeneralUtility.ensureArray(customer.productContexts)
				.map(productContext => {
					let pc = {};
					angular.copy(productContext, pc);
					for (let key in pc) {
						if (pc.hasOwnProperty(key) && !pc[key]) {
							pc[key] = '[N/A]';
						}
					}
					
					pc.params = Object.keys(productContext.params)
						.filter(key=> key !== 'EMPTY_KEY')
						.map(key=> ({
							name: key,
							label: StringUtility.prettifyLabel(key),
							value: productContext.params[key] || '[N/A]'
						}));
					pc.orgs = GeneralUtility.ensureArray(customer.organizations)
						.filter(org=> org.organizationId === pc.owningEntityId);
					return pc;
				})
				.sort((pc1, pc2)=> StringUtility.compareStringsIgnoreCase(pc1.serviceCode + pc1.label, pc2.serviceCode + pc2.label));

			this.contracts = GeneralUtility.ensureArray(customer.contracts);
		};

		const requiredModelProperties = ['email', 'username', 'firstName', 'lastName', 'countryCode', 'dateOfBirth',
			'securityPhoneNumber', 'isMfaEnabled', 'loginHistory', 'creationDetails', 'accountAccessStatus', 'addresses',
			'status', 'emailStatus', 'entitlements', 'productContexts', 'organizations', 'contracts'];

		return ViewModelFactory(init, requiredModelProperties);
	}
}());
