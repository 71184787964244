/******************************************************************************************
 *    wizard Service
 ******************************************************************************************
 *
 * A Wizard is a modal containing several steps.
 */
(function() {
	'use strict';

	angular.module('coreDirectives.Wizard', [
		'coreDirectives.Modal',
		'coreDirectives.StepBar'
	])
		.service('Wizard', Wizard);

	function Wizard($q, $templateRequest, $compile, $document, Modal) {
		let currentStep;
		let wizardElement;

		// Opens a wizard
		this.open = ({title, steps, locals, controller, controllerAs, startingStep})=> {
			if (!title) {
				throw new Error('Missing configuration for wizard: `title` is required.');
			}

			if (!steps || !Array.isArray(steps)) {
				throw new Error('Missing configuration for wizard: `steps` array is required.');
			}

			if (controllerAs && !controller) {
				throw new Error('Missing configuration for wizard: `controllerAs` requires `controller` to be set.');
			}

			if (locals !== undefined && typeof locals !== 'object') {
				throw new Error('Invalid configuration for wizard: `locals` must be an object');
			}

			steps.forEach((step)=> {
				if (!step.template && !step.templateUrl) {
					throw new Error('Missing configuration for wizard: each step requires `template` or `templateUrl` to be set.');
				}
			});

			Modal.isLoading = true;
			currentStep = startingStep || 0;

			this.stepLabels = steps.map(({label})=> label || '');
			locals = locals || {};
			locals.Wizard = this;

			return $q.all(steps.map(({template, templateUrl})=> template ? $q.resolve(template) : $templateRequest(templateUrl, true)))
				.then((stepTemplates)=> {
					this.stepTemplates = stepTemplates;
				})
				.then(()=> {
					const wizardResult = Modal.open({title, templateUrl: 'wizard.html', locals, controller, controllerAs});

					Modal.renderReady.then(()=> {
						wizardElement = angular.element($document[0].querySelector('.wizard-content'));
						this.setStep(currentStep);
					});

					return wizardResult;
				});
		};

		this.getStep = ()=> currentStep;
		this.setStep = (step)=> {
			if (!wizardElement || step < 0 || step >= this.stepTemplates.length) {
				console.log('Invalid wizard step', step, this.stepTemplates.length, wizardElement);
				return;
			}
			currentStep = step;
			wizardElement.html(this.stepTemplates[step]);
			$compile(wizardElement)(Modal.getScope());
		};

		this.prev = ()=> {
			this.setStep(currentStep - 1);
		};

		this.next = ()=> {
			this.setStep(currentStep + 1);
		};

		// Closes the currently open wizard, equivalent to the OK button, accepting the wizard promise
		this.close = (reason)=> {
			Modal.close(reason);
		};

		// Closes the currently open wizard, equivalent to the Cancel button, rejecting the wizard promise
		this.dismiss = (reason)=> {
			Modal.dismiss(reason);
		};
	}
}());
