/******************************************************************************************
 *    Customer Batch Action Controller
 *    This controller will be used to perform bulk action on customers
 ******************************************************************************************/
(function() {
	'use strict';
	angular.module('customers.CustomerBatchActionController', [])
		.controller('CustomerBatchActionController', CustomerBatchActionController);

	function CustomerBatchActionController($scope, $q, CustomerService, CustomerBatchVm, Modal, fileDownload, VM_STATE,
											utcDateFilter, ADOBE_ID_AUTH_SOURCE, User) {

		this.sendEmailForForcePwdReset = false;
		this.selectedAction = null;
		this.hasFails = false;
		this.userHasAnyPermission = false;
		
		const ACTION_STATUS = {
			INVALID: 'INVALID',
			SKIPPED: 'SKIPPED',
			DISABLED: 'DISABLED',
			FAILED: 'FAILED',
			ACTIVATION_IF_SUCCESS: 'ACCOUNT-ACTIVATED',
			INVALID_CSV_ROW: 'INVALID-CSV-ROW',
			PASSWORD_RESET_SUCCESS: 'PASSWORD-RESET-SUCCESS'
		};

		const ACTIONS = {
			disableActionWithLegalLock: 'disable-with-legal-lock',
			disableActionWithoutLegalLock: 'disable-without-legal-lock',
			activateCustomer: 'activate-customer',
			forcePasswordReset: 'force-password-reset'
		};

		const isValidId = (id)=> {
			if (!id || typeof id !== 'string') {
				return false;
			}
			const guid = id.split('@')[0];
			return /^[0-9A-Fa-f]{24}$/.test(guid);
		};

		const buildInvalidCustomerVm = (id)=> ({
			accountId: id,
			invalid: true,
			actionStatus: ACTION_STATUS.INVALID,
			actionStatusError: 'The accountId is invalid',
			state: VM_STATE.ERROR
		});
		
		const rejectionFunction = (customer, rejection) => {
			this.hasFails = true;
			customer.actionStatus = ACTION_STATUS.FAILED;
			customer.actionStatusError = rejection.data && rejection.data.errorDescription ||
				rejection.data && rejection.data.error ||
				'Failed for unknown reason';
		};

		// CSV row data is expected to be in the format --> adobeId,comment,action
		const processCustomerList = (file)=> {
			if (!file) {
				this.customers = null;
				this.invalidFormat = false;
				this.missingComment = false;
				return;
			}
			this.customers = file.split(/[\r\n]/)
				/* filter empty rows */
				.filter((row)=> !!row.trim())
				/* convert rows to VMs */
				.map((row)=> {
					const split = row.split(/[,;\t]/);
					const rawId = split[0] || '';
					let id = rawId.trim();

					// ID can be missing if the CSV file has empty rows
					if (!isValidId(id)) {
						return buildInvalidCustomerVm(id);
					}

					// infer individual accountIds from GUIDs
					if (id.indexOf('@') === -1) {
						id += '@' + ADOBE_ID_AUTH_SOURCE;
					}
					const customer = CustomerService.get(id);
					const vm = new CustomerBatchVm(customer);
					// The comment is whatever remains on the row after the ID it also includes the action type
					vm.comment = row.substring(rawId.length + 1).trim();
					customer.comment = vm.comment;
					return vm;
				});
			/* If no IDs have been extracted, consider the file to be invalid */
			this.invalidFormat = !this.customers.length || this.customers.every((customer)=> customer.invalid);
			if (this.invalidFormat) {
				this.customers = null;
			}
			/* There may be customers that don't have a disable comment */
			this.missingComment = this.customers &&
				this.customers.some((customer)=> !customer.invalid && !customer.comment);
		};
		
		$scope.$watch(()=>this.file, processCustomerList);

		Object.defineProperty(this, 'isLoading', {
			get: ()=> !this.customers || this.customers.some((customer)=> customer.state === VM_STATE.LOADING)
		});
		
		const editCustomerAccountStatus = (customer) => {
			let commentsArray = customer.comment.split(',');
			
			if (commentsArray[0].length === 0) {
				customer.actionStatus = ACTION_STATUS.INVALID_CSV_ROW;
				customer.actionStatusError = 'Comment is not valid';
				return $q.resolve(customer);
			}
			
			let isCompareDisableWithLegalLock = commentsArray[1].localeCompare(ACTIONS.disableActionWithLegalLock);
			let isCompareDisableWithoutLegalLock = commentsArray[1].localeCompare(ACTIONS.disableActionWithoutLegalLock);
			let isCompareActivateCustomer = commentsArray[1].localeCompare(ACTIONS.activateCustomer);

			if (isCompareDisableWithoutLegalLock === 0) {
				return CustomerService.get(customer.accountId)
					.disableWithGDPR(false, customer.comment)
					.then(() => {
						customer.actionStatus = ACTION_STATUS.DISABLED;
						customer.syncFromModel();
					})
					.catch((rejection) => rejectionFunction(customer, rejection));
			} else if (isCompareDisableWithLegalLock === 0) {
				return CustomerService.get(customer.accountId)
					.disableWithGDPR(true, customer.comment)
					.then(() => {
						customer.actionStatus = ACTION_STATUS.DISABLED;
						customer.syncFromModel();
					})
					.catch((rejection) => rejectionFunction(customer, rejection));
			} else if (isCompareActivateCustomer === 0) {
				return CustomerService.get(customer.accountId)
					.enableWithGDPR(commentsArray[0])
					.then(()=> {
						customer.actionStatus = ACTION_STATUS.ACTIVATION_IF_SUCCESS;
						customer.syncFromModel();
					})
					.catch((rejection) => rejectionFunction(customer, rejection));
			} else {
				this.hasFails = true;
				customer.actionStatus = ACTION_STATUS.INVALID_CSV_ROW;
				customer.actionStatusError = 'Action is not valid';
			}
		};
		
		const forceResetCustomerPassword = (customer) => {
			let commentsArray = customer.comment.split(',');
			
			if (commentsArray[0].length === 0) {
				customer.actionStatus = ACTION_STATUS.INVALID_CSV_ROW;
				customer.actionStatusError = 'Comment is not valid';
				return $q.resolve(customer);
			}
			
			let isCompareForceResetPassword = commentsArray[1].localeCompare(ACTIONS.forcePasswordReset);
			if (isCompareForceResetPassword === 0) {
				return CustomerService.get(customer.accountId)
					.forcePasswordReset(customer.accountId, customer.comment, this.sendEmailForForcePwdReset)
					.then(() => {
						customer.actionStatus = ACTION_STATUS.PASSWORD_RESET_SUCCESS;
					})
					.catch((rejection) => rejectionFunction(customer, rejection));
			} else {
				this.hasFails = true;
				customer.actionStatus = ACTION_STATUS.INVALID_CSV_ROW;
				customer.actionStatusError = 'Action is not valid';
			}
		};
		
		this.performActionOnCustomer = ()=> {
			Modal.isLoading = true;
			const calls = this.customers
				.map(customer=> {
					if (customer.invalid) {
						customer.actionStatus = ACTION_STATUS.INVALID;
						return $q.resolve(customer);
					}

					if (!customer.included) {
						customer.actionStatus = ACTION_STATUS.SKIPPED;
						return $q.resolve(customer);
					}
					
					if (this.selectedAction === ACTIONS.forcePasswordReset) {
						forceResetCustomerPassword(customer);
					} else if (this.selectedAction === ACTIONS.activateCustomer ||
						this.selectedAction === ACTIONS.disableActionWithLegalLock ||
						this.selectedAction === ACTIONS.disableActionWithoutLegalLock) {
						editCustomerAccountStatus(customer);
					}
				});

			$q.all(calls).finally(()=> {
				const statusOrder = [ACTION_STATUS.INVALID, ACTION_STATUS.FAILED,
					ACTION_STATUS.SKIPPED, ACTION_STATUS.DISABLED,ACTION_STATUS.INVALID_CSV_ROW,
					ACTION_STATUS.ACTIVATION_IF_SUCCESS, ACTION_STATUS.PASSWORD_RESET_SUCCESS];
				this.customers.sort((a, b)=> {
					return statusOrder.indexOf(a.actionStatus) - statusOrder.indexOf(b.actionStatus);
				});
				
				this.done = true;
				Modal.isLoading = false;
			});
		};

		this.downloadResults = ()=> {
			const getCsvOutput = (customer)=> [customer.accountId, customer.actionStatus, customer.actionStatusError].join(',');
			const content = this.customers.map(getCsvOutput).join('\n');
			const date = utcDateFilter(Date.now(), 'dateStamp');
			const filename = `batch-action-results_${date}.csv`;
			fileDownload(content, filename);
		};
		
		this.getActionList = () => {
			return ACTIONS;
		};

		this.isSendingEmailNotificationEnabled = () => {
			return this.selectedAction === ACTIONS.forcePasswordReset;
		};
		
		this.getActionsBasedOnUserPermission = () => {
			let actionList = [];
			if (User.canForceResetCustomerPassword()) {
				actionList.push(ACTIONS.forcePasswordReset);
				this.userHasAnyPermission = true;
			}
			if (User.canEditCustomerStatus()) {
				actionList.push(ACTIONS.disableActionWithoutLegalLock);
				actionList.push(ACTIONS.activateCustomer);
				this.userHasAnyPermission = true;
			}
			if (User.canEditCustomerStatusWithPdna()) {
				actionList.push(ACTIONS.disableActionWithLegalLock);
				this.userHasAnyPermission = true;
			}
			return actionList;
		};
	}
}());
