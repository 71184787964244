/******************************************************************************************
 *    Redirector Service
 *******************************************************************************************
 *
 * Should be the preferred way of navigating states programatically.
 *
 */
(function() {
	'use strict';

	angular.module('core.Redirector', []).service('Redirector', Redirector);

	function Redirector($state) {
		//Basic stateName validation
		const simplisticallyValidState = function(stateName) {
			if (stateName && typeof stateName === 'string') {
				return true;
			}

			return false;
		};


		/*=== Public Interface ===*/

		//Navigate to specified state.
		this.go = function(stateName, stateParams, options) {
			if (simplisticallyValidState(stateName)) {
				console.log('%cRedirector :: %s >>> %s', 'color:#CCCCCC;', $state.current.name || $state.current.url, stateName);
				$state.go(stateName, stateParams, options);
			}
		};

		//Navigate to specified state, replacing the current one in browser history.
		this.replaceWith = function(stateName, stateParams) {
			this.go(stateName, stateParams, {location: 'replace'});
		};
	}
}());
