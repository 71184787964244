/******************************************************************************************
 *    Roles Model
 ******************************************************************************************/
(function() {
	'use strict';


	angular.module('entities.RolesModel', [])
		.factory('RolesModel', RolesModelFactory);

	function RolesModelFactory(AboApi, SingletonModel) {
		function RolesModel() {
			this.registerPropertiesFetcher(()=> AboApi.getRolesMetadata(), 'roles');
		}

		return SingletonModel(RolesModel);
	}

}());
