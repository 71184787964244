/******************************************************************************************
 *    UTC Date Filter
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('core.DateFilter', [])
		.filter('utcDate', UtcDateFactory)
		.filter('fromDateString', FromDateStringFactory);

	// Retrieves a UTC Date from a string formatted like YYYY-MM-DD
	function FromDateStringFactory() {
		return function(dateString) {
			if (!dateString) {
				return null;
			}
			const dateArray = (dateString || '').split('-');
			return Date.UTC(+dateArray[0], +dateArray[1] - 1, +dateArray[2]);
		};
	}


	function UtcDateFactory() {
		const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June',
			'July', 'August', 'September', 'October', 'November', 'December'];
		const SHORT_MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
		const SHORT_WEEKDAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];


		// Pads with 0 a number between 0 and 99 to a two-digit string
		const pad = function(number) {
			return String(number).length === 1 ? '0' + number : String(number);
		};

		const getFormattedTime = function(date) {
			return `${pad(date.getUTCHours())}:${pad(date.getUTCMinutes())}:${pad(date.getUTCSeconds())}`;
		};

		const getFormattedDate = function(date) {
			return `${MONTHS[date.getUTCMonth()]} ${date.getUTCDate()} ${date.getUTCFullYear()}`;
		};

		const getFormattedFullDate = function(date) {
			return `${SHORT_WEEKDAYS[date.getUTCDay()]} ${getFormattedDate(date)} ${getFormattedTime(date)} UTC`;
		};

		const getDateStamp = function(date) {
			return `${date.getUTCFullYear()}-${SHORT_MONTHS[date.getUTCMonth()].toUpperCase()}-${date.getUTCDate()}`;
		};

		const getFormattedMonthYear = function(date) {
			return `${MONTHS[date.getUTCMonth()]} ${date.getUTCFullYear()}`;
		};

		return function UtcDateFilter(input, mode = 'date') {
			const isDateLike = input instanceof Date ||
			                   typeof input === 'number' && !isNaN(input) ||
			                   typeof input === 'string' && !isNaN(+input);
			// Allow non-dates to pass through
			if (!isDateLike) {
				return input;
			}
			
			if (input === 0) {
				return 'N/A';
			}

			// Extract UTC date
			const date = new Date(+input);

			switch (mode) {
				case 'dateStamp':
					// 1987-JUN-12
					return getDateStamp(date);
				case 'date':
					// June 12 1987
					return getFormattedDate(date);

				case 'full':
					// Thu June 12 1998 07:23:03 UTC
					return getFormattedFullDate(date);
					
				case 'monthYearDate' :
					// June 1987
					return getFormattedMonthYear(date);

				default:
					throw new Error('Unrecognized utcDate mode');
			}
		};
	}
}());
