/******************************************************************************************
 *    Customer Details View Model
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerDetailsVm', [])
		.factory('CustomerDetailsVm', CustomerDetailsVmFactory);

	function CustomerDetailsVmFactory(EditableViewModelFactory, CUSTOMER_ACCOUNT_TYPE, CUSTOMER_STATUS, CUSTOMER_PASSWORD_STATUS,
									  User, GeneralUtility) {
		const init = function(customer) {
			this.accountId = customer.accountId || 'GUID@WCD';
			this.authSrcInAccountId = this.accountId.split('@')[1];
			this.accountType = CUSTOMER_ACCOUNT_TYPE[customer.authSrcType] || CUSTOMER_ACCOUNT_TYPE.UNKNOWN;
			this.responsibleAuthSrc = customer.responsibleAuthSrc;
			this.shouldDisplayResponsibleAuthSrc = customer.responsibleAuthSrc !== this.authSrcInAccountId && customer.responsibleAuthSrc !== 'WCD';
			this.responsibleAccountType = CUSTOMER_ACCOUNT_TYPE[customer.responsibleAuthSrcType] || CUSTOMER_ACCOUNT_TYPE.UNKNOWN;
			this.isResponsibleAccountTypeSet = this.responsibleAccountType !== CUSTOMER_ACCOUNT_TYPE.UNKNOWN &&
				this.responsibleAccountType !== CUSTOMER_ACCOUNT_TYPE.NOT_SET;
			this.currentAccountType = this.isResponsibleAccountTypeSet ? this.responsibleAccountType : this.accountType;
			this.hasDifferentAccountTypes = this.isResponsibleAccountTypeSet ? this.accountType !== this.responsibleAccountType : false;
			this.isIndividualAccountType = CUSTOMER_ACCOUNT_TYPE.INDIVIDUAL === this.currentAccountType;
			this.isEntitlementOnlyAccountAccountType = 'ENTITLEMENT_ONLY_ACCOUNT' === customer.authSrcType;
			this.tags = customer.tags || [];
			this.humanReadableTags = this.tags.join(', ') || '[N/A]';
			this.countryCode = customer.countryCode || '[N/A]';
			this.preferredLangs = angular.isArray(customer.preferredLangs) ? customer.preferredLangs : [];
			this.humanReadablePreferredLangs = customer.humanReadablePreferredLangs || '[N/A]';
			this.dateOfBirth = customer.dateOfBirth || '[N/A]';
			this.hasDateOfBirth = Boolean(customer.dateOfBirth);
			this.securityPhoneNumber = customer.securityPhoneNumber || '[N/A]';
			this.email = customer.email;
			// These properties needs to be null if set to the empty string
			this.firstName = customer.firstName || '';
			this.lastName = customer.lastName || '';
			this.screenName = customer.screenName || '';
			this.alternateEmail = customer.alternateEmail || '';

			// Username needs to match the email if it is updated or the original username otherwise
			Object.defineProperty(this, 'username', {
				configurable: true, /* Allow the property to be changed/deleted */
				get: ()=> (this.email === customer.email ? customer.username : this.email) || '[N/A]'
			});

			Object.defineProperty(this, 'verifiedIdentity', {
				configurable: true, /* Allow the property to be changed/deleted */
				get: ()=> customer.verifiedIdentity
			});

			this.hasEditableFields = this.isIndividualAccountType && User.canEditCustomerDetails();
			this.hasFixCredentials = !customer.username;
			this.isEditable = customer.status === CUSTOMER_STATUS.ACTIVE && !!customer.username;
			this.isPhoneVerifiable = !!customer.securityPhoneNumber;
			this.hasPhone = !!customer.securityPhoneNumber;

			// Don't allow to set a phone number if the account doesn't have a password (workaround for an IMS bug)
			// https://jira.corp.adobe.com/browse/SUSI-8420
			// Revert the change when the SUSI fix is in.
			this.passwordStatus = CUSTOMER_PASSWORD_STATUS[GeneralUtility.ensureObject(customer.passwordStatus).status] || CUSTOMER_PASSWORD_STATUS.UNKNOWN;
			this.hasEmptyPassword = this.passwordStatus === CUSTOMER_PASSWORD_STATUS.EMPTY;

			this.canEmptyScreenName = !customer.screenName;
			this.canClearDob = User.canClearDob() && this.hasDateOfBirth && this.isIndividualAccountType;
			this.isMfaEnabled = customer.isMfaEnabled;
			this.isMfaRequiredByOrg = customer.isMfaRequiredByOrg;

			this.isPdnaDisabled = !!customer.pdnaDisabled;
			this.isIncomplete = !!customer.incrementalIncomplete;
			this.isPaidCustomer = customer.isPaidCustomer;
			this.contracts = customer.contracts;
			
			this.invitedOrActiveEntitlementAccountLinkForType2E = customer.invitedOrActiveEntitlementAccountLinkForType2E;
			this.entitlementAccountLinks = customer.entitlementAccountLinks;
			this.isAuthenticatingAccountForEOA = Boolean(customer.entitlementAccountLinks && customer.entitlementAccountLinks.length);
			this.isEOAPreferredUserAgreementPermissionStatusEnabled = customer.isEOAPreferredUserAgreementPermissionStatusEnabled;
			
			this.isSocialOnly = customer.isSocialOnly;
			this.socialAccounts = customer.socialAccounts;
			this.hasSocialAccounts = Boolean(customer.socialAccounts && customer.socialAccounts.length);
			// Customer must have a way to log in, so his last social account cannot be unlinked if he does not have a password.
			this.isUnlinkSocialAllowed = User.canEditCustomerDetails() && !customer.isSocialOnly ||
				customer.socialAccounts && customer.socialAccounts.length > 1;

			// Validations
			// blank string is only affected by ng-required and not by ng-pattern
			this.emailPattern = /^[^[\]:,\(\)<>"@]+@[^[\]:,\(\)<>"@]+\.[^[\]:,\(\)<>"@]+$/;
			this.emailMaxLength = 60;
			this.namePattern = /^[^<>";]+$/;
			this.nameMaxLength = 300;
			this.screenNamePattern = /^[^<>";]*$/;
			this.screenNameMaxLength = 25;
		};

		const requiredModelProperties = ['username', 'firstName', 'lastName', 'screenName', 'email',
			'alternateEmail', 'countryCode', 'preferredLangs', 'humanReadablePreferredLangs',
			'authSrc', 'authSrcType','responsibleAuthSrc', 'responsibleAuthSrcType', 'dateOfBirth',
			'securityPhoneNumber', 'isMfaEnabled', 'status', 'pdnaDisabled', 'incrementalIncomplete', 'isPaidCustomer', 'contracts',
			'isSocialOnly', 'socialAccounts', 'passwordStatus', 'tags',
			'invitedOrActiveEntitlementAccountLinkForType2E', 'entitlementAccountLinks',
			'isEOAPreferredUserAgreementPermissionStatusEnabled', 'isMfaRequiredByOrg'];

		return EditableViewModelFactory(init, requiredModelProperties);
	}
}());
