/******************************************************************************************
 *    Agent Service
 ******************************************************************************************/
// TODO: rethink this service
(function() {
	'use strict';

	angular.module('agents.AgentService', [])
		.service('AgentService', AgentService);

	function AgentService(AboApi, AgentModel, $q) {
		const cachedSearch = {
			query: '',
			results: []
		};
		const cacheSearch = (query, results) => {
			cachedSearch.query = query || '';
			cachedSearch.results = results || [];
		};

		// used to cancel the latest search
		let searchCanceler;

		/* retrieves an array of agents. Each of the agents is filled with the properties returned by the search API */
		this.search = function(query) {
			// cancel previous search if any
			this.search.cancel();

			// make actual call to backend
			searchCanceler = $q.defer();
			return AboApi.findAgents(query, searchCanceler.promise)
				.then((result) => {
					const agents = result.data.map((agentData)=> {
						var agent = new AgentModel(agentData.accountId);
						agent.setSearchDetails(agentData);
						return agent;
					});

					cacheSearch(query, agents);

					return agents;
				});
		};

		this.search.cancel = function() {
			if (searchCanceler) {
				searchCanceler.resolve();
				searchCanceler = null;
			}
		};

		this.getLastSearchQuery = function() {
			return cachedSearch.query;
		};

		var cachedAgent = {};
		/* retrieves a single agent */
		this.get = function(id) {
			const hasCorrectId = (agent)=> agent.accountId === id;

			// Replace the cachedAgent with the one that will be returned if necessary
			if (!cachedAgent.accountId || !hasCorrectId(cachedAgent)) {
				cachedAgent = cachedSearch.results.find(hasCorrectId) || new AgentModel(id);
			}

			return cachedAgent;
		};


		this.create = function(username, manager, permissions, expireDate, comment) {
			return AboApi.createAgent(username, manager, permissions, expireDate, comment)
				.then((response)=> response.data);
		};
	}
}());
