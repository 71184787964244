/******************************************************************************************
 *    Agent Profile Controller
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('agents.AgentProfileController', [])
		.controller('AgentProfileController', AgentProfileController);

	function AgentProfileController($stateParams, AgentService, AgentAccountAccessVm, AgentDetailsVm, AgentPermissionsVm,
	                                Modal, Toast, VM_STATE, PermissionsModel, RolesModel, $state, $scope,
	                                fromDateStringFilter) {
		var agent = AgentService.get($stateParams.id);
		var permissionsModel = PermissionsModel.get();
		var rolesModel = RolesModel.get();

		this.agentAccountAccess = new AgentAccountAccessVm(agent, permissionsModel);
		this.agentDetails = new AgentDetailsVm(agent);
		this.agentPermissions = new AgentPermissionsVm(agent, permissionsModel, rolesModel);


		Object.defineProperty(this, 'isLoading', {
			get: ()=> this.agentAccountAccess.state === VM_STATE.LOADING ||
			          this.agentDetails.state === VM_STATE.LOADING ||
			          this.agentPermissions.state === VM_STATE.LOADING
		});

		const disable = (comment)=> {
			Modal.isLoading = true;
			return agent.disable(comment)
				.then(()=> {
					this.agentAccountAccess.syncFromModel();
					Toast.showSuccess('Agent successfully disabled.');
				})
				.finally(()=> Modal.isLoading = false);
		};
		this.showDisableModal = ()=> {
			Modal.open({
				title: 'Disable account access',
				templateUrl: 'agent-disable-modal.html',
				locals: {accountId: agent.accountId, email: agent.username, disable}
			});
		};


		const enable = (comment)=> {
			Modal.isLoading = true;
			return agent.enable(comment)
				.then(()=> {
					this.agentAccountAccess.syncFromModel();
					Toast.showSuccess('Agent successfully enabled.');
				})
				.finally(()=> Modal.isLoading = false);
		};
		this.showEnableModal = ()=> {
			Modal.open({
				title: 'Enable account access',
				templateUrl: 'agent-enable-modal.html',
				locals: {accountId: agent.accountId, email: agent.username, enable}
			});
		};

		const changeExpireDate = (dateString, comment)=> {
			Modal.isLoading = true;
			const expireDate = fromDateStringFilter(dateString);
			return agent.updateExpireDate(expireDate, comment)
				.then(()=> {
					this.agentAccountAccess.syncFromModel();
					Toast.showSuccess('Agent expire date successfully changed.');
				})
				.finally(()=> Modal.isLoading = false);
		};

		this.showChangeExpireDateModal = ()=> {
			Modal.open({
				title: 'Change expire date',
				templateUrl: 'agent-change-expire-modal.html',
				locals: {pattern: this.agentAccountAccess.expireDatePattern, changeExpireDate}
			});
		};

		Object.defineProperty(this, 'changed', {
			get: ()=> this.agentPermissions.changed
		});

		// Reset all fields to their last known server state
		this.discardChanges = ()=> {
			this.agentPermissions.syncFromModel();
			Toast.showWarning('Agent permission changes discarded.');
		};

		// Update the state on the server
		this.saveChanges = (comment)=> {
			Modal.isLoading = true;
			const permissions = this.agentPermissions.permissions;
			return agent.updatePermissions(permissions, comment)
				.then(()=> {
					this.agentPermissions.syncFromModel();
					Toast.showSuccess('Agent permissions successfully saved.');
				})
				.finally(()=> Modal.isLoading = false);
		};

		// Prompt the user to give a reason for the changes
		this.showSaveModal = (allowDiscard = false)=> {
			const permissions = this.agentPermissions.permissions;
			return Modal.open({
				title: 'Save agent permission changes',
				templateUrl: 'agent-save-modal.html',
				locals: {permissions, allowDiscard, save: this.saveChanges, discard: this.discardChanges}
			});
		};

		this.showDiscardModal = ()=> {
			return Modal.open({
				title: 'Discard agent permission changes',
				templateUrl: 'agent-discard-modal.html',
				locals: {discard: this.discardChanges}
			});
		};

		// Prompt the user to save changes when navigating away from the page
		$scope.$on('$stateChangeStart', (evt, toState, toParams)=> {
			const resumeNavigation = ()=> {
				$state.go(toState, toParams);
			};

			if (this.changed) {
				evt.preventDefault();
				if ($scope.agentProfileForm.$valid) {
					// By the time the modal closing promise resolves, the VM will be in sync with the updated model
					// avoiding to prompt again when navigation is resumed
					this.showSaveModal(true).then(resumeNavigation);
				} else {
					// Invalid forms cannot be saved, but it's still nice to let the user know he made changes
					this.showDiscardModal().then(resumeNavigation);
				}
			}
		});

	}
}());
