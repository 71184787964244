/******************************************************************************************
 *    Customer Constants
 ******************************************************************************************/
(function() {
	'use strict';


	angular.module('customers.Constants', [])
		// Authorization source for individual Adobe accounts
		.constant('ADOBE_ID_AUTH_SOURCE', 'AdobeID')
		// Account types
		.constant('CUSTOMER_ACCOUNT_TYPE', {
			INDIVIDUAL: 'Adobe ID',
			ORGANIZATION: 'Organization',
			ENTERPRISE: 'Enterprise ID',
			FEDERATED: 'Federated ID',
			ENTITLEMENT_ONLY_ACCOUNT: 'Entitlement Only Account',
			NOT_SET: 'Not Set',
			UNKNOWN: 'Unknown'
		})
		.run(($rootScope, CUSTOMER_ACCOUNT_TYPE)=> $rootScope.CUSTOMER_ACCOUNT_TYPE = CUSTOMER_ACCOUNT_TYPE)
		// Account statuses
		.constant('CUSTOMER_STATUS', {
			ACTIVE: 'ACTIVE',
			DISABLED: 'DISABLED',
			LOCKED: 'LOCKED',
			REMOVED: 'REMOVED',
			UNKNOWN: 'UNKNOWN'
		})
		.constant('CUSTOMER_TEMPORARY_STATUS', {
			ACCOUNT_CLOSED: 'AccountClosed'
		})
		.run(($rootScope, CUSTOMER_STATUS)=> $rootScope.CUSTOMER_STATUS = CUSTOMER_STATUS)
		// Password statuses
		.constant('CUSTOMER_PASSWORD_STATUS', {
			EMPTY: 'EMPTY',
			FORCE_RESET: 'FORCE_RESET',
			NORMAL: 'NORMAL',
			NOT_SET: 'NOT_SET',
			UNKNOWN: 'UNKNOWN'
		})
		.run(($rootScope, CUSTOMER_PASSWORD_STATUS)=> $rootScope.CUSTOMER_PASSWORD_STATUS = CUSTOMER_PASSWORD_STATUS)
		// Email verify statuses
		.constant('CUSTOMER_EMAIL_STATUS', {
			VERIFIED: 'VERIFIED',
			NOT_VERIFIED: 'NOT_VERIFIED',
			NO_SUCH_ACCOUNT: 'NO_ACCOUNT',
			TRACKING_SERVICE_ERROR: 'TRACKING_SERVICE_ERROR',
			UNKNOWN: 'UNKNOWN'
		})
		.run(($rootScope, CUSTOMER_EMAIL_STATUS)=> $rootScope.CUSTOMER_EMAIL_STATUS = CUSTOMER_EMAIL_STATUS)
		.constant('CUSTOMER_EMAIL_STATUS_LABELS',{
			VERIFIED: 'Verified',
			NOT_VERIFIED: 'Not verified',
			NO_SUCH_ACCOUNT: 'No such account',
			TRACKING_SERVICE_ERROR: 'Tracking service error',
			UNKNOWN: 'Unknown'
		})
		.run(($rootScope, CUSTOMER_EMAIL_STATUS_LABELS)=> $rootScope.CUSTOMER_EMAIL_STATUS_LABELS = CUSTOMER_EMAIL_STATUS_LABELS)
		// Email delivery statuses along with their human-friendly labels
		.constant('CUSTOMER_EMAIL_DELIVERY_STATUS', {
			BOUNCED: 'BOUNCED',
			QUEUED: 'QUEUED',
			DELIVERED: 'DELIVERED',
			OPENED: 'OPENED',
			COMPLAINED: 'COMPLAINED',
			SENT: 'SENT',
			TRACKING_SERVICE_ERROR: 'TRACKING_SERVICE_ERROR',
			UNKNOWN: 'UNKNOWN'
		})
		.run(($rootScope, CUSTOMER_EMAIL_DELIVERY_STATUS)=> $rootScope.CUSTOMER_EMAIL_DELIVERY_STATUS = CUSTOMER_EMAIL_DELIVERY_STATUS)
		.constant('CUSTOMER_EMAIL_DELIVERY_STATUS_LABELS',{
			BOUNCED: 'Bounced',
			QUEUED: 'Queued',
			DELIVERED: 'Delivered',
			OPENED: 'Opened',
			COMPLAINED: 'Complained',
			SENT: 'Sent',
			TRACKING_SERVICE_ERROR: 'Tracking service error',
			UNKNOWN: 'Unknown'
		})
		.run(($rootScope, CUSTOMER_EMAIL_DELIVERY_STATUS_LABELS)=> $rootScope.CUSTOMER_EMAIL_DELIVERY_STATUS_LABELS = CUSTOMER_EMAIL_DELIVERY_STATUS_LABELS)
		// Terms of use statuses
		.constant('CUSTOMER_TOU_STATUS', {
			ACCEPTED: 'ACCEPTED',
			NOT_ACCEPTED: 'NOT_ACCEPTED',
			UNKNOWN: 'UNKNOWN'
		})
		.run(($rootScope, CUSTOMER_TOU_STATUS)=> $rootScope.CUSTOMER_TOU_STATUS = CUSTOMER_TOU_STATUS)
		.constant('ENTITLEMENT_CODES', {
			CREATIVE_CLOUD: 'creative_cloud',
			ACOM_CREATEPDF: 'ACOM_CREATEPDF',
			ACOM_FORMS: 'ACOM_FORMS',
			ACOM_SERVICE: 'ACOM_SERVICE',
			ONE_QUOTA: 'quota_service',
			DIGITAL_EDITIONS: 'digital_editions'
		})
		.run(($rootScope, ENTITLEMENT_CODES)=> $rootScope.ENTITLEMENT_CODES = ENTITLEMENT_CODES)
		// All of the possible statuses an entitlement can be in
		.constant('ENTITLEMENT_STATUS', {
			ACTIVE: 'ACTIVE',
			DISABLED: 'DISABLED',
			SUSPENDED: 'SUSPENDED',
			SUSPENDED30: 'SUSPENDED30',
			REMOVED: 'REMOVED',
			STOPPED: 'STOPPED',
			CLOSED: 'CLOSED'
		})
		.run(($rootScope, ENTITLEMENT_STATUS)=> $rootScope.ENTITLEMENT_STATUS = ENTITLEMENT_STATUS)
		;
}());
