/******************************************************************************************
 *    App Header Controller
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('core.AppHeaderController', []).controller('AppHeaderController', AppHeaderController);

	function AppHeaderController(AppStatus, User, Redirector, ImsAuth, AboApi, Modal, Toast) {
		const activate = function() {
			this.userDisplayName = User.getDisplayName();
			this.isHeaderVisible = true;
			this.logout = function() {
				//Hide header and go to root (to display the animated spinner).
				this.isHeaderVisible = false;
				Redirector.go('root');
				//Then trigger sign-out flow.
				ImsAuth.triggerSignOutFlow();
			};

			this.hasCustomers = User.canSeeCustomers();
			this.hasAgents = User.canSeeAgents();
			this.hasActivity = User.canSeeActivity();

			const comment = function(comment) {
				return AboApi.sendFeedback(comment);
			};
			this.showFeedbackModal = ()=> {
				Modal.open({
					title: 'New ABO feedback',
					templateUrl: 'feedback-modal.html',
					locals: {comment}
				}).then(()=> Toast.showSuccess('Thank you for your feedback'));
			};
		};

		AppStatus.ready.then(activate.bind(this));
	}
}());
