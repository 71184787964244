/******************************************************************************************
 *    State Patrol Service
 *******************************************************************************************
 *
 * Controls access to states based on current user permissions and data.permission property of a state.
 *
 */
(function() {
	'use strict';

	angular.module('auth.StatePatrol', []).service('StatePatrol', StatePatrol);

	function StatePatrol($rootScope, $urlRouter, Redirector, User) {

		//Initialize State Patrol.
		let initDone = false;
		this.init = function() {
			if (!initDone) {
				//Init can only be done once.
				initDone = true;

				const onStateChangeStart = function(event, toState, toStateParams) {
					//Public State
					if (!toState || !toState.data || !toState.data.access || typeof toState.data.access !== 'string') {
						// Redirect root state to user's default
						if (toState && toState.name === 'root') {
							event.preventDefault();
							console.log('%cStatePatrol :: Redirect to Default State %O', 'font-weight:bold;color:#CC0000;',
								User.getDefaultStateName());
							Redirector.replaceWith(User.getDefaultStateName());
						}
						return;
					}

					//Protected State.
					//User access denied.
					if (!User.hasPermission(toState.data.access)) {
						event.preventDefault();
						console.log('%cStatePatrol :: Access Denied %O', 'font-weight:bold;color:#CC0000;', {
							toState,
							toStateParams
						});
						Redirector.replaceWith(User.getDefaultStateName());
					}
				};

				//Listen for $stateChangeStart events in order to allow / deny state transitions.
				$rootScope.$on('$stateChangeStart', onStateChangeStart);

				//Enable $urlRouter's $location-event listener.
				$urlRouter.listen();

				//Sync current location with $urlRouter after registering $stateChangeStart listener.
				$urlRouter.sync();
			}
		};
	}
}());
