/******************************************************************************************
 *    Step Bar for wizard
 *    http://spectrum.corp.adobe.com/?light#components-wizards
 ******************************************************************************************/

(function() {
	'use strict';

	angular.module('coreDirectives.StepBar', [])
		.directive('stepBar', StepBarDirective);

	/*=== Directive ===*/
	function StepBarDirective() {
		return {
			restrict: 'E',
			templateUrl: 'step-bar.html',
			scope: {
				texts: '=',
				currentStepIndex: '='
			}
		};
	}
}());
