/******************************************************************************************
 *    Customer Search View Model
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerSearchVm', [])
		.factory('CustomerSearchVm', CustomerSearchVmFactory);

	function CustomerSearchVmFactory(ViewModelFactory, CUSTOMER_ACCOUNT_TYPE, CUSTOMER_STATUS) {
		const init = function(customer) {
			this.accountId = customer.accountId;
			this.accountType = CUSTOMER_ACCOUNT_TYPE[customer.authSrcType] || CUSTOMER_ACCOUNT_TYPE.UNKNOWN;
			this.responsibleAuthSrc = customer.responsibleAuthSrc;
			this.responsibleAccountType = CUSTOMER_ACCOUNT_TYPE[customer.responsibleAuthSrcType] || CUSTOMER_ACCOUNT_TYPE.UNKNOWN;
			this.currentAccountType = this.responsibleAccountType !== CUSTOMER_ACCOUNT_TYPE.UNKNOWN &&
				this.responsibleAccountType !== CUSTOMER_ACCOUNT_TYPE.NOT_SET ? this.responsibleAccountType : this.accountType;
			this.username = customer.username || '[N/A]';
			this.fullName = [customer.firstName, customer.lastName].join(' ').trim() || '[N/A]';
			this.screenName = customer.screenName || '[N/A]';
			this.status = customer.status || '[N/A]';
			this.temporaryStatus = customer.temporaryStatus || '';
			this.statusClass = customer.status === CUSTOMER_STATUS.ACTIVE ? '' : 'text-warn';
		};

		const requiredModelProperties = ['authSrcType', 'authSrc', 'responsibleAuthSrcType', 'responsibleAuthSrc',
			'username', 'firstName', 'lastName', 'screenName', 'status', 'temporaryStatus'];

		return ViewModelFactory(init, requiredModelProperties);
	}

}());
