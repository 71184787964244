/******************************************************************************************
 *    Customer Address View Model
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerAddressesVm', [])
		.factory('CustomerAddressesVm', CustomerAddressesVmFactory);


	function CustomerAddressesVmFactory(ViewModelFactory) {
		const init = function(customer) {
			const rawAddresses = customer.addresses;

			const getZipCode = (zipCode)=> zipCode.length <= 5 ? zipCode : `${zipCode.substr(0, 5)}-${zipCode.substr(5)}`;
			const initAddress = (address)=> {
				if (!address) {
					return {};
				}

				return {
					line1: address.line1,
					line2: address.line2,
					line3: address.line3,
					apartment: address.suiteApt,
					country: address.countryCode,
					city: address.city,
					state: address.state,
					zipCode: getZipCode(address.postalZip || ''),
					modifiedDate: address.modifiedDate || '[N/A]'
				};
			};

			this.hasBillingAddress = !!rawAddresses.billingAddress;
			this.hasShippingAddress = !!rawAddresses.shippingAddress;
			this.hasMailingAddress = !!rawAddresses.mailingAddress;
			this.hasAddresses = this.hasBillingAddress || this.hasShippingAddress || this.hasMailingAddress;
			this.openAddressIndex = [this.hasBillingAddress, this.hasShippingAddress, this.hasMailingAddress].indexOf(true);
			this.billingAddress = initAddress(rawAddresses.billingAddress);
			this.shippingAddress = initAddress(rawAddresses.shippingAddress);
			this.mailingAddress = initAddress(rawAddresses.mailingAddress);

			const address = rawAddresses.billingAddress || rawAddresses.shippingAddress || rawAddresses.mailingAddress;
			const isDefined = (value)=>value !== undefined;
			this.primaryAddress = !address ? '' :
				[address.line1, address.city, address.state, address.postalZip].filter(isDefined).join(', ');
		};

		const requiredModelProperties = ['addresses'];

		return ViewModelFactory(init, requiredModelProperties);
	}
}());
