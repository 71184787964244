/******************************************************************************************
 *    Customer Organizations View Model
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerRolesVm', [])
		.factory('CustomerRolesVm', CustomerRolesVmFactory);


	function CustomerRolesVmFactory(ViewModelFactory) {
		const init = function(customer) {
			/** Example of a rawRole
			 * {
			 *   "principal": "6A6973C8530F5DCD0A490D24@AdobeOrg:1017531",
			 *   "roleCode": "org_admin",
			 *   "targetExpression": "6A6973C8530F5DCD0A490D24@AdobeOrg"
			 * }
			 */
			this.roles = customer.roles || [];
		};

		const requiredModelProperties = ['roles'];

		return ViewModelFactory(init, requiredModelProperties);
	}
}());
