(function() {
	'use strict';

	angular.module('coreDirectives.IntlTelInput', [])
		.service('ImsCountries', function($q, AboApi) {
			var countries;
	
			//Returns a promise that resolves with the list of countries from IMS
			this.get = (function() {
				var deferred;//Deferred object that gets fulfilled after the API responds
				var isWaiting = false;//True while waiting for API to respond, false otherwise
	
				return function() { //locale
					//If a call is already pending, return the promise that's already waiting to be fulfilled
					if (isWaiting && deferred) {
						return deferred.promise;
					}
	
					deferred = $q.defer();
	
					if (countries) {
						deferred.resolve(countries);
					} else {
						isWaiting = true;
						AboApi.getCountries('en_US').then(
							function(response) {
								isWaiting = false;
								countries = response.data.countries;
								deferred.resolve(countries);
							},
							function(rejection) {
								isWaiting = false;
								deferred.reject(rejection);
							}
						);
					}
					return deferred.promise;
				};
			}());
		});
})();
