/******************************************************************************************
 *    Permissions Model
 ******************************************************************************************/
(function() {
	'use strict';


	angular.module('entities.PermissionsModel', [])
		.factory('PermissionsModel', PermissionsModelFactory);

	function PermissionsModelFactory(AboApi, SingletonModel) {
		function PermissionsModel() {
			this.registerPropertiesFetcher(()=> AboApi.getPermissionsMetadata(), 'permissions');
		}

		return SingletonModel(PermissionsModel);
	}

}());
