/******************************************************************************************
 *    File Download Service
 ******************************************************************************************
 *
 * Creates a file to be downloaded by the user with the specified content and filename.
 */
(function() {
	'use strict';

	angular.module('coreServices.FileDownload', [])
		.factory('fileDownload', FileDownloadFactory);

	function FileDownloadFactory() {
		return function(content, filename) {
			/* Create a new link element to be clicked on in order to provide a filename hint */
			var link = document.createElement('a');
			var downloadUrl = URL.createObjectURL(new Blob([content]), {type: 'text/plain'});
			link.href = downloadUrl;
			link.download = filename;
			link.style.display = 'none';
			/* The link needs to be in the DOM before clicking on it */
			document.body.appendChild(link);
			link.click();
			/* Cleanup must be done *after* the download starts. There is no notification of that event, so a simple
			 * timeout is used instead. */
			setTimeout(function() {
				document.body.removeChild(link);
				URL.revokeObjectURL(link.href);
			}, 1000);
		};
	}
}());
