/******************************************************************************************
 *    Agent Log
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('agents.AgentLog', [])
		.controller('AgentLogController', AgentLogController)
		.directive('aboAgentLog', AgentLogDirective);


	/*=== Controller ===*/
	function AgentLogController(CustomerService, AuditCustomerVm, $state, $scope, User) {/* jshint validthis: true */
		$scope.$watch(()=>this.log, ()=> {
			this.hasCustomer = !!this.log.customerAccountId;
			if (this.hasCustomer) {
				const customer = CustomerService.get(this.log.customerAccountId);
				this.customer = new AuditCustomerVm(customer);

			}
		});

		this.canViewCustomer = User.canSeeCustomers();
		this.viewCustomer = ()=> {
			$state.go('customer.details', {id: this.log.customerAccountId});
		};

	}


	/*=== Directive ===*/
	function AgentLogDirective() {
		return {
			restrict: 'E',
			templateUrl: 'agent-log.html',
			scope: {
				log: '='
			},
			controller: 'AgentLogController',
			controllerAs: 'vm',
			bindToController: true
		};
	}
}());
