/******************************************************************************************
 *    Customer Search Controller
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerSearchController', [])
		.controller('CustomerSearchController', CustomerSearchController);

	function CustomerSearchController($stateParams, $state, CustomerService, CustomerSearchVm, Modal) {
		// convenience computed property to simplify view logic
		Object.defineProperty(this, 'hasCustomers', {
			get: ()=>this.customers.length !== 0
		});

		this.search = ()=> {
			if (!this.query) {
				return;
			}

			// Update the URL, for deep linking
			$state.go('customerSearch', {query: this.query}, {notify: false, location: true});

			// Close the quick profile
			this.customer = null;

			this.isLoading = true;
			CustomerService.search(this.query)
				.then((customers)=> {
					this.customers = customers.map((customer)=>new CustomerSearchVm(customer));
				})

				.catch((error)=> {
					console.error(error);
					// TODO: error state
				})

				.finally(()=> {
					this.isPristine = false;
					this.isLoading = false;
				});
		};

		this.resetSearch = ()=> {
			CustomerService.search.cancel();
			this.customers = [];
			this.customer = null;
			this.isPristine = true;
			this.isLoading = false;
			this.query = '';
		};


		this.showCustomer = (customer)=> {
			this.customer = customer;
		};

		// setup initial state
		this.resetSearch();

		// returning to search will set the query parameter and re-perform the search
		if ($stateParams.query) {
			this.query = $stateParams.query;
			this.search();
		}
		
		this.showBatchActionModal = ()=> {
			Modal.open({
				title: 'Bulk activity on customer accounts',
				templateUrl: 'customer-batch-action.html',
				controller: 'CustomerBatchActionController',
				controllerAs: 'vm'
			});
		};
	}
	
}());
