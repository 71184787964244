(function() {
	'use strict';

	/**
	 * Directive that uses intl-tel-input bower dependency to convert a text input to
	 * a rich phone number input complete with validation, formatting and country flag.
	 * The same lib (i.e. intl-tel-input) is used by SUSI when onboarding users to the
	 * SMS Recovery feature.
	 */

	angular.module('coreDirectives.IntlTelInput')
		.directive('susiPhoneInput', function($timeout, PhoneInputCountryMapper, CustomerService, $stateParams) {
			return {
				restrict: 'A',
				require: '^ngModel',
				link: function postLink(scope, element, attrs, ngModelCtrl) { // jshint unused:vars
					//Wait for PhoneInputCountryMapper to finish mapping before converting input the intlTelInput
					PhoneInputCountryMapper.then(function() {
						const defaultCountry = '';//Empty string is the default expected by the intlTelInput component
						const customer = CustomerService.get($stateParams.id);
						const preferredCountry = customer && typeof customer.countryCode === 'string' ? customer.countryCode.toLowerCase() : '';
						
						//Convert element to intlTelInput
						//Configuration matches SUSI configuration for consistency
						element.intlTelInput({
							initialCountry: defaultCountry,
							preferredCountries: [preferredCountry],
							autoFormat: true,
							autoPlaceholder: true,
							nationalMode: false,
							autoHideDialCode: false,
							geoIpLookup: null
						});
					
						//Make the countries faux-dropdown at least as wide as the input element
						var elementWidth = element.outerWidth(); //Get input outerWidth
						var countriesFauxDropdownElement = element.parent()[0].querySelector('.flag-dropdown .country-list');
						if (countriesFauxDropdownElement) {
							countriesFauxDropdownElement.style.minWidth = elementWidth + 'px';
						}
					
						//Write data to model
						var read = function() {
							ngModelCtrl.$setViewValue(element.val());
						};
					
						//Set initial value if input is not empty
						if (element.val() !== '') {
							//Store input value in current digest
							//If the input value is empty in the next digest ($timeout callback)
							//the `value` var will be used to set the initial value of the input.
							//Otherwise, the value that's available then is used.
							var value = element.val();
					
							//Set intlTelInput value inside a $timeout callback to prevent
							//instantiation clash between directive and intlTelInput plugin
							$timeout(function() {
								//Prefer current value if it exists, otherwise use the "old" value from the previous $digest
								element.intlTelInput('setNumber', element.val() || value);
								read();
							});
						}
					
						//Listen for change events to enable binding
						element.on('blur keyup change country-change', function() {
							if (!scope.$$phase) {
								scope.$apply(read);
							} else {
								read();
							}
						});
					
						//Destroy the intlTelInput when scope is destroyed
						scope.$on('$destroy', function() {
							element.intlTelInput('destroy');
						});
					});
				}
			};
		});
})();
