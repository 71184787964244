/******************************************************************************************
 *    App Module and Dependencies
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('thirdPartyDeps', [
		'ngAnimate',
		'ngMessages',
		'ngSanitize',
		'duScroll',
		'ui.router'
	]);

	angular.module('utility', [
		'utility.DomUtility',
		'utility.StringUtility',
		'utility.PromiseUtility',
		'utility.GeneralUtility'
	]);

	angular.module('core', [
		'core.EnvironmentConfiguration',
		'core.Redirector',
		'core.AppInitializer',
		'core.AboApi',
		'core.AppStatus',
		'core.AppHeaderController',
		'core.DateFilter',
		'core.Util'
	]);

	angular.module('auth', [
		'auth.Permissions',
		'auth.ImsAuth',
		'auth.aboApiInterceptor',
		'auth.Auth',
		'auth.User',
		'auth.StatePatrol'
	]);

	angular.module('coreDirectives', [
		'coreDirectives.TakeoverMessage',
		'coreDirectives.Modal',
		'coreDirectives.Wizard',
		'coreDirectives.Accordion',
		'coreDirectives.Tabs',
		'coreDirectives.Toast',
		'coreDirectives.TimeUntil',
		'coreDirectives.Autofocus',
		'coreDirectives.StepBar',
		'coreDirectives.FileReader',
		'coreDirectives.IntlTelInput',
		'coreDirectives.Explanation'
	]);


	angular.module('coreServices', [
		'coreServices.Model',
		'coreServices.ViewModel',
		'coreServices.FileDownload'
	]);

	angular.module('agents', [
		'agents.Constants',
		'agents.AgentModel',
		'entities.PermissionsModel',
		'entities.RolesModel',
		'agents.AgentService',

		'agents.AgentSearchRoute',
		'agents.AgentSearchController',
		'agents.AgentSearchVm',
		'agents.AgentQuickProfile',

		'agents.AgentRoute',
		'agents.AgentHeaderController',
		'agents.AgentHeaderVm',

		'agents.AgentProfileController',
		'agents.AgentAccountAccessVm',
		'agents.AgentDetailsVm',
		'agents.AgentPermissionsVm',

		'agents.AgentActivityController',
		'agents.AgentLog'
	]);

	angular.module('customers', [
		'customers.Constants',
		'customers.CustomerModel',
		'entities.EntitlementsModel',
		'customers.CustomerService',
		'customers.CustomerLog',

		'customers.CustomerSearchRoute',
		'customers.CustomerSearchController',
		'customers.CustomerSearchInput',

		'customers.CustomerRoute',
		'customers.CustomerHeaderController',
		'customers.CustomerProfileController',
		'customers.CustomerAuthenticationController',
		'customers.CustomerEntitlementsController',
		'customers.CustomerGDPRController',
		'customers.CustomerGdprAccessReportRoute',
		'customers.CustomerGdprAccessReportVm',
		'customers.GdprAccessReportModel',
		'customers.GdprAccessReportController',
		'customers.CustomerActivityController',

		'customers.CustomerQuickProfile',

		'customers.CustomerSearchVm',
		'customers.CustomerHeaderVm',
		'customers.CustomerDetailsVm',
		'customers.CustomerAddressesVm',
		'customers.CustomerOrganizationsVm',
		'customers.CustomerRolesVm',
		'customers.CustomerCreationDetailsVm',
		'customers.CustomerLoginHistoryVm',
		'customers.CustomerAddressesVm',
		'customers.CustomerAccountAccessVm',
		'customers.CustomerPasswordVm',
		'customers.CustomerEmailVm',
		'customers.CustomerTermsOfUseVm',
		'customers.CustomerEntitlementsVm',
		'customers.CustomerGDPRVm',

		'customers.CustomerExportRoute',
		'customers.CustomerExportVm',
		'customers.ExportController',

		'customers.CustomerBatchActionController',
		'customers.CustomerBatchVm',
		
		'customers.CustomerEmailStatus',
		
		'customers.CustomerLoginAsImpersonationVm',
		
		'customers.CustomerSessionsVm'
	]);

	angular.module('audits', [
		'audits.Constants',
		'entities.AuditModel',
		'audits.AuditService',

		'audits.AuditSearchRoute',
		'audits.AuditSearchController',
		'audits.AuditSearchVm',
		'audits.auditQuickResult',
		'audits.AuditCustomerVm'
	]);

	angular.module('abo', [
		'thirdPartyDeps',
		'utility',
		'app.endpoints',
		'core',
		'auth',
		'app.config',
		'app.run',
		'coreDirectives',
		'coreServices',
		'customers',
		'agents',
		'audits'
	]);
})();
