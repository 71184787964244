/******************************************************************************************
 *    Customer Batch View Model
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerBatchVm', [])
		.factory('CustomerBatchVm', CustomerBatchVmFactory);


	function CustomerBatchVmFactory(ViewModelFactory) {
		const init = function(customer) {
			this.accountId = customer.accountId;
			this.fullName = [customer.firstName, customer.lastName].filter((value)=>value).join(' ') || '[N/A]';
			this.email = customer.email;
			this.included = true;
		};

		const requiredModelProperties = ['firstName', 'lastName', 'email'];

		return ViewModelFactory(init, requiredModelProperties);
	}
}());
