/******************************************************************************************
 *    Endpoints
 *******************************************************************************************
 *
 * These are changed at build time. See guilfile.js for details.
 *
 */
(function() {
	'use strict';

	angular.module('app.endpoints', [])
		.constant('ABO_API_URL', '/api/v2')
		.constant('IMS_JS_LIB_URL', 'https://auth.services.adobe.com/imslib/imslib.min.js')
		.constant('IMS_URL', 'https://adobeid-na1.services.adobe.com/ims');
})();
