/******************************************************************************************
 *    Tabs Directive
 *******************************************************************************************
 *
 * Expected markup:
 *
 * <div abo-tabs active-index="{{ index }}">
 *   <div class="abo-tab-title-container">
 *     <h3 abo-tab-title>Billing Address</h3>
 *     <h3 abo-tab-title>Shipping Address</h3>
 *     <h3 abo-tab-title ng-class="{ disabled: disableMailingAccess }">Mailing Address</h3>
 *   </div>
 *
 *   <div class="abo-tab-body-container">
 *     <div abo-tab-body>Billing Address Body</div>
 *     <div abo-tab-body>Shipping Address Body</div>
 *     <div abo-tab-body>Mailing Address Body</div>
 *   </div>
 * </div>
 */

(function() {
	'use strict';

	angular.module('coreDirectives.Tabs', [])
		.controller('AboTabsController', AboTabsController)
		.directive('aboTabs', AboTabs);

	function AboTabs() {
		return {
			restrict: 'A',
			controller: AboTabsController
		};
	}

	function AboTabsController($scope, $element, $attrs, $animate, DomUtility) {
		const selectors = {
			TITLE: '[abo-tab-title]',
			BODY: '[abo-tab-body]'
		};

		this.openTab = (activeIndex) => {
			const titles = Array.prototype.slice.call($element[0].querySelectorAll(selectors.TITLE));
			const bodies = Array.prototype.slice.call($element[0].querySelectorAll(selectors.BODY));

			if (titles.length !== bodies.length) {
				throw new Error(`Invalid tabs: mismatched titles and bodies`);
			}

			// Convert activeIndex to number (attributes pass strings instead of numbers)
			activeIndex = +activeIndex;

			// If the active tab doesn't exist, just select the first tab
			if (!titles[activeIndex]) {
				activeIndex = 0;
			}

			// Set `active` class on the active elements (titles and bodies) and remove it from the others
			const activate = (element, index) => {
				$animate[index === activeIndex ? 'addClass' : 'removeClass'](element, 'active');
			};
			titles.forEach(activate);
			bodies.forEach(activate);
		};


		// Initialize active tab
		// Note that this.openTab can safely be called with a string representation of an integer
		$attrs.$observe('activeIndex', this.openTab);
		this.openTab();

		// Listen to clicks on titles to switch selected tab
		$element.on('click', (evt)=> {
			const title = DomUtility.closest(evt.target, selectors.TITLE, $element[0]);

			if (!title || title.classList.contains('disabled')) {
				return;
			}

			const activeIndex = Array.prototype.indexOf.call($element[0].querySelectorAll(selectors.TITLE), title);

			$scope.$evalAsync(()=>this.openTab(activeIndex));
		});
	}
}());
