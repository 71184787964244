/******************************************************************************************
 *    Customer Email Status Directives
 ******************************************************************************************/
// Usage:
// <span customer-email-delivery-status="vm.customerEmail.primary.deliveryStatus"></span>
// <span customer-email-verification-status="vm.customerEmail.primary.status"> </span>
(function() {
	'use strict';

	angular.module('customers.CustomerEmailStatus', [])
		.directive('customerEmailVerificationStatus', CustomerEmailVerificationStatusDirective)
		.filter('customerEmailVerificationLabel', CustomerEmailVerificationLabelFilter)
		.directive('customerEmailDeliveryStatus', CustomerEmailDeliveryStatusDirective)
		.filter('customerEmailDeliveryStatusLabel', CustomerEmailDeliveryStatusLabelFilter);

	function CustomerEmailVerificationStatusDirective() {
		return {
			restrict: 'A',
			template: '{{customerEmailVerificationStatus | customerEmailVerificationLabel}}',
			scope: {
				customerEmailVerificationStatus: '='
			}
		};
	}

	function CustomerEmailVerificationLabelFilter(CUSTOMER_EMAIL_STATUS_LABELS) {
		return function(input) {
			return CUSTOMER_EMAIL_STATUS_LABELS[input] || CUSTOMER_EMAIL_STATUS_LABELS.UNKNOWN;
		};
	}


	function CustomerEmailDeliveryStatusDirective() {
		return {
			restrict: 'A',
			template: '{{customerEmailDeliveryStatus | customerEmailDeliveryStatusLabel}}',
			scope: {
				customerEmailDeliveryStatus: '='
			}
		};
	}
	
	function CustomerEmailDeliveryStatusLabelFilter(CUSTOMER_EMAIL_DELIVERY_STATUS_LABELS) {
		return function(input) {
			return CUSTOMER_EMAIL_DELIVERY_STATUS_LABELS[input] || CUSTOMER_EMAIL_DELIVERY_STATUS_LABELS.UNKNOWN;
		};
	}
}());

