/******************************************************************************************
 *    Audit Service
 ******************************************************************************************/

(function() {
	'use strict';

	angular.module('audits.AuditService', [])
		.service('AuditService', AuditService);

	function AuditService(AboApi, AuditModel, $q, AUDIT_DATES) {
		const cachedSearch = {
			query: '',
			field: '',
			action: '',
			date: '',
			results: []
		};
		const cacheSearch = (query, field, action, date, results) => {
			cachedSearch.query = query || '';
			cachedSearch.field = field || '';
			cachedSearch.action = action || '';
			cachedSearch.date = date || '';
			cachedSearch.results = results || [];
		};

		// used to cancel the latest search
		let searchCanceler;

		const extractSinceDate = (date) => {
			const dayLength = 1000/*ms*/ * 60/*s*/ * 60/*m*/ * 24/*h*/;
			switch (date) {
				case AUDIT_DATES.DAY:
					return Date.now() - dayLength;
				case AUDIT_DATES.WEEK:
					return Date.now() - dayLength * 7;
				case AUDIT_DATES.MONTH:
					return Date.now() - dayLength * 30;
				case AUDIT_DATES.YEAR:
					return Date.now() - dayLength * 365;
				default:
					return '';
			}
		};

		/* retrieves an array of audits. Each of the audits is filled with the properties returned by the search API */
		this.search = function(query, field, action, date) {
			// cancel previous search if any
			this.search.cancel();

			const since = extractSinceDate(date);
			const until = Date.now();

			// make actual call to backend
			searchCanceler = $q.defer();
			return AboApi.findLogs(query, field, action, since, until, searchCanceler.promise)
				.then((result) => {
					const audits = result.data.map((auditData)=> {
						var audit = new AuditModel(auditData.id);
						audit.setSearchDetails(auditData);
						return audit;
					});

					cacheSearch(query, field, action, date, audits);

					return audits;
				});
		};

		this.search.cancel = function() {
			if (searchCanceler) {
				searchCanceler.resolve();
				searchCanceler = null;
			}
		};

		this.getLastSearch = function() {
			return {
				query: cachedSearch.query,
				field: cachedSearch.field,
				action: cachedSearch.action,
				date: cachedSearch.date
			};
		};

		var cachedAudit = {};
		/* retrieves a single audit */
		this.get = function(id) {
			const hasCorrectId = (audit)=> audit.id === id;

			// Replace the cachedAudit with the one that will be returned if necessary
			if (!hasCorrectId(cachedAudit)) {
				cachedAudit = cachedSearch.results.find(hasCorrectId) || new AuditModel(id);
			}

			return cachedAudit;
		};

		this.getAvailableActions = function() {
			return AboApi.getAuditActions().then((response)=> response.data);
		};
	}
}());
