(function() {
	'use strict';

	/*
	 * Replaces countries in intlTelInput jQuery plugin with countries returned by the IMS API
	 */
	angular.module('coreDirectives.IntlTelInput')
		.factory('PhoneInputCountryMapper', function($q, ImsCountries) {
			var deferred = $q.defer();
	
			ImsCountries.get().then(
				function(imsCountries) {
					//Make a map of the countries from IMS based on their 2-letter country code
					var imsCountryMap = {};
					imsCountries.forEach(function(country) {
						if (typeof country.countryCode === 'string') {
							imsCountryMap[country.countryCode.toLowerCase()] = country;
						}
					});
	
					//Replace phone input countries with the ones from IMS
					if (imsCountries && imsCountries.length) {
						//Get reference to the internal countries array
						var phoneInputCountries = window.$.fn.intlTelInput.getCountryData();
	
						//Loop limit is dynamic (i.e `phoneInputCountries.length`) because the
						//array can get shortened during the loop.
						for (var i = 0; i < phoneInputCountries.length; i++) {
							var iso2 = phoneInputCountries[i].iso2.toLowerCase();
	
							//If iso2 exists in the imsCountryMap, change entry name to match IMS countries
							if (iso2 in imsCountryMap) {
								phoneInputCountries[i].name = imsCountryMap[iso2].countryName;
							}
	
							//Otherwise, remove the country from the list of options
							else {
								phoneInputCountries.splice(i, 1);
							}
						}
	
						//We're done
						deferred.resolve();
					}
				},
				function(rejection) {
					deferred.reject(rejection);
				}
			);
	
			//Expose a promise that fulfills after mapping finished.
			return deferred.promise;
		});
})();
