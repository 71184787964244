/******************************************************************************************
 *    User Permissions
 ******************************************************************************************/
(function() {
	'use strict';

	//Stores known user permissions as key-value pairs.
	//Keys represent front-end permission names, values represent back-end permission names.
	//Normally, they should be symmetrical.
	const PERMISSIONS = {
		VIEW_CUSTOMER: 'VIEW_CUSTOMER',
		VIEW_ACTIVITY: 'VIEW_ACTIVITY',
		VIEW_PHONE: 'VIEW_PHONE',
		EDIT_CUSTOMER: 'EDIT_CUSTOMER',
		
		// Creates a Delete GDPR request.
		// The account will be deleted in 60 days.
		EDIT_STATUS: 'EDIT_STATUS',
		
		// Disables the account in Renga, doesn't create a Delete GDPR request
		// The account won't be deleted automatically.
		// Some Adobe projects use "customer" accounts for technical reasons (like accounts with 1 Tb of quota).
		// Other projects reuse the accounts.
		EDIT_STATUS_TO_NOT_BE_DELETED: 'EDIT_STATUS_TO_NOT_BE_DELETED',
		
		PDNA: 'PDNA',
		
		// Deletes the account in Renga IMMEDIATELY, doesn't create a Delete GDPR request.
		DELETE_CUSTOMER: 'DELETE_CUSTOMER',
		
		CLEAR_DOB: 'CLEAR_DOB',

		EDIT_ENTITLEMENT: 'EDIT_ENTITLEMENT',
		ADD_ENTITLEMENT_ACROBAT: 'ADD_ENTITLEMENT_ACROBAT',
		EDIT_ALL_ENTITLEMENT_STATUS_CODES: 'EDIT_ALL_ENTITLEMENT_STATUS_CODES',

		IMPERSONATE: 'IMPERSONATE',

		VIEW_VADER_ASSETS: 'VIEW_VADER_ASSETS',

		VIEW_AGENTS: 'VIEW_AGENTS',
		EDIT_AGENTS: 'EDIT_AGENTS',
		ADD_AGENTS: 'ADD_AGENTS',

		MANAGE_ABO_RIGHTS: 'MANAGE_ABO_RIGHTS',
		MANAGE_ADMINISTRATOR_RIGHTS: 'MANAGE_ADMINISTRATOR_RIGHTS'
	};

	//Provides functionality for validating permissions
	function Permissions(PERMISSIONS) {
		//Checks if the passed-in permission name exists in `PERMISSIONS` store.
		const isValidPermission = function(permissionName) {
			return PERMISSIONS.hasOwnProperty(permissionName);
		};

		//Takes a string permission name or array of permission names and validates them against `PERMISSIONS`
		// constant.
		//If at least one permission is matched, validate will return true.
		//This is so that if new permissions are added, they are ignored by the app instead of failing validation and
		// thus preventing use.
		this.validate = function(permissions) {
			//If string, "convert" to array with one element
			if (typeof permissions === 'string') {
				permissions = [permissions];
			}

			if (!permissions || !Array.isArray(permissions) || permissions.length <= 0) {
				return false;
			}

			return permissions.some(isValidPermission);
		};
	}

	angular.module('auth.Permissions', [])
		.constant('PERMISSIONS', PERMISSIONS)
		.service('Permissions', Permissions);
}());
