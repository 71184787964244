/******************************************************************************************
 *    DOM Utility Methods
 *******************************************************************************************/
(function() {
	'use strict';

	angular.module('utility.DomUtility', []).service('DomUtility', DomUtility);

	function DomUtility() {
		// Normalization of the element.matches method across browsers
		this.matches = (element, selector)=> {
			return element.matches && element.matches(selector) ||
				element.matchesSelector && element.matchesSelector(selector) ||
				element.msMatchesSelector && element.msMatchesSelector(selector) ||
				element.webkitMatchesSelector && element.webkitMatchesSelector(selector);
		};

		// Retrieve the first node up the DOM tree that matches a CSS query, starting from the element itself
		// The stop param can be optionally used to limit the search to a DOM subtree. It can be a Node or a query
		this.closest = (element, query, stop)=> {
			const shouldStop = (node)=> {
				return node === null || node === stop ||
					typeof stop === 'string' && this.matches(node, stop);
			};

			if (!element || !query) {
				return null;
			}

			while (!shouldStop(element)) {
				if (this.matches(element, query)) {
					return element;
				}
				element = element.parentNode;
			}
			return null;
		};

		//Check if an element (`descendant`) is a descendant of another element (parent)
		this.isDescendant = (descendant, parent)=> {
			if (!descendant || !descendant.parentNode || !parent) {
				return false;
			}

			var node = descendant.parentNode;
			while (node !== null) {
				if (node === parent) {
					return true;
				}
				node = node.parentNode;
			}
			return false;
		};

		//Check if element is in viewport
		this.isElementInViewport = (element)=> {
			var rect = element.getBoundingClientRect();
			return rect.top >= 0 &&
				rect.left >= 0 &&
				rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
				rect.right <= (window.innerWidth || document.documentElement.clientWidth);
		};

		//Normalize key code from keyboard event
		this.getKeyCode = (event)=> {
			return event.keyCode || event.which;
		};

		//Asynchronously load script.
		//You can optionally pass in a callback to be notified when loading is done.
		this.asyncLoadScript = (url, successCallback, errorCallback)=> {
			if (!url || typeof url !== 'string') {
				return;
			}

			var js = document.createElement('script');
			js.async = true;
			js.src = url;

			if (successCallback && typeof successCallback === 'function') {
				js.addEventListener('load', successCallback, false);
			}

			if (errorCallback && typeof errorCallback === 'function') {
				js.addEventListener('error', errorCallback, false);
			}

			//Insert newly create script tag before the first script tag on the page.
			var firstScriptTag = document.getElementsByTagName('script')[0];
			firstScriptTag.parentNode.insertBefore(js, firstScriptTag);
		};
	}
}());
