/******************************************************************************************
 *    Customer Password View Model
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerPasswordVm', [])
		.factory('CustomerPasswordVm', CustomerPasswordVmFactory);

	function CustomerPasswordVmFactory(ViewModelFactory, CUSTOMER_PASSWORD_STATUS, CUSTOMER_STATUS,
	                                   CUSTOMER_ACCOUNT_TYPE, User, EnvironmentConfiguration) {
		const init = function(customer) {
			const passwordStatus = customer.passwordStatus || {};

			this.status = CUSTOMER_PASSWORD_STATUS[passwordStatus.status] || CUSTOMER_PASSWORD_STATUS.UNKNOWN;
			this.lastUpdate = passwordStatus.lastUpdate || '[N/A]';
			this.hasDeliveryInfo = !!passwordStatus.lastUpdate;
			this.deliveryStatus = passwordStatus.deliveryStatus || '[N/A]';

			this.hasResetButton = CUSTOMER_ACCOUNT_TYPE[customer.authSrcType] === CUSTOMER_ACCOUNT_TYPE.INDIVIDUAL &&
			                      User.canResetCustomerPassword();
			this.canReset = customer.status === CUSTOMER_STATUS.ACTIVE;
			this.canSet = EnvironmentConfiguration.getValue('isSetPasswordEnabled') &&
				CUSTOMER_ACCOUNT_TYPE[customer.authSrcType] === CUSTOMER_ACCOUNT_TYPE.INDIVIDUAL &&
				User.canSetCustomerPassword();
		};

		const requiredModelProperties = ['passwordStatus', 'status', 'authSrcType'];

		return ViewModelFactory(init, requiredModelProperties);
	}
}());
