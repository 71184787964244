/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2021 Adobe Systems Incorporated
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe Systems Incorporated and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Adobe Systems Incorporated and its
 * suppliers and are protected by all applicable intellectual property
 * laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe Systems Incorporated.
 *
 
 * Customer Impersonation View Model
 **************************************************************************/

(function() {
	'use strict';

	angular.module('customers.CustomerLoginAsImpersonationVm', [])
		.factory('CustomerLoginAsImpersonationVm', CustomerLoginAsImpersonationVmFactory);

	function CustomerLoginAsImpersonationVmFactory(ViewModelFactory) {
		const init = function(customer) {
			
			this.impersonationSessionsList = customer.impersonationSessions || [];
			
		};

		const requiredModelProperties = ['impersonationSessions'];

		return ViewModelFactory(init, requiredModelProperties);
	}
}());
