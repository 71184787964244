/******************************************************************************************
 *    GdprAccessReport Controller
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.GdprAccessReportController', [])
		.controller('GdprAccessReportController', GdprAccessReportController);

	function GdprAccessReportController($stateParams, CustomerService, CustomerGdprAccessReportVm, VM_STATE, User,
										GdprAccessReportModel, $document) {
		var customer = CustomerService.get($stateParams.customerId);
		this.gdprAccessReportModel = new GdprAccessReportModel($stateParams.requestId);

		this.customer = new CustomerGdprAccessReportVm(customer, this.gdprAccessReportModel);
		this.date = Date.now();
		this.agent = User.getEmail();

		this.customer.ready.then(()=> {
			$document[0].title = 'GDPR Access Report for ' + this.customer.username;
		});

		Object.defineProperty(this, 'isLoading', {
			get: ()=> this.customer.state === VM_STATE.LOADING
		});
	}
}());
