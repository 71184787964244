/******************************************************************************************
 *    Customer Entitlements View Model
 ******************************************************************************************/
// https://wiki.corp.adobe.com/display/ABO2/ABO2+One+Quota

(function() {
	'use strict';

	angular.module('customers.CustomerEntitlementsVm', [])
		.factory('CustomerEntitlementsVm', CustomerEntitlementsVmFactory);

	function CustomerEntitlementsVmFactory(ViewModelFactory, StringUtility, CUSTOMER_STATUS, CUSTOMER_ACCOUNT_TYPE, ENTITLEMENT_CODES,
	                                       ENTITLEMENT_STATUS, User, GeneralUtility) {
		// Acrobat service levels are also stored as boolean parameters
		const ACROBAT_SERVICE_LEVEL = {
			ACOM_W_VIP: 'ACOM_W_VIP',
			ACOM_W_20: 'ACOM_W_20',
			ACOM_W_5: 'ACOM_W_5',
			ACOM_W_1: 'ACOM_W_1',
			ACOM_FREE: 'ACOM_FREE',
			UNKNOWN: 'UNKNOWN'
		};

		// The values that should be shown in the interface for the acrobat service levels
		const ACROBAT_SERVICE_LEVEL_PRETTY = {
			ACOM_W_VIP: 'Complimentary',
			ACOM_W_20: 'Unknown',
			ACOM_W_5: 'Premium Plus',
			ACOM_W_1: 'Premium Basic',
			ACOM_FREE: 'Free',
			UNKNOWN: 'Unknown'
		};

		// The different types of acrobat accounts - see accountType for how they are computed
		const ACROBAT_ACCOUNT_TYPE = {
			'BUYER': 'Team buyer',
			'MEMBER': 'Team member',
			'INDIVIDUAL': 'Individual'
		};
		
		const ENTITLEMENT_SOURCE = {
			CUSTOMER_CARE: 'customer_care',
			PAID_ENTITLEMENT: 'paid_entitlement'
		};
		
		const PARAMETER_NAMES = {
			quotaSource: 'quota_source',
			storageQuota: 'storage_quota',
			storageQuotaMin: 'storage_quota_min',
			assignableSeats: 'assignable_seats',
			emptyKey: 'EMPTY_KEY'
		};

		const buildEntitlement = (entitlement, customer, metadata)=> {
			if (!entitlement.code) {
				throw new Error('Attempting to build entitlement for invalid data');
			}

			const isEntitlementCode = (code) => entitlement.code === code;
			const isOneQuotaEntitlement = (e) => e.code === ENTITLEMENT_CODES.ONE_QUOTA;
			const isPaidOneQuotaEntitlement = (e) => isOneQuotaEntitlement(e) &&
				GeneralUtility.ensureObject(e.parameters)[PARAMETER_NAMES.quotaSource] === ENTITLEMENT_SOURCE.PAID_ENTITLEMENT;
			const isCustomerCareOneQuotaEntitlement = (e) => isOneQuotaEntitlement(e) &&
				GeneralUtility.ensureObject(e.parameters)[PARAMETER_NAMES.quotaSource] === ENTITLEMENT_SOURCE.CUSTOMER_CARE;

			let definition = GeneralUtility.ensureArray(metadata.entitlements).find((meta)=> meta.code === entitlement.code);
			if (!definition) {
				definition = {
					type: entitlement.code.indexOf('ACOM_') === 0 ? 'acom' : 'default',
					allowStatus: false,
					creatable: false
				};
			}

			/* The type of the entitlement decides which attributes appear or not */
			const isAcrobat = definition.type === 'acom';
			const isCreativeCloud = isEntitlementCode(ENTITLEMENT_CODES.CREATIVE_CLOUD);
			const isOneQuota = isEntitlementCode(ENTITLEMENT_CODES.ONE_QUOTA);
			const isRegular = !isAcrobat && !isCreativeCloud && !isOneQuota;
			
			const entitlements = GeneralUtility.ensureArray(customer.entitlements);

			/* build parameters array to display in ng-repeat. Make sure the ones we already displayed in other places
			 * don't appear in this list
			 * https://wiki.corp.adobe.com/display/ABO2/ABO2+One+Quota */
			const hasAnyOneQuotaEntitlement = entitlements.some(isOneQuotaEntitlement);
			const hasAnyCustomerCareOneQuotaEntitlement = entitlements.some(isCustomerCareOneQuotaEntitlement);
			const isOneQuotaReadOnly = isPaidOneQuotaEntitlement(entitlement);
			const isCustomerCareOneQuota = isCustomerCareOneQuotaEntitlement(entitlement);
			const hasQuotaUI = isCustomerCareOneQuota;
			const hasSpecialAssignableSeatsTreatment = isEntitlementCode(ENTITLEMENT_CODES.ACOM_CREATEPDF) || isEntitlementCode(ENTITLEMENT_CODES.ACOM_FORMS);
			const hasSpecialAcrobatServiceLevelTreatment = isAcrobat;

			const parametersMap = GeneralUtility.ensureObject(entitlement.parameters);
			const shouldExtractCreativeCloudStorageQuota = isCreativeCloud && Number(parametersMap[PARAMETER_NAMES.storageQuota]) !== 0;
			const extractedParameters = []
				.concat(hasQuotaUI ? [PARAMETER_NAMES.storageQuotaMin] : [])
				.concat(shouldExtractCreativeCloudStorageQuota ? [PARAMETER_NAMES.storageQuota] : [])
				.concat(hasSpecialAssignableSeatsTreatment ? [PARAMETER_NAMES.assignableSeats] : [])
				.concat(hasSpecialAcrobatServiceLevelTreatment ? Object.keys(ACROBAT_SERVICE_LEVEL) : []);
			const unextractedParameter = (name) => extractedParameters.indexOf(name) === -1;
			const buildParameterInfo = (name)=> ({
				name: name,
				label: StringUtility.prettifyLabel(name),
				value: parametersMap[name] || '[N/A]'
			});
			const parameters = Object.keys(parametersMap)
				.filter(unextractedParameter)
				.map(buildParameterInfo);
			const storageQuotaMin = Number(parametersMap[PARAMETER_NAMES.storageQuotaMin]) || 0;

			/* list of options for the storage_quota_min parameter */
			const quotaOptions = [
				{value: 0, label: 'None'},
				{value: 71, label: '71GB'},
				{value: 121, label: '121GB'},
				{value: 1025, label: '1TB (1025GB)'}
			];
			// is the current quota value in the list?
			if (!quotaOptions.find((qo) => qo.value === storageQuotaMin)) {
				const newQuotaOption = {value: storageQuotaMin, label: storageQuotaMin + 'GB'};
				const index = quotaOptions.findIndex((qo) => qo.value > storageQuotaMin);
				quotaOptions.splice(index !== -1 ? index : quotaOptions.length, 0, newQuotaOption);
			}

			/* utility constants to simplify logic */
			const isIndividualAccount = CUSTOMER_ACCOUNT_TYPE[customer.authSrcType] === CUSTOMER_ACCOUNT_TYPE.INDIVIDUAL;
			const isActiveCustomer = customer.status === CUSTOMER_STATUS.ACTIVE;
			const isActiveEntitlement = entitlement.status === ENTITLEMENT_STATUS.ACTIVE;
			const isSuspendedEntitlement = entitlement.status === ENTITLEMENT_STATUS.SUSPENDED;
			const isAcrobatVipCustomer = entitlement.level === ACROBAT_SERVICE_LEVEL.ACOM_W_VIP;
			
			let isCreateCustomerCareOneQuotaAllowed = !hasAnyCustomerCareOneQuotaEntitlement &&
				(isCreativeCloud || isOneQuotaReadOnly) && User.canChangeEntitlementQuota();
			
			let isEntitlementCreatableFromCustomerPOV = definition.creatable;
			if (isOneQuota && !isCreateCustomerCareOneQuotaAllowed) {
				isEntitlementCreatableFromCustomerPOV = false;
			}

			const isDigitalEditions = entitlement.code === ENTITLEMENT_CODES.DIGITAL_EDITIONS;
			const isActiveDigitalEditions = isDigitalEditions && isActiveEntitlement;
			
			let humanReadableExplanation = '';
			if (isDigitalEditions) {
				humanReadableExplanation = isActiveDigitalEditions ? 'The customer is an active Digital Editions user.' :
					'The customer is not an active Digital Editions user.';
			}

			//jscs:disable requireCamelCaseOrUpperCaseIdentifiers
			const thisEntitlement = {
				/* === Every service === */
				name: entitlement.name,
				code: entitlement.code,
				level: entitlement.level,
				status: entitlement.status,
				accountId: entitlement.accountId || '[N/A]',
				ownerAccountId: entitlement.ownerAccountId || '[N/A]',
				delegateAccountId: entitlement.delegateAccountId || '[N/A]',
				createdDate: entitlement.createdDate || '[N/A]',
				modifiedDate: entitlement.modifiedDate || '[N/A]',
				effectiveEndDate: entitlement.effectiveEndDate || '[N/A]',
				parameters: parameters,
				isEntitlementCreatableFromCustomerPOV,

				/* entitlements can be configured to not allow status changes from ABO */
				hasExtendedStatusButton: !!definition.allowStatus && isIndividualAccount &&
				                         User.canEditEntitlementStatusExtended(),
				hasStatusButton: !!definition.allowStatus && isIndividualAccount &&
				                 User.canEditEntitlementStatus() && !User.canEditEntitlementStatusExtended(),
				/* disabled customers cannot have their entitlements changed */
				isStatusEditable: isActiveCustomer,

				/* === Creative cloud === */
				isCreativeCloud,

				/* === One Quota === */
				isCreateCustomerCareOneQuotaAllowed,
				hasAnyOneQuotaEntitlement,
				isOneQuota,
				isOneQuotaReadOnly,
				isCustomerCareOneQuota,
				hasQuotaUI,
				isQuotaEditable: isActiveCustomer && isIndividualAccount && (isActiveEntitlement || isSuspendedEntitlement) && isCustomerCareOneQuota &&
				                 User.canChangeEntitlementQuota(),
				minQuotaOptions: quotaOptions,
				storageQuotaMin,
				/* quotaOptions: defined below - dynamically filtered to be above storageQuotaMin */
				
				isDigitalEditions,
				isActiveDigitalEditions,

				/* === Acrobat === */
				/* levels are translated to user-understandable strings */
				hasServiceLevelExplanation: hasSpecialAcrobatServiceLevelTreatment,
				serviceLevelExplanation: ACROBAT_SERVICE_LEVEL_PRETTY[entitlement.level] ||
				                         ACROBAT_SERVICE_LEVEL_PRETTY.UNKNOWN,

				/* grant/revoke VIP is only available if the customer is the owner of the acrobat entitlement */
				hasVipButton: isAcrobat && isIndividualAccount &&
				              User.canChangeEntitlementVipStatus(),
				isVipButtonEnabled: isActiveCustomer && isActiveEntitlement &&
				                    customer.accountId === entitlement.ownerAccountId &&
				                    customer.accountId === entitlement.delegateAccountId &&
				                    /* My guess is only ACOM_W_VIP have assignableSeats, so below is redundant */
				                    (!Number(parametersMap[PARAMETER_NAMES.assignableSeats]) || isAcrobatVipCustomer),

				/* customer gives access to others to this acrobat entitlement */
				hasAssignableSeats: hasSpecialAssignableSeatsTreatment,
				isAssignableSeatsEditable: isActiveCustomer && isIndividualAccount &&
				                           isActiveEntitlement && isAcrobatVipCustomer &&
				                           User.canChangeEntitlementAssignableSeats(),
				assignableSeats: Number(parametersMap[PARAMETER_NAMES.assignableSeats]) || 0,

				/* acrobat customers can buy entitlements for others */
				hasAccountType: isAcrobat,
				accountType: Number(parametersMap[PARAMETER_NAMES.assignableSeats]) > 0 ? ACROBAT_ACCOUNT_TYPE.BUYER :
				             entitlement.accountId !== entitlement.ownerAccountId &&
				             entitlement.accountId === entitlement.delegateAccountId ? ACROBAT_ACCOUNT_TYPE.MEMBER :
				             ACROBAT_ACCOUNT_TYPE.INDIVIDUAL,

				/* connect URL */
				isMeetingUrlDisplayed: isEntitlementCode(ENTITLEMENT_CODES.ACOM_SERVICE),
				hasMeetingUrl: !!entitlement.serviceUrl,
				meetingUrl: entitlement.serviceUrl || '[N/A]',

				/* === Others === */
				isAdminUrlDisplayed: isRegular,
				hasAdminUrl: !!entitlement.adminUrl,
				adminUrl: entitlement.adminUrl || '[N/A]',

				isServiceUrlDisplayed: isRegular,
				hasServiceUrl: !!entitlement.serviceUrl,
				serviceUrl: entitlement.serviceUrl || '[N/A]',

				isLanguageDisplayed: isRegular,
				language: entitlement.language || '[N/A]',

				humanReadableExplanation
			};

			Object.defineProperty(thisEntitlement, 'quotaOptions', {
				get: ()=> quotaOptions.filter(o=> o.value >= thisEntitlement.storageQuotaMin)
			});
			return thisEntitlement;
		};
		
		const sortEntitlements = (thisEntitlement1, thisEntitlement2) => {
			// Quota related SAOs first, then alphabetically.
			const isStringAboutQuota = (s) => s.toString().toLowerCase().indexOf('quota') !== -1;
			const isEntitlementAboutStorage = (e) => isStringAboutQuota(e.name);
			const isAboutStorage1 = isEntitlementAboutStorage(thisEntitlement1);
			const isAboutStorage2 = isEntitlementAboutStorage(thisEntitlement2);
			if (isAboutStorage1 && !isAboutStorage2) {
				return -1;
			} else if (isAboutStorage2 && !isAboutStorage1) {
				return 1;
			}
			return StringUtility.compareStringsIgnoreCase(thisEntitlement1.name, thisEntitlement2.name);
		};
		
		
		const init = function(customer, metadata) {
			this.email = customer.email;
			const isIndividualAccount = CUSTOMER_ACCOUNT_TYPE[customer.authSrcType] === CUSTOMER_ACCOUNT_TYPE.INDIVIDUAL;
			this.hasAddEntitlementButton = isIndividualAccount && User.canAddEntitlement();
			this.entitlements = GeneralUtility.ensureArray(customer.entitlements)
				.map((entitlement)=> buildEntitlement(entitlement, customer, metadata))
				.sort(sortEntitlements);
			const doesCustomerAllowNewEntitlement = (meta) => !this.entitlements
				.some((entitlement)=> meta.code === entitlement.code && !entitlement.isEntitlementCreatableFromCustomerPOV);
			this.entitlementList = GeneralUtility.ensureArray(metadata.entitlements)
				.filter((metaEntitlement)=> metaEntitlement.creatable && doesCustomerAllowNewEntitlement(metaEntitlement))
				.sort((e1, e2) => StringUtility.compareStringsIgnoreCase(e1.name, e2.name));
			this.CODES = ENTITLEMENT_CODES;
			
			this.productContexts = GeneralUtility.ensureArray(customer.productContexts)
				.map(productContext => {
					let pc = {};
					angular.copy(productContext, pc);
					pc.params = Object.keys(productContext.params)
						.filter(key=> key !== PARAMETER_NAMES.emptyKey)
						.map(key=> ({
							name: key,
							label: StringUtility.prettifyLabel(key),
							value: productContext.params[key] || '[N/A]'
						}));
					pc.orgs = GeneralUtility.ensureArray(customer.organizations)
						.filter(org=> org.organizationId === pc.owningEntityId);
					return pc;
				})
				.sort((pc1, pc2)=> StringUtility.compareStringsIgnoreCase(pc1.serviceCode + pc1.label, pc2.serviceCode + pc2.label));
			this.hasProductContexts = this.productContexts && this.productContexts.length;
			this.hasSeeProductContextsLink = this.hasProductContexts && this.entitlements.length > 5;
			this.showProductContexts = ()=> GeneralUtility.scrollIntoView('#product-contexts', '.customer-container');

			this.isActiveDigitalEditions = this.entitlements.some((entitlement) => entitlement.isActiveDigitalEditions);
		};

		const requiredModelProperties = ['entitlements', 'productContexts', 'organizations', 'email', 'status', 'authSrcType'];
		const requiredMetadataProperties = ['entitlements'];

		/* Compute the `changed` and `getChanges` properties, similar to an EditableViewModel
		 * TODO: make this generic to remove the ugly hack below :(
		 * Entitlements have only 3 editable properties (see below).
		 * The trick is to have the changes reflect for what entitlement the change appears.
		 */
		const VmFactory = ViewModelFactory(init, requiredModelProperties, requiredMetadataProperties);
		return function(customer, metadata) {
			const vm = new VmFactory(customer, metadata);
			const original = new VmFactory(customer, metadata);
			const vmSync = vm.syncFromModel;
			const originalSync = original.syncFromModel;
			original.syncFromModel = angular.noop;
			vm.syncFromModel = ()=> {
				vmSync();
				originalSync();
			};

			/* Special sync method that updates a single entitlement in the view model */
			vm.syncEntitlementFromModel = (code, serviceId)=> {
				const isThisEntitlement = (entitlement)=> entitlement.code === code &&
				                                          entitlement.accountId === serviceId;
				const sourceEntitlement = customer.entitlements.find(isThisEntitlement);
				vm.entitlements = vm.entitlements.map((entitlement)=>
					isThisEntitlement(entitlement) ? buildEntitlement(sourceEntitlement, customer, metadata) : entitlement);
				original.entitlements = original.entitlements.map((entitlement)=>
					isThisEntitlement(entitlement) ? buildEntitlement(sourceEntitlement, customer, metadata) : entitlement);
			};

			const editableProperties = ['assignableSeats', 'storageQuotaMin'];
			const buildChange = (property, entitlement, originalEntitlement)=> ({
				entitlement: entitlement,
				property: property,
				original: originalEntitlement[property],
				changed: entitlement[property]
			});
			vm.getChanges = ()=> {
				const changes = [];
				GeneralUtility.ensureArray(vm.entitlements).forEach((entitlement, index)=> {
					const originalEntitlement = original.entitlements[index];
					editableProperties.forEach((property)=> {
						if (entitlement[property] !== originalEntitlement[property]) {
							changes.push(buildChange(property, entitlement, originalEntitlement));
						}
					});
				});
				return changes;
			};
			Object.defineProperty(vm, 'changed', {
				get: ()=> !!vm.getChanges().length
			});

			return vm;
		};
	}
}());
