/******************************************************************************************
 *    Toast Component
 ******************************************************************************************/
(function() {
	'use strict';
	angular.module('coreDirectives.Toast', [])
		.service('Toast', Toast);

	/*=== Service ===*/
	function Toast($rootScope, $document, $compile, $timeout) {
		const HIDE_DELAY = 7000;
		const VARIANT = {
			SUCCESS: 'SUCCESS',
			WARNING: 'WARNING',
			ERROR: 'ERROR'
		};

		const doc = $document[0];

		// Container for all toasts
		const container = doc.createElement('div');
		container.classList.add('toast-container');
		container.innerHTML =
			'<div ng-repeat="toast in toasts" class="toast" ng-class="toast.variant">{{ toast.message }}</div>';
		doc.querySelector('body').appendChild(container);

		const scope = $rootScope.$new(true);
		scope.toasts = this.toasts = [];
		$compile(container)(scope);


		this.show = function(variant, message) {
			this.toasts.push({variant, message});
			$timeout(()=> { this.toasts.shift(); }, HIDE_DELAY);
		};

		this.showSuccess = (message)=> this.show(VARIANT.SUCCESS, message);
		this.showWarning = (message)=> this.show(VARIANT.WARNING, message);
		this.showError = (message)=> this.show(VARIANT.ERROR, message);
	}
}());
