/******************************************************************************************
 *    Agent Permissions View Model
 ******************************************************************************************/
(function() {
	'use strict';

	// TODO: array.includes
	angular.module('agents.AgentPermissionsVm', [])
		.factory('AgentPermissionsVm', AgentPermissionsVmFactory)
		.filter('humanReadable', HumanReadableFilter);


	function HumanReadableFilter() {
		const ACRONYMS = ['ABO', 'PDNA', 'I', 'II', 'III'];
		const toRegexString = (word)=> '^' + word + '$';
		const acronymRegex = new RegExp(ACRONYMS.map(toRegexString).join('|'), 'i');

		const capitalize = (string)=> {
			const isAcronym = acronymRegex.test(string);
			return isAcronym ? string.toUpperCase() :
			       string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();
		};

		return function(string) {
			return (string || '').split('_').map(capitalize).join(' ');
		};
	}

	function AgentPermissionsVmFactory(ViewModelFactory, User) {

		// TODO: move to util
		const arrayContains = (a, b)=> b.every((item)=> a.includes(item));
		const arrayEquals = (a, b)=> arrayContains(a, b) && arrayContains(b, a);

		const init = function(agent, permissionsModel, rolesModel) {
			const permissions = permissionsModel.permissions || [];
			const roles = rolesModel.roles || [];
			const permissionTypes = permissions.map((permission)=>permission.type);
			const roleTypes = roles.map((role)=>role.type);
			const unique = (value, index, array)=> array.indexOf(value) === index;
			const knownTypesReverseOrder = ['PLATFORM', 'ABO'];
			const typeSort = (a, b)=> knownTypesReverseOrder.indexOf(b) - knownTypesReverseOrder.indexOf(a);
			const exists = (value)=> !!value;
			this.types = permissionTypes.concat(roleTypes).filter(unique).sort(typeSort).filter(exists);

			this.types.forEach((type)=> {
				const isOfType = (entity)=> entity.type === type;
				const permissionsOfType = permissions.filter(isOfType).map((permission)=> permission.permission);
				const rolesOfType = roles.filter(isOfType);

				const agentPermissions = permissionsOfType.map((permission)=>({
					name: permission,
					enabled: (agent.permissions || []).includes(permission),
					isEditable: true
				}));


				const canActOnAgent = agent.accountId ? User.canEditAgent(agent.accountId) : User.canAddAgent();
				this[type] = {
					roles: rolesOfType.map((role)=> role.role),
					agentPermissions: agentPermissions,
					editable: canActOnAgent && User.canEditPermissions(type)
				};


				const isEnabled = (permission)=> !!permission.enabled;
				const getPermission = (permission)=> permission.name;
				Object.defineProperty(this[type], 'permissions', {
					configurable: true,
					get: ()=> this[type].agentPermissions.filter(isEnabled).map(getPermission)
				});

				const rolesMap = {};
				rolesOfType.forEach((role)=> {
					rolesMap[role.role] = role.permissions || [];
				});

				const roleFitsPermissions = (role)=> arrayEquals(rolesMap[role] || [], this[type].permissions || []);
				Object.defineProperty(this[type], 'role', {
					configurable: true,
					get: ()=> this[type].roles.find(roleFitsPermissions),
					set: (role)=> role && agentPermissions.forEach((permission)=> {
						permission.enabled = rolesMap[role].includes(permission.name);
					})
				});

				// Show permissions initially only for a custom role
				this[type].arePermissionsVisible = this[type].permissions.length && !this[type].role;
			});

			var disableChangingPermission = (type, permissionName, explanationWhyNotEditable) => {
				var permission = this[type].agentPermissions.find(permission => permission.name === permissionName);
				permission.isEditable = false;
				permission.explanationForAgent = explanationWhyNotEditable;
			};
			disableChangingPermission('ABO','IMPERSONATE', 'the Impersonate permission is set through membership in an IAM group.');

			var setExplanationForAgent = (type, permissionName, comment) => {
				var permission = this[type].agentPermissions.find(permission => permission.name === permissionName);
				permission.explanationForAgent = comment;
			};
			setExplanationForAgent('ABO', 'LEGACY_NON_IAM_GROUP_IMPERSONATE', 'should be granted to only the Trust and Safety team members.');
			
			const flatten = (arrayOfArrays)=> Array.prototype.concat.apply([], arrayOfArrays);
			Object.defineProperty(this, 'permissions', {
				configurable: true,
				get: ()=> flatten(this.types.map((type)=> this[type].permissions))
			});

			const pristinePermissions = this.permissions;
			Object.defineProperty(this, 'changed', {
				configurable: true,
				get: ()=> !arrayEquals(pristinePermissions, this.permissions)
			});
		};


		return ViewModelFactory(init, ['permissions'], ['permissions'], ['roles']);
	}
}());
