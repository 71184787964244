/******************************************************************************************
 *    Customer Login History View Model
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('customers.CustomerLoginHistoryVm', [])
		.factory('CustomerLoginHistoryVm', CustomerLoginHistoryVmFactory);

	function CustomerLoginHistoryVmFactory(ViewModelFactory) {
		const init = function(customer) {
			const logins = customer.loginHistory || [];

			this.logins = logins.map((entry) => ({
				ip: entry.ip || '[N/A]',
				firstSeen: entry.firstSeenDate || '[N/A]',
				country: entry.countryCode || '[N/A]'
			}));
		};

		const requiredModelProperties = ['loginHistory'];

		return ViewModelFactory(init, requiredModelProperties);
	}
}());
