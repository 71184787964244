/******************************************************************************************
 *    Agent Search Route
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('agents.AgentSearchRoute', [])
		.config(AgentSearchRouteConfig);

	function AgentSearchRouteConfig($stateProvider, PERMISSIONS) {
		$stateProvider
			.state('agentSearch', {
				url: '/agents/search?query',
				templateUrl: 'agent-search.html',
				controller: 'AgentSearchController',
				controllerAs: 'vm',
				data: {
					access: PERMISSIONS.VIEW_AGENTS
				}
			});
	}
}());
