(function() {
	'use strict';

	/**
	 * Directive that displays a grey (?) in the page with an explanation about the surrounding content.
	 */

	angular.module('coreDirectives.Explanation', [])
		.directive('explanation', function(Toast) {
			return {
				restrict: 'E',
				transclude: true,
				scope: true,  // create a new scope for each directive instance (instead of sharing the scope the directive is included in).
				templateUrl: 'explanation.html',
				controller: function($transclude, $scope, $timeout) {
					$transclude(sourceElement => {
						// $timeout gives time to the digest() method to replace any {{ }} inside sourceElement.text()
						$timeout(function() {
							const explanationText = sourceElement.text();
							$scope.explanationText = explanationText;
							$scope.Toast = Toast;
						});
					});
				}
			};
		});
})();
