/******************************************************************************************
 *    Agent Details View Model
 ******************************************************************************************/
(function() {
	'use strict';

	angular.module('agents.AgentDetailsVm', [])
		.factory('AgentDetailsVm', AgentDetailsVmFactory);

	function AgentDetailsVmFactory(ViewModelFactory) {
		const init = function(agent) {
			this.email = agent.email || '[N/A]';
			this.firstName = agent.firstName || '[N/A]';
			this.lastName = agent.lastName || '[N/A]';
			this.manager = agent.manager || '[N/A]';
			this.fullName = [agent.firstName, agent.lastName].join(' ').trim() || '[N/A]';
		};

		const requiredModelProperties = ['email', 'firstName', 'lastName'];

		return ViewModelFactory(init, requiredModelProperties);
	}
}());
